import React from 'react';

import IdentityApp from './components/identity-app';
import WithQuery from '../../../../common/components/with-query';

const VerifyIdentities = props => {
  return (
    <WithQuery query={props.location.search}>
      <IdentityApp/>
    </WithQuery>
  );
}

export default VerifyIdentities;
