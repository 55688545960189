import React from 'react';
import { connect } from 'react-redux'

import Api from '../../../../../../common/api';
import Auth from '../../../../../../common/auth';

import { setError } from '../../../../../../actions/auth';
import { setFlow } from '../../../../../../actions/flow';

import StandardInput from '../../../../shared/components/standard-input';
import Heading from '../../../../shared/components/heading';
import ResetPasswordRules from '../../../../../golfid/shared/components/reset-password-rules';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const styles = theme => ({
    view: {
      ...theme.internalContainer,
      [theme.breakpoints.up('md')]: {
        padding: theme.internalContainer._themeMdPadding
      },
    },
    buttonControls: {
      display: "flex",
      justifyContent: "center"
    },
    buttonPrimary: {
      backgroundColor: "#0076BB",
      padding: "15px 20px",
      borderRadius: "4px",
      display: "inline-block",
      margin: "0 25px 0 0",
      width: "auto",
    },
});

class View extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      oldPassword: '',
      newPassword: '',
      repeat: '',
      previousPassword: false,
      messaging: null,
      validation: {
        oldPassword: null,
        newPassword: null,
        repeat: null
      },
      processing: false,
      showPassword: false,
    };

    this.api = new Api();

    this.handleInput = this.handleInput.bind(this);
    this.onChangeSuccess = this.onChangeSuccess.bind(this);
    this.onShowPassword = this.onShowPassword.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitPress = this.onSubmitPress.bind(this);
  }

  handleInput(name) {

    return function (e) {

      this.setState({
        [name]: e.target.value,
        validation: {
          oldPassword: null,
          newPassword: null,
          repeat: null
        }
      });

      if (this.props.error.field) {
        this.props.dispatch(setError({}));
      }
    }.bind(this);
  }

  onChangeSuccess() {
    this.props.dispatch(setFlow({}));
    this.setState({
      newPassword: '',
      oldPassword: '',
      repeat: ''
    })
  }

  onShowPassword() {
    this.setState({showPassword: !this.state.showPassword});
  }

  async onSubmit() {

    const validation = {
      oldPassword: this.state.oldPassword === '' ? 'Please enter your current password.' : null,
      newPassword: this.state.newPassword === '' ? 'Please enter the new password.' : null,
      repeat: function () {

        if (this.state.newPassword !== '') {
          if (this.state.repeat === '') {
            return 'Please repeat your password.';
          }
          if (this.state.repeat !== this.state.newPassword) {
            return `The passwords don't match. Please re-enter to try again.`;
          }

          return null;
        }
        else {
          return null;
        }
      }.bind(this)()
    };

    if (Object.values(validation).some((v) => { return v !== null; })) {
      return this.setState({ validation });
    }

    const success = await Auth.userChangePassword(this.props.dispatch, this.state.oldPassword, this.state.newPassword);

    if (success) {
      this.onChangeSuccess();
    }
  }

  async onSubmitPress(e) {

    if (e.key === 'Enter') {
      return await this.onSubmit();
    }
  }

  render() {
    let newPasswordErr = this.state.validation.newPassword || null;
    let oldPasswordErr = this.state.validation.oldPassword || null;
    let previous = false;
    let repeatErr = this.state.validation.repeat || null;

    const endAdornment = this.state.showPassword ?
      <VisibilityOffIcon style={{'cursor': 'pointer'}} onClick={this.onShowPassword} /> :
      <VisibilityIcon style={{'cursor': 'pointer'}} onClick={this.onShowPassword} />;

    if (this.props.error) {
      if (this.props.error.field === 'previousPassword') {
        previous = true;
      } else if (this.props.error.field === 'oldPassword') {
        oldPasswordErr = this.props.error.message;
      }
    }

    return (
      <Card elevation={3} className={this.props.classes.view}>
        <CardContent>
          <Grid container direction="column">
            <Grid item>
              <Heading text='Change Your Password'/>
            </Grid>
            <ResetPasswordRules
              password={this.state.newPassword}
              passwordRepeat={this.state.repeat}
              previous={previous}
            />
            <Grid item>
              <StandardInput
                  id="old-password"
                  inputType={this.state.showPassword ? 'text' : 'password'}
                  value={this.state.oldPassword}
                  password={true}
                  style={{ marginBottom: '10px', width: '100%' }}
                  error={oldPasswordErr}
                  attribute="old-password"
                  label="Old Password"
                  labelWidth={100}
                  endAdornment={endAdornment}
                  onChange={this.handleInput('oldPassword')}
                  onKeyUp={this.onSubmitPress} />
            </Grid>
            <Grid item>
              <StandardInput
                  id="new-password"
                  inputType={this.state.showPassword ? 'text' : 'password'}
                  value={this.state.newPassword}
                  password={true}
                  style={{ marginBottom: '10px', width: '100%' }}
                  error={newPasswordErr}
                  attribute="new-password"
                  label="New Password"
                  labelWidth={110}
                  onChange={this.handleInput('newPassword')}
                  onKeyUp={this.onSubmitPress} />
            </Grid>
            <Grid item>
              <StandardInput
                  id="repeat-new-password"
                  inputType={this.state.showPassword ? 'text' : 'password'}
                  value={this.state.repeat}
                  password={true}
                  style={{ marginBottom: '30px', width: '100%' }}
                  error={repeatErr}
                  attribute="repeat"
                  label="Confirm Password"
                  labelWidth={140}
                  onChange={this.handleInput('repeat')}
                  onKeyUp={this.onSubmitPress} />
            </Grid>
            <Grid item>
              <Button className={this.props.classes.buttonControls} color="primary" id="password-change-submit" onClick={this.onSubmit} variant="contained">
                  {this.state.processing ?
                      (<CircularProgress size={14} color="inherit" />)
                      : 'Change Password'
                  }
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.error,
    flow: state.flow
  };
}

const ConnectedView = connect(mapStateToProps, null)(View);

export default withStyles(styles)(ConnectedView);
