import React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  buttonControls: {
    display: "flex",
    justifyContent: "center",
    marginTop: "25px"
  }
});

class LogoutEverywhere extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            revokeTokens: true,
            revokeTokensWarning: false
        };

        this.onLogoutEverywhereClick = this.onLogoutEverywhereClick.bind(this);
    }

    async onLogoutEverywhereClick() {
        this.props.onRevokeTokensClick();
    }

    render() {

        return (
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Button className={this.props.classes.buttonControls}
                        color="primary"
                        id="logouteverywhere-click"
                        onClick={this.onLogoutEverywhereClick}
                        style={{
                            width: '100%'
                        }}
                        variant="contained">
                        Click Here to Logout Of All Devices
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(LogoutEverywhere);
