import React, { Component }  from 'react';
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import { toggleMode } from '../../../../actions/style';

const styles = (theme) => ({
  footer: {
    //bottom: 0,
    display: 'block',
    //left: 0,
    //position: 'fixed',
  },
  footerContainer: {

  },
  footerDivider: {
    height: '2px'
  },
  footerItem: {
    paddingRight: '25px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '10px'
    }
  },
  footerLink: {
    textDecoration: 'none',
  },
  footerModeToggleIcon: {
    cursor: 'pointer',
    width: '15px'
  },
  footerModeToggleContainer: {
    paddingTop: '8px'
  },
  footerStopHand: {
    height: '16px',
    marginRight: '4px',
    width: '16px'
  },
  footerText: {
    fontSize: '13px',
    fontWeight: 'normal',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px'
    },
  },
  footerButton: {
    top: '-10px',
    position: 'relative'
  },
  iconCcpa: {
    float: 'left',
    margin: '3px 5px 0px 0px',
    width: '28px',
  },
  otSettings: {
    position: 'relative',
    background: 'none!important',
    border: 'none!important',
    fontSize: '13px!important',
    color: 'rgba(0, 0, 0, 0.87)!important',
    cursor: 'pointer',
    fontFamily: 'Karla, sans-serif!important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px! important'
    },
    textTransform: 'uppercase',
    paddingRight: '0px!important',
    paddingLeft: '0px!important'
  }
});

class Footer extends Component {
  constructor(props) {
    super(props);

    this.theme = 1;

    this.toggleDayNight = this.toggleDayNight.bind(this);
  }

  toggleDayNight() {
    const mode = this.props.mode === 'light' ? 'dark' : 'light';
    this.props.toggleMode(mode);
  }

  render() {
    const year = new Date().getFullYear();

    const publicDir = !window.globals || !window.globals.gidPublic ? '' : window.globals.gidPublic;

    const ccpa = !window.globals || !window.globals.gidShowCCPA ? null :
      (
        <img className={this.props.classes.iconCcpa} src={`${publicDir}/img/icon-ccpa.svg`} alt='CCPA'/>
      );

    const cdpr = !window.globals || !window.globals.gidShowCDPR ? null :
      (
        <Grid item className={this.props.classes.footerItem}>
          <p className={this.props.classes.footerText}>
            {ccpa}<Link id="ot-sdk-btn" className={`ot-sdk-show-settings ${this.props.classes.otSettings}`}>YOUR PRIVACY CHOICES</Link>
          </p>
        </Grid>
      );

    return (
      <footer className={this.props.classes.footer}>
        <Divider className={this.props.classes.footerDivider} color="primary" />
        <Grid container spacing={0} justifyContent="center" className={this.props.classes.footerContainer}>
          <Grid item className={this.props.classes.footerItem}>
            <p className={this.props.classes.footerText}>
                <Link color="inherit" href={this.props.adChoicesUrl} target="_blank">AD CHOICES</Link>
            </p>
          </Grid>
          <Grid item className={this.props.classes.footerItem}>
            <p className={this.props.classes.footerText}>
              <Link color="inherit" href={this.props.privacyPolicyUrl} target="_blank">PRIVACY POLICY</Link>
            </p>
          </Grid>
          {cdpr}
          <Grid item className={this.props.classes.footerItem}>
            <p className={this.props.classes.footerText}>
              <Link color="inherit" href={this.props.caNotice} target="_blank">CA NOTICE</Link>
            </p>
          </Grid>
          <Grid item className={this.props.classes.footerItem}>
            <p className={this.props.classes.footerText}>
              <Link color="inherit" href={this.props.termsAndConditionsUrl} target="_blank">TERMS &amp; CONDITIONS</Link>
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={0} justifyContent="center" className={this.props.classes.footerContainer}>
            <Grid item className={this.props.classes.footerItem}>
                <p className={this.props.classes.footerText}>
                    COPYRIGHT &copy; {year} GOLF CHANNEL, ALL RIGHTS RESERVED.
                </p>
            </Grid>
        </Grid>
      </footer>
    );
  }
}

const mapStateToProps = state => {
  return {
    mode: state.mode
  }
}

const ConnectedFooter = connect(mapStateToProps, { toggleMode })(Footer);

export default withStyles(styles)(ConnectedFooter);
