import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";

import Api from '../../../../common/api'

const styles = (theme) => ({
  applicationName: {
    color: theme.palette.primary.main
  },
  connectToEverythingGolf: {
    fontSize: '36px',
    fontWeight: 'bold',
    letterSpacing: '-1.3px',
    lineHeight: '50px',
    margin: '0 0 0 0',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      fontSize: '56px',
    }
  },
  internalContainer: {
    [theme.breakpoints.up('md')]: {
      margin: '0 0 0 40px'
    }
  },
  logoImage: {
    margin: "0 auto",
    display: "block",
    maxHeight: "30px",
    maxWidth: "150px",
    [theme.breakpoints.up('md')]: {
      display: "inline-block",
      maxHeight: "50px",
      maxWidth: "200px",
    },
  },
  secondHeadline: {
    fontSize: "24px",
    fontWeight: "bold",
    letterSpacing: "-1.3px",
    margin: "20px 0 20px 0",
    textAlign: "center",
    [theme.breakpoints.up('md')]: {
      textAlign: "left",
      fontSize: "32px",
    },
  }
});

class LeftRailContainer extends Component {

  constructor(props) {

    super(props);

    this.state = {
      client: null
    };

    this.api = new Api();
    this.getClientInfoBlock = this.getClientInfoBlock.bind(this);
  }

  getClientInfoBlock() {
    if (!this.state.client) return null;

    const name = this.state.client.displayable.content.title ?
    this.state.client.displayable.content.title :
    this.state.client.applicationName;
    const logo = this.state.client.displayable.content.appIconUrl ?
    this.state.client.displayable.content.appIconUrl :
    null;

    let nameElement = (
      <Typography color="textSecondary" variant="h2" className={this.props.classes.secondHeadline}>
        Log in to <span className={this.props.classes.applicationName}>{name}</span>
      </Typography>
    );
    let logoElement = <img className={this.props.classes.logoImage} src={logo} alt={name}/>;
    if (!logo) logoElement = null;

    return (
      <div>
        {nameElement}
        {logoElement}
      </div>
    );
  }

  getHeadingBlock() {
    if (this.props.noHeading) return null;

    return (
      <div>
        <Typography variant="h2" className={this.props.classes.connectToEverythingGolf}>Connect to</Typography>
        <Typography variant="h2" className={this.props.classes.connectToEverythingGolf}>Everything GOLF</Typography>
      </div>
    )
  }

  async componentWillMount() {
    if (this.props.clientId) {
      try {
        const client = await this.api.get(
          `/application/${this.props.clientId}`
        );

        if (client.code === 200) {
          this.setState({ client: client.content });
        }
      }
      catch (e) {
        return this.onError({ error: e.toString() });
      }
    }
  }

  render() {
    const clientInfo = this.getClientInfoBlock();
    const heading = this.getHeadingBlock();

    return (
      <div className={this.props.classes.internalContainer}>
        {heading}
        {clientInfo}
      </div>
    );
  }
}

export default withStyles(styles)(LeftRailContainer);
