import { combineReducers } from 'redux';
import { setAlert, setError, setHasValidated, setSignedIn, setUser } from './auth';
import { setFlow } from './flow';
import { setReturnTo } from './return';
import { applyTheme, toggleMode } from './style';

const reducers = combineReducers({
  alert: setAlert,
  error: setError,
  flow: setFlow,
  isSignedIn: setSignedIn,
  hasValidated: setHasValidated,
  mode: toggleMode,
  returnTo: setReturnTo,
  theme: applyTheme,
  user: setUser
});

export default reducers;
