import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';

class StandardInput extends React.Component {

  render() {

    const {
      pattern = "",
      disabled = false,
      error = null,
      focus = false,
      value = undefined,
      id = null,
      attribute,
      label,
      labelWidth = null,
      inputType = null,
      password = false,
      startAdornment = null,
      endAdornment = null,
      onChange = function () {},
      onKeyUp = function () {},
      style = {},
    } = this.props;

    return (
      <FormControl style={ style } error={error !== null} variant="outlined">
        <InputLabel htmlFor={attribute}>{label}</InputLabel>
        <OutlinedInput
          color="primary"
          id={id || attribute}
          pattern={pattern}
          disabled={disabled}
          value={value}
          variant="outlined"
          type={!!inputType ? inputType : (!password ? 'input' : 'password')}
          autoFocus={focus}
          startAdornment={startAdornment}
          endAdornment={endAdornment}
          onChange={onChange}
          onKeyUp={onKeyUp}
          labelWidth={labelWidth}
        />
        {
          error !== null ?
          <FormHelperText id={`${attribute}-error`}>{error}</FormHelperText>
          : null
        }
      </FormControl>
    );
  }
}

export default (StandardInput);
