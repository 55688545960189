import React from 'react';
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Auth from '../../../../../../common/auth';

import Heading from '../../../../shared/components/heading';

const styles = theme => ({
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  },
  text: {
    fontSize: '14px'
  },
  textApproved: {
    fontSize: '12px'
  },
  logoutButton: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px"
  },
  username: {
    display: 'inline',
    fontSize: '18px',
  },
  verified: {
    paddingTop: '15px',
    paddingLeft: '10px',
    display: 'inline',
  },
  verifiedIcon: {
    color: '#009900 !important',

  }
});

class View extends React.Component {

  constructor(props) {

    super(props);
    this.onLogout = this.onLogout.bind(this);
    this.onVerify = this.onVerify.bind(this);
  }

  onLogout() {
    Auth.userLogout(this.props.dispatch);
  }

  onVerify() {
    Auth.userRequestVerify(this.props.dispatch, this.props.user.username);
  }

  render() {

    const verified = this.props.user.verified ?
        <Grid item><CheckCircleIcon className={this.props.classes.verifiedIcon}/></Grid> :
        null;

    const verify = !this.props.user.verified ?
        <Grid item xs={6}>
          <Button className={this.props.classes.logoutButton} variant="contained" color="secondary" onClick={this.onVerify}>Verify Email</Button>
        </Grid> :
        null;

    return (
      <Card elevation={3} className={this.props.classes.view}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item>
              <Heading text="Preferences" />
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary" className={this.props.classes.heading}>Currently logged in as:</Typography>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography color="textPrimary" className={this.props.classes.username}><strong>{this.props.user.username}</strong></Typography>
                </Grid>
                {verified}
              </Grid>
            </Grid>
            <Grid item>
              <Button className={this.props.classes.logoutButton} variant="contained" color="primary" onClick={this.onLogout}>Logout</Button>
            </Grid>
            {verify}
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: Auth.userState(state)
  };
}

const ConnectedView = connect(mapStateToProps, null)(View);

export default withStyles(styles)(ConnectedView);
