import React from 'react';

import VerifyApp from './components/verify-app';
import WithQuery from '../../../../common/components/with-query';

const Verify = props => {
  return (
    <WithQuery query={props.location.search}>
      <VerifyApp />
    </WithQuery>
  );
}

export default Verify;
