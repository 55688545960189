import React from 'react';

import Api from '../../../../../../common/api';

import StandardInput from '../../../../shared/components/standard-input';
import Heading from '../../../../shared/components/heading';
import ResetPasswordRules from '../../../../shared/components/reset-password-rules';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const styles = theme => ({
    view: {
        marginTop: '40px'
    },
    text: {
        color: '#666',
        fontSize: '14px'
    },
    buttonControls: {
      display: "flex",
      justifyContent: "center"
    },
    buttonPrimary: {
      backgroundColor: "#0076BB",
      padding: "15px 20px",
      borderRadius: "4px",
      display: "inline-block",
      margin: "0 25px 0 0",
      width: "auto",
    },
});

class PasswordUpdate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            oldPassword: '',
            newPassword: '',
            repeat: '',
            previousPassword: false,
            messaging: null,
            validation: {
                oldPassword: null,
                newPassword: null,
                repeat: null
            },
            processing: false,
            showPassword: false,
        };

        this.api = new Api();

        this.handleInput = this.handleInput.bind(this);
        this.onShowPassword = this.onShowPassword.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitPress = this.onSubmitPress.bind(this);
    }

    handleInput(name) {

        return function (e) {

            this.setState({
                [name]: e.target.value,
                validation: {
                    oldPassword: null,
                    newPassword: null,
                    repeat: null
                }
            });
        }.bind(this);
    }

    onShowPassword() {
      this.setState({showPassword: !this.state.showPassword});
    }

    async onSubmit() {
        const validation = {
            oldPassword: this.state.oldPassword === '' ? 'Please enter your current password.' : null,
            newPassword: this.state.newPassword === '' ? 'Please enter the new password.' : null,
            repeat: function () {

                if (this.state.newPassword !== '') {
                    if (this.state.repeat === '') {
                        return 'Please repeat your password.';
                    }
                    if (this.state.repeat !== this.state.newPassword) {
                        return `The passwords don't match. Please re-enter to try again.`;
                    }

                    return null;
                }
                else {
                    return null;
                }
            }.bind(this)()
        };

        if (Object.values(validation).some((v) => { return v !== null; })) {
            return this.setState({ validation });
        }

        try {
            this.setState({ processing: true, previousPassword: false });

            const user = await this.api.put('/user', { password: { before: this.state.oldPassword, after: this.state.newPassword }});

            this.setState({ processing: false });

            if (user) {
                if (user.code === 201) {
                    this.setState({
                        oldPassword: '',
                        newPassword: '',
                        repeat: '',
                        showPassword: false
                    });
                    this.props.onRevokeTokensClick();
                    return this.props.onSuccess(`Your password was successfully changed.`);
                }

                if (user.code === 400) {
                    if (user.content.message === 'You cannot re-use your last four passwords. Please enter another password.') {
                      this.setState({previousPassword: true});
                    }
                    return this.props.onError(user.content.message);
                }

                if (user.code === 401) {
                    this.props.onError('Your current password is incorrect. Please try entering it again.');
                    return this.setState({
                        oldPassword: ''
                    });
                }
            }
        }
        catch (e) {
            return this.props.onError(e.toString());
        }
    }

    async onSubmitPress(e) {
        if (this.state.previousPassword == true) {
            this.setState({previousPassword: false});
        }
        if (e.key === 'Enter') {
            return await this.onSubmit();
        }
    }

    render() {

        const endAdornment = this.state.showPassword ?
          <VisibilityOffIcon color="action" style={{'cursor': 'pointer'}} onClick={this.onShowPassword} /> :
          <VisibilityIcon color="action" style={{'cursor': 'pointer'}} onClick={this.onShowPassword} />;

        return (
            <Grid container spacing={0} className={this.props.classes.view}>
                <Grid item xs={12}>
                    <Heading text='Change Your Password'/>
                </Grid>
                <ResetPasswordRules
                  password={this.state.newPassword}
                  passwordRepeat={this.state.repeat}
                  previous={this.state.previousPassword}
                />
                <Grid item xs={12}>
                    <StandardInput
                        id="old-password"
                        inputType={this.state.showPassword ? 'text' : 'password'}
                        value={this.state.oldPassword}
                        password={true}
                        style={{ marginBottom: '10px' }}
                        error={this.state.validation.oldPassword}
                        attribute="old-password"
                        label="Old Password"
                        endAdornment={endAdornment}
                        onChange={this.handleInput('oldPassword')}
                        onKeyUp={this.onSubmitPress} />
                </Grid>
                <Grid item xs={12}>
                    <StandardInput
                        id="new-password"
                        inputType={this.state.showPassword ? 'text' : 'password'}
                        value={this.state.newPassword}
                        password={true}
                        style={{ marginBottom: '10px' }}
                        error={this.state.validation.newPassword}
                        attribute="new-password"
                        label="New Password"
                        onChange={this.handleInput('newPassword')}
                        onKeyUp={this.onSubmitPress} />
                </Grid>
                <Grid item xs={12}>
                    <StandardInput
                        id="repeat-new-password"
                        inputType={this.state.showPassword ? 'text' : 'password'}
                        value={this.state.repeat}
                        password={true}
                        style={{ marginBottom: '30px' }}
                        error={this.state.validation.repeat}
                        attribute="repeat"
                        label="Confirm Password"
                        onChange={this.handleInput('repeat')}
                        onKeyUp={this.onSubmitPress} />
                </Grid>
                <Grid item xs={12}>
                    <Button className={this.props.classes.buttonControls} color="primary" id="password-change-submit" onClick={this.onSubmit} variant="contained">
                        {this.state.processing ?
                            (<CircularProgress size={14} color="inherit" />)
                            : 'Change Password'
                        }
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(PasswordUpdate);
