import React, { Component } from 'react';
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { setFlow } from '../../../../../actions/flow';

import RequestView from './views/request';
import SentView from './views/sent';

const styles = theme => ({
  pageContainer: theme.pageContainer,
  view: {
    ...theme.internalContainer,
  }
});

class Page extends Component {
  componentWillUnmount() {
    this.props.dispatch(setFlow({}));
  }

  render() {
    let view = <RequestView query={this.props.query}/>;

    if (this.props.flow) {
      if (this.props.flow.isResetSent) {
        view = <SentView query={this.props.query}/>;
      }
    }

    return (
      <Grid container justify="center" className={this.props.classes.pageContainer}>
        {view}
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    flow: state.flow
  };
}

const ConnectedPage = connect(mapStateToProps, null)(Page);

export default withStyles(styles)(ConnectedPage);
