import React from 'react';
import { Redirect } from 'react-router';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";

import Api from '../../../../../../common/api';
import Util from '../../../../../../common/util';

import Link from '@material-ui/core/Link';
import PopAlert from '../../../../shared/components/pop-alert';
import StandardInput from '../../../../shared/components/gn-standard-input';

const styles = theme => ({
  button: {
    borderRadius: '10px',
    fontWeight: '600',
    fontSize: '16px',
    height: '55px',
    width: '100%',
  },
  buttonControls: {
    display: "flex",
    justifyContent: "center",
  },
  infoText: {
    fontSize: '14px'
  },
  linkText: {
    color: '#222222',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  noHeaderContainer: {
    padding: '20px 20px 0px 20px',
    maxWidth: '1200px',
    margin: '0 auto 50px auto'
  },
  sentInfoText: {
    fontSize: '14px',
    marginBottom: '15px'
  },
  signInText: {
    fontSize: '14px',
    paddingTop: '10px',
    textAlign: 'center'
  },
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  },
});

class StepForgotPassword extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      error: null,
      pop: false,
      username: this.props.username || '',
      sent: false,
      reSent: false,
      validation: {
        username: null
      },
      processing: false,
      redirect: null
    };
    this.api = new Api();
    this.generateOnInput = this.generateOnInput.bind(this);
    this.goCreateAccount = this.goCreateAccount.bind(this);
    this.goSignIn = this.goSignIn.bind(this);
    this.onCreateAccountClick = this.onCreateAccountClick.bind(this);
    this.onError = this.onError.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitInternal = this.onSubmitInternal.bind(this);
    this.onSubmitPressed = this.onSubmitPressed.bind(this);
    this.onRequestClose = this.onRequestClose.bind(this);
  }

  async componentWillMount() {

    if (this.props.query.aslogin) {
      try {
        await this.onSubmitInternal(this.props.query.aslogin);
        return this.setState({
          username: this.props.query.aslogin,
          sent: true
        });
      }
      catch (e) {
        console.error(e);
        return;
      }
    }

    if (this.props.query.email) {
      this.setState({
        'username': this.props.query.email
      });
    }
  }

  generateOnInput(stateKey) {
    return function (e) {
      this.setState({
        [stateKey]: e.target.value,
        validation: { [stateKey]: null }
      });
    }.bind(this);
  }

  goCreateAccount(e) {
    e.preventDefault();
    this.props.goCreateAccount();
  }

  goSignIn(e) {
    e.preventDefault();
    this.props.goSignIn();
  }

  onCreateAccountClick(e) {
    e.preventDefault();
    this.redirect(`/new-account${Util.toQueryString(this.props.query)}`);
  }

  onError({ error }) {
    this.setState({
      error,
      pop: true
    });
  }

  onRequestClose() {
    this.setState({
      pop: false
    });
  }

  async onSubmit() {
    return await this.onSubmitInternal(this.state.username);
  }

  async onSubmitInternal(username) {
    const validation = {
            username: username.length ? null : 'Please enter an email address.'
    };
    const that = this;

    if (Object.values(validation).some((v) => { return v !== null; })) {
      return this.setState({ validation });
    }

    try {
      const payload = { username };
      const set = this.props.query.set ? '?set=true' : '';

      if (this.props.query && Object.keys(this.props.query).length) {
        payload.parameters = Util.toCoreQueryObject(this.props.query) || {};
        payload.parameters.username = username;
      }

      this.setState({ processing: true });
      const response = await this.api.post(`/user/authenticate/forgot${set}`, payload);
      this.setState({ processing: false });

      if (response.code > 299) {
        if (response.code === 429) {
          return this.onError({
            error: `A ${this.props.query.set ? 'set password' : 'password reset'} email has recently been sent. Please wait a moment before trying to resend.`
                    });
                }

                if (response.code === 400) {
                    return this.onError({
                      error: function() {
                          if (response.content.validation && response.content.validation.keys.length && response.content.validation.keys[0] === 'username') {
                              validation.username = 'Please enter a valid email address.';
                              that.setState({ validation });
                              return 'This doesn\'t look like a valid email address. Please check it and try again.';
                          }
                          return undefined;
                      }() || 'Please double-check your email address for mistakes and try again.'
          });
        }

        return this.onError({
          error: 'We\'re sorry, our servers are having trouble at the moment. Please try again later.'
        });
      }

      this.onError({
        error: 'Reset email sent.'
      });

      return this.setState({
        username,
        sent: true,
        reSent: this.state.sent,
        validation: { username: null }
      });
    }
    catch (e) {
      this.setState({ processing: false });
      return this.onError({ error: e.toString() });
    }
  }

  async onSubmitPressed(e) {
    if (e.key === 'Enter') {
      return await this.onSubmit();
    }
  }

  render() {
    const buttonStyle = !this.props.query.buttonColor ? null :
    {
      backgroundColor: `#${this.props.query.buttonColor}`
    };

    const buttonText = this.state.sent ? 'Resend Email' : 'Send Email';

    const containerStyle =
      this.props.query.noHeader ?
      this.props.classes.noHeaderContainer :
      this.props.classes.view;

    const emailInput = this.state.sent ? null : (
      <Grid item xs={12} md={12}>
        <StandardInput
          inputType="email"
          style={{ marginBottom: '15px' }}
          error={this.state.validation.username}
          attribute="username"
          label="Email Address"
          labelWidth={85}
          focus={true}
          value={this.state.username}
          onChange={this.generateOnInput('username')}
          onKeyUp={this.onSubmitPressed} />
      </Grid>
    );

    const infoText = this.state.sent ? (
      <Grid item xs={12} md={12}>
        <Typography className={this.props.classes.sentInfoText} color="initial" >
          If there is an account associated with <strong>{this.state.username}</strong>, a password reset email will be {this.state.reSent ? 're-' : ''}sent.
        </Typography>

        <Typography className={this.props.classes.sentInfoText} color="initial" >
          Remember, your reset link will expire in 24 hours, emails may take up to 10 minutes to arrive, and be sure to check your Spam folder.
        </Typography>

        <Typography className={this.props.classes.sentInfoText} color="initial" >
          Didn't receive an email? Don't have an account? <Link className={this.props.classes.linkText} onClick={this.goCreateAccount} href={`/gn`}>Click here to create one.</Link>
        </Typography>
      </Grid>
    ) : (
      <Grid item xs={12} md={12}>
        <Typography className={this.props.classes.infoText} color="initial" >
          Enter your email address and we will send you an email with a link to reset your password. Your reset link will expire in 24 hours.
        </Typography>
      </Grid>
    )

    return (
      <Grid container spacing={2} className={containerStyle}>
        <PopAlert open={this.state.pop} message={this.state.error} onClose={this.onRequestClose} />
        {infoText}
        {emailInput}
        <Grid item xs={12} md={12}>
          <Button style={buttonStyle} className={this.props.classes.button} color="primary" onClick={this.onSubmit} variant="contained">{buttonText}</Button>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography className={this.props.classes.signInText} color="initial" >
            Already have an account? <Link className={this.props.classes.linkText} onClick={this.goSignIn} to="/gn">Log in</Link>
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(StepForgotPassword);
