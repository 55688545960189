import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';

import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { setFlow } from '../../../../../actions/flow';

import ResetView from './views/reset';

const styles = theme => ({
  pageContainer: theme.pageContainer
});

class Page extends Component {
  componentWillUnmount() {
    this.props.dispatch(setFlow({}));
  }

  render() {
    let view = <ResetView query={this.props.query}/>;

    if (this.props.flow.isResetRedirect) {
      if (this.props.flow.redirect !== null) {
        return (
          <Redirect to={this.props.flow.redirect} />
        );
      }
    }

    return (
      <Grid container justify="center" className={this.props.classes.pageContainer}>
        <Grid item>
          {view}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    flow: state.flow
  };
}

const ConnectedPage = connect(mapStateToProps, null)(Page);

export default withStyles(styles)(ConnectedPage);
