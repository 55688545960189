import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';

import { withStyles } from '@material-ui/core';

import Loading from '../../../../../../base/shared/components/loading';

import DocUpload from './doc-upload';
import CollectInfo from './collect-info';
import Success from './success';
import {setError} from "../../../../../../../actions/auth";
import { setReturnTo } from '../../../../../../../actions/return';
import Api from "../../../../../../../common/api";


const styles = theme => ({
  pageContainer: theme.pageContainer
});

class Index extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      redirectTo: null,
      verificationId: null,
      programId: window.globals ? window.globals.gidStudentProgramId : '60c9e65288e17849464951fc',
      userStatus: null,
    };
    this.api = new Api();

    this.onVerificationId = this.onVerificationId.bind(this);
    this.onUserStatus = this.onUserStatus.bind(this);

  }

  onUserStatus(userStatus) {
    this.setState({ userStatus });
  }

  onVerificationId(verificationId) {
    this.setState({ verificationId });
  }

  async componentDidMount() {
    try {
      // The 2 indicates the page was accessed by navigating into the history
      if (performance.navigation.type === 2) {
         window.location.reload(true);
         return;
      }
      const type = 'student'

      // bypass reading golf-id status..it may still 'pending' and with the old
      // verificationId... force a restart so we can show the user our collect info form
      if(this.state.userStatus === '_forceRestart') {
        this.setState({
          loading: false
        })
        return
      }

      const userStatusResp = await this.api.get(`/user/identity/${type}/status`);

      if (!userStatusResp || userStatusResp.code === 401) {
        this.props.dispatch(setReturnTo('/identity'));
        return this.setState({ redirectTo: '/?logged_out=true' });
      }

      let userStatus = userStatusResp['content'];

      if (userStatus && userStatus.canReverify) {
        userStatus = null;
      }

      if (userStatus) {
        if (userStatus.status === 'pending') {
          // this.props.dispatch(setFlow({isDocUpload: true}));
          // setFlow({isDocUpload: true});
          if (userStatus.programId) {
            this.setState({
              verificationId: userStatus.verificationId,
              programId: userStatus.programId,
              userStatus: userStatus.status,
              loading: false
            })
          } else {
            this.setState({
              verificationId: userStatus.verificationId,
              userStatus: userStatus.status,
              loading: false
            })
          }
        }
        this.setState({
          loading: false,
          userStatus: userStatus.status,  // 'failed' or 'success'
          verificationId: userStatus.verificationId,
        })
      }
      this.setState({
        loading: false
      })
    }

    catch (e) {
      this.props.dispatch(setError( {
          code: null,
          field: null,
          message: e.toString(),
          rawMessage: e,
          toast:false
        }
      ));
    }
  }


  render() {

    if (this.state.redirectTo) {
      return <Redirect push to={this.state.redirectTo} />;
    }

    let view = <CollectInfo query={this.props.query}
                            verificationId={this.state.verificationId}
                            programId={this.state.programId}
                            onVerificationId={this.onVerificationId}
                            onUserStatus={this.onUserStatus}
    />;

    if (this.state.userStatus === 'pending') {
      view = <DocUpload query={this.props.query}
                        verificationId={this.state.verificationId}
                        programId={this.state.programId}
                        onVerificationId={this.onVerificationId}
                        onUserStatus={this.onUserStatus}
      />;
    }

    if (this.state.userStatus === 'success') {
      view = <Success query={this.props.query}
                      verificationId={this.state.verificationId}
                      programId={this.state.programId}
                      onVerificationId={this.onVerificationId}
                      onUserStatus={this.onUserStatus}
            />
    }

    return (
      <div className={this.props.classes.pageContainer}>
        {this.state.loading? <Loading/>: view}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    flow: state.flow,
  };
}

const ConnectedPage = connect(mapStateToProps, null)(Index);

export default withStyles(styles)(ConnectedPage);
