import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Popover from '@material-ui/core/Popover';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  passwordRulesContainer: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '2px 3px 8px #888888',
    width: '250px',
    marginTop: '10px',
  },
  passwordRulesHeader: {
    fontSize: '18px',
    fontWeight: 'bold',
    paddingLeft: '22px',
    paddingTop: '5px'
  },
  passwordRulesList: {
    marginBottom: '5px',
    marginTop: '0px'
  },
  arrow: {
    '&:after': {
      borderBottomColor: '#a53d38',
      borderTop: '0',
      top: '-20px',
      left: '50%',
      marginLeft: '-20px'
    }
  }


});

class StandardInput extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      focused: false,
      popoverAttach: null
    };

    this.onFocus = this.onFocus.bind(this);
  }

  onFocus(e) {
    if (!this.props.showPasswordRules) return;

    let curFocused = !this.state.focused;

    this.setState({focused: curFocused, popoverAttach: e.currentTarget});
  }

  render() {

    const {
      pattern = "",
      disabled = false,
      error = null,
      focus = false,
      value = undefined,
      id = null,
      attribute,
      label,
      labelWidth = 70,
      inputType = null,
      password = false,
      startAdornment = null,
      endAdornment = null,
      onChange = function () {},
      onKeyUp = function () {},
      style = {},
    } = this.props;
    const invalidChars = "+@#$%^&*()/<>'";

    return (
      <FormControl style={ style } error={error !== null} variant="outlined">
        <InputLabel htmlFor={attribute}>{label}</InputLabel>
        <OutlinedInput
          style={ { borderRadius: '10px' } }
          color="primary"
          id={id || attribute}
          pattern={pattern}
          disabled={disabled}
          value={value}
          type={!!inputType ? inputType : (!password ? 'input' : 'password')}
          autoFocus={focus}
          startAdornment={startAdornment}
          endAdornment={endAdornment}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onFocus={this.onFocus}
          onBlur={this.onFocus}
          labelWidth={labelWidth}
        />
        <Popper
          id={id}
          open={this.state.focused && this.props.showPasswordRules}
          anchorEl={this.state.popoverAttach}
          placement={'bottom-start'}
        >
          <Grid container spacing={1} className={`${this.props.classes.passwordRulesContainer} ${this.props.classes.arrow}`}>
            <Grid item xs={12} md={12}>
              <Typography className={this.props.classes.passwordRulesHeader}>Requirements</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <ul className={this.props.classes.passwordRulesList}>
                <li>
                  <Typography>Must be 10 characters</Typography>
                </li>
                <li>
                  <Typography>No spaces</Typography>
                </li>
                <li>
                  <Typography>Must contain one letter</Typography>
                </li>
                <li>
                  <Typography>Must contain one number</Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Popper>
        {
          error !== null ?
          <FormHelperText id={`${attribute}-error`}>{error}</FormHelperText>
          : null
        }
      </FormControl>
    );
  }
}

export default withStyles(styles)(StandardInput);
