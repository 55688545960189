export const setAlert = (state={}, action) => {
  switch (action.type) {
    case 'SET_ALERT':
      return action.alert;
    default:
      return state;
  }
}

export const setError = (state={}, action) => {
  switch (action.type) {
    case 'SET_ERROR':
      return action.error;
    default:
      return state;
  }
}

export const setHasValidated = (state=false, action) => {
  switch (action.type) {
    case 'SET_HAS_VALIDATED':
      return action.hasValidated;
    default:
      return state;
  }
}

export const setSignedIn = (state=false, action) => {
  switch (action.type) {
    case 'SET_IS_SIGNED_IN':
      return action.isSignedIn;
    default:
      return state;
  }
}

export const setUser = (state=null, action) => {
  switch (action.type) {
    case 'SET_USER':
      return action.user;
    default:
      return state;
  }
}
