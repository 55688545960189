import React from 'react';
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import Auth from '../../../../../../common/auth';

import StandardInput from '../../../../shared/components/standard-input';
import Heading from '../../../../shared/components/heading';
import CountryCodeAdornment from '../../../../../../common/components/country-code-adornment';

const styles = theme => ({
    text: {
        fontSize: '14px',
        marginBottom: '10px'
    },
    view: {
      ...theme.internalContainer,
      [theme.breakpoints.up('md')]: {
        padding: theme.internalContainer._themeMdPadding
      },
    },
    tooltipHeading: {
        fontSize: '15px',
        fontWeight: 'bold'
    },
    tooltipText: {
        fontSize: '13px'
    },
    twoFactorBlock: {
        marginBottom: '20px'
    },
    buttonControls: {
      display: "flex",
      justifyContent: "center",
      marginTop: "10px"
    },
});


class View extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
          enabled: false,
          open: false,
          type: null,
          areaCode: '',
          countryCode: '',
          countryCodeValue: 'US:+1',
          phoneNumber: '',
          validation: {
            phoneNumber: null
          }
        };

        this.handleInput = this.handleInput.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onTwoFactorTypeChange = this.onTwoFactorTypeChange.bind(this);
        this.onTwoFactorCheck = this.onTwoFactorCheck.bind(this);
        this.onTwoFactorCountryCodeChange = this.onTwoFactorCountryCodeChange.bind(this);
        this.onTwoFactorPhoneKeyUp = this.onTwoFactorPhoneKeyUp.bind(this);
    }

    componentDidMount() {
      const type = this.props.user.twoFactor.type;

      this.setState({
        enabled: this.props.user.twoFactor.enabled,
        type: type,
      })
    }

    handleInput(field) {

      return function (e) {

        return this.setState({
          [field]: e.target.value,
          validation: {
            phoneNumber: null
          }
        });
      }.bind(this);
    }

    handleTooltipClose() {

      this.setState({ open: false });
    }

    handleTooltip() {

      const open = !this.state.open;
      this.setState({ open: open });
    }

    async onSubmit() {

      const validation = {
        phoneNumber: this.state.enabled && this.state.type === 'phone' ?
        (
          this.state.phoneNumber.length ? null : 'Error: Please enter a phone number.'
        )
        : null
      };

      if (Object.values(validation).some((v) => { return v !== null; })) {
        return this.setState({ validation });
      }

      Auth.userUpdateTwoFactor(
        this.props.dispatch,
        this.props.user,
        this.state.enabled,
        this.state.type,
        this.state.phoneNumber,
        this.state.countryCode,
        this.state.areaCode
      );
    }

    onTwoFactorTypeChange(e, type) {
      this.setState({
        type
      });
    }

    onTwoFactorCheck() {
      const enabled = !this.state.enabled;
      this.setState({
        enabled
      });
    }

    onTwoFactorCountryCodeChange(e) {
      let areaCode = '';
      let countryCode = '';
      let countryCodeValue = e.target.value;
      const fields = e.target.value.split(':');

      if (fields.length === 2) {
        countryCode = fields[0];
        areaCode = fields[1];
      }

      return this.setState({
        areaCode,
        countryCode,
        countryCodeValue
      });
    }

    onTwoFactorPhoneKeyUp(e) {
      return this.setState({ phoneNumber: e.target.value });
    }

    render() {

      return (
        <Card elevation={3} className={this.props.classes.view}>
          <CardContent>
            <Grid item xs={12}>
              <Heading text='Two-Factor Settings'/>
              <Typography color="textSecondary" className={this.props.classes.text}>Two-factor authentication keeps your account secure by sending you an authorization code before you log in.</Typography>
            </Grid>
            <Grid item xs={12} className={this.props.classes.twoFactorBlock}>
              <FormControlLabel
                  control={<Checkbox checked={this.state.enabled} color="primary" onChange={this.onTwoFactorCheck} />}
                  label="Enable 2-FA"/>
              <ClickAwayListener onClickAway={this.handleTooltipClose.bind(this)}>
                <Tooltip
                  open={this.state.open}
                  placement="top"
                  title={
                    <div>
                        <p className={this.props.classes.tooltipHeading}>Two-Factor Authentication (2-FA)</p>
                        <p className={this.props.classes.tooltipText}>Enabling 2-FA enhances your account security by requiring you to enter a 6-digit code sent to your email or phone before logging in.</p>
                        <p className={this.props.classes.tooltipText}>Golf Account will not share your phone number with anyone, for any reason.</p>
                    </div>
                  }>
                    <InfoIcon onClick={this.handleTooltip.bind(this)} style={{cursor: 'pointer', position: 'relative', top: '4px', width: '16px', height: '16px'}} />
                </Tooltip>
              </ClickAwayListener>
            </Grid>
            <Grid item style={{ display: this.state.enabled ? 'block' : 'none' }}>
              <RadioGroup
                  aria-label="two-factor-preference"
                  name="twoFactorPreference"
                  value={this.state.type}
                  onChange={this.onTwoFactorTypeChange}>
                <FormControlLabel value="email" control={<Radio color="primary"/>} label="Email" />
                <FormControlLabel value="phone" control={<Radio color="primary"/>} label="Phone" />
              </RadioGroup>
              <Grid item style={{ display: (this.state.enabled && this.state.type === 'phone' ? 'block' : 'none') }}>
                <StandardInput
                    value={this.state.phoneNumber}
                    style={{ marginBottom: '2rem' }}
                    error={this.state.validation.phoneNumber}
                    attribute="phoneNumber"
                    label="Phone Number"
                    labelWidth={110}
                    focus={true}
                    startAdornment={
                        <CountryCodeAdornment
                            onChange={this.props.onTwoFactorCountryCodeChange}
                            value={this.state.countryCodeValue} />
                    }
                    onChange={this.handleInput('phoneNumber')}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button className={this.props.classes.buttonControls} color="primary" onClick={this.onSubmit} variant="contained">Update 2-FA</Button>
            </Grid>
          </CardContent>
        </Card>
      );
    }
}

const mapStateToProps = state => {
  return {
    error: state.error,
    user: Auth.userState(state)
  };
}

const ConnectedView = connect(mapStateToProps, null)(View);

export default withStyles(styles)(ConnectedView);
