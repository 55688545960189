import React from 'react';
import { connect } from 'react-redux'
import debounce from 'lodash.debounce';
import { Redirect } from 'react-router-dom';

import Api from "../../../../../../../common/api";
import Verification from '../../../../../../../common/verification';

import {setError} from '../../../../../../../actions/auth';

import StandardInput from '../../../../../shared/components/standard-input';
import PageHeader from '../../../../../shared/components/page-header';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import PopAlert from "../../../../../shared/components/pop-alert";
import LeftRail from "../../../../../shared/components/left-rail";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import DateInput from "../../date-input";

const styles = theme => ({
    view: {
      ...theme.internalContainer,
      [theme.breakpoints.up('md')]: {
        padding: theme.internalContainer._themeMdPadding
      },
    },
    buttonControls: {
      display: "flex",
      justifyContent: "center",
      marginTop: "35px"
    },
    buttonPrimary: {
      backgroundColor: "#0076BB",
      padding: "15px 20px",
      borderRadius: "4px",
      display: "inline-block",
      margin: "0 25px 0 0",
      width: "auto",
    },
    card: {
      background: theme.palette.background.light,
    }
});

class CollectInfoView extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      birthDate: '',
      email: '',
      organization:  null,
      organizationsList: [],
      loading: false, //for OrgList

      validation: {
        firstName: null,
        lastName: null,
        email: null,
        organization: null,
        birthDate: null
      },

      pop: this.props.error.toast,
      processing: false,
      redirectTo: null,
    };

    this.api = new Api();

    this.handleInput = this.handleInput.bind(this);
    // this.onChangeSuccess = this.onChangeSuccess.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitPress = this.onSubmitPress.bind(this);
    this.setOrgValue = this.setOrgValue.bind(this);
    this.onOrgInputChange = this.onOrgInputChange.bind(this);
    this.goHome = this.goHome.bind(this);
    this.onMessageClose = this.onMessageClose.bind(this);

    this.MAX_YEAR = new Date().getFullYear() - 15
    this.MIN_YEAR = this.MAX_YEAR - 80
  }

  async fetchOrgList(value) {
      this.setState( { loading: true })
      this.api.get(`/user/identity/teacher/organizations?search=` + encodeURIComponent(value))
          .then( (res) => {
              this.setState( {
                  organizationsList: res.content,
                  loading: false,
              })
          })
          .catch(err => {
              console.error("fech org error", err);
          });
  }

  onOrgInputChange(event, value) {
        if (value && value.length >= 2) {
            this.fetchOrgList(value)
      }
  }

  setOrgValue(value) {
      let validationClone = this.state.validation;
      validationClone['organization'] =  null;
      if (value) {
        this.setState({
          organization: {id:value.id, name:value.name},
          validation: validationClone
        })
      } else {
        this.setState({
          organization: null,
          validation: validationClone
        })
      }
  }

  setDob = (formattedValue, dateValue) => {
    let validationClone = this.state.validation;
    if (formattedValue !== "") {
      validationClone['birthDate'] = null
      if (formattedValue !== "") {
          if (dateValue.getFullYear() < this.MIN_YEAR || dateValue.getFullYear() > this.MAX_YEAR) {
            validationClone['birthDate'] = "Invalid date"
            formattedValue = ""
          }
      } else {
          validationClone['birthDate'] = null
      }
    }
    this.setState({ birthDate: formattedValue, validationClone })
  }

  handleInput(name) {
    return function (e) {
      let validationClone = this.state.validation;
      validationClone[name] =  null;
      this.setState({
        [name]: e.target.value,
        validation: validationClone
      });

      if (this.props.error.field) {
        this.props.dispatch(setError({}));
      }
    }.bind(this);
  }

  // onChangeSuccess() {
  //   console.log("ok success")
  //   this.props.onUserStatus('success');
  //
  //   this.props.dispatch(setError( {
  //       code: null,
  //       field: null,
  //       message: "You have been sucessfully validated!",
  //       rawMessage: "You have been sucessfully validated!",
  //       toast: true
  //     }
  //   ));
  // }

  onMessageClose() {
    this.props.dispatch(setError({}));
  }


  async onSubmit() {
    let validation = {
      firstName: this.state.firstName === '' ? 'Please enter your first name.' : null,
      lastName: this.state.lastName === '' ? 'Please enter your last name.' : null,
      email: this.state.email === '' ? 'Please enter your email address.' : null,
      birthDate: this.state.birthDate === '' ? 'Please enter a valid date of birth.' : null,
      organization: this.state.organization instanceof Object && this.state.organization.hasOwnProperty('id') ? null : 'Please select an organization',
    };

    if (Object.values(validation).some((v) => { return v !== null; })) {
      return this.setState({ validation });
    }

    const query = Object.assign(this.props.query, {});
    let parameters = undefined;

    if (query.client_id) {
      delete query.identity;

      parameters = {
        response_type: query.response_type,
        client_id: query.client_id,
        redirect_uri: query.redirect_uri,
        scope: query.scope,
        state: query.state,
        code_challenge: query.code_challenge,
        code_challenge_method: query.code_challenge_method
      }
    }

    let payload = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      organization: this.state.organization,
      birthDate: this.state.birthDate,
      email: this.state.email,
      parameters: parameters
    };

    // console.log("Verification.startProcess submit", payload)

    this.setState({processing: true});
    const resp = await Verification.startProcess(this.props.dispatch, 'teacher', payload);
    this.setState({processing: false});

    // console.log('Verification.startProcess done', resp)
    if (resp) {
      if (resp.content['currentStep'] === 'success') {
        this.props.onVerificationId(resp.content['verificationId'])
        this.props.onUserStatus('success');
        return
      }
      // an other sheerId step will be treated as pending on our end
      this.props.onVerificationId(resp.content['verificationId'])
      this.props.onUserStatus('pending');
    }
  }

  async onSubmitPress(e) {
    if (e.key === 'Enter') {
      return await this.onSubmit();
    }
  }

  async componentDidMount() {
    try {
      this.fetchOrgList('+')
    }
    catch (e) {
      this.props.dispatch(setError( {
                code: null,
                field: null,
                message: e.toString(),
                rawMessage: e,
                toast:false
              }
      ));
    }
  }

  goHome(e) {
    e.preventDefault();
    this.setState({redirectTo: '/'});
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect push to={this.state.redirectTo} />;
    }

    let orgError = this.state.validation.organization || null
    let dobError = this.state.validation.birthDate || null

    let view = (
        <Grid item xs={12} md={6}>
          <Card elevation={13} className={this.props.classes.card}>
            <CardContent>
              <Grid container direction="column" spacing={0}>
                <Grid item xs={12}>
                  <PageHeader text='Verify your Teacher Status'/>
                </Grid>

              <Grid container direction="row">
                <FormControl error={orgError !== null}>
                  <Autocomplete
                      id="organization-select"
                      style={{ width: '95%' }}
                      error={this.state.validation.organization || null}
                      options={this.state.organizationsList}
                      filterSelectedOptions
                      loading={this.state.loading}
                      autoHighlight
                      getOptionSelected={(option) => option.id }
                      getOptionLabel={(option) => option.name }
                      onInputChange={debounce(this.onOrgInputChange, 350)}
                      onChange={(event, newValue) => {
                          this.setOrgValue(newValue);
                      }}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              label="Choose an organization"
                              // variant="outlined"
                              inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password', // disable autocomplete and autofill
                              }}
                          />
                        )}
                  />
                  {
                      orgError !== null ?
                          <FormHelperText id={`organization-select-error`}>{orgError}</FormHelperText>
                          : null
                  }
                </FormControl>
              </Grid>

              <Grid container direction="row">

                <DateInput onChange={this.setDob}></DateInput>
                <FormControl error={dobError !== null}>

                  {
                    dobError !== null ?
                      <FormHelperText id={`dob-select-error`}>{dobError}</FormHelperText>
                      : null
                  }
                </FormControl>

              </Grid>

              <Grid container direction="row">
                    <Typography variant="caption" display="inline" >
                        Date of birth - used for verification purposes only</Typography>
              </Grid>


              <Grid container direction="row">
                <Grid item xs={12} sm={6}>
                  <StandardInput
                      id="firstName"
                      required={true}
                      inputType={'text'}
                      value={this.state.firstName}
                      style={{ marginBottom: '10px', width: '90%' }}
                      error={this.state.validation.firstName || null}
                      attribute="first-name"
                      label="First Name *"
                      labelWidth={100}
                      onChange={this.handleInput('firstName')}
                      onKeyUp={this.onSubmitPress} />
                </Grid>

                <Grid item xs={12} sm={6}>
                   <StandardInput
                     id="lastName"
                     required={true}
                     inputType={'text'}
                     value={this.state.lastName}
                     style={{ marginBottom: '10px', width: '90%' }}
                     error={this.state.validation.lastName || null}
                     attribute="last-name"
                     label="Last Name *"
                     labelWidth={100}
                     onChange={this.handleInput('lastName')}
                     onKeyUp={this.onSubmitPress} />
                   </Grid>
                </Grid>

                <Grid direction="row" container>
                  <Grid item xs={12}>
                    <StandardInput
                        inputType="email"
                        required={true}
                        value={this.state.email}
                        style={{ width: '95%' }}
                        // error={usernameErr}
                        error={this.state.validation.email || null}
                        attribute="email"
                        label="Email *"
                        labelWidth={116}
                        focus={true}
                        onChange={this.handleInput('email')}
                        onKeyUp={this.onSubmitPress} />
                    </Grid>
                </Grid>


                <Grid direction="row" container spacing={5}>
                  <Grid item xs={6}>
                    <Button className={this.props.classes.buttonControls} color="primary" id="cancel" onClick={this.goHome} variant="contained">
                     Cancel
                    </Button>
                  </Grid>

                  <Grid item xs={6}>
                    <Button className={this.props.classes.buttonControls} color="primary" id="password-change-submit" onClick={this.onSubmit} variant="contained">
                        {this.state.processing ?
                            (<CircularProgress size={14} color="inherit" />)
                            : 'Verify My Status'
                        }
                      </Button>
                    </Grid>
                </Grid>

                <Grid item>
                    <Typography  variant="caption" display="block" gutterBottom>
                        By clicking, I consent to processing my information for the purpose of verifying my eligibility as described within the <a href="https://www.sheerid.com/privacy-policy/" target="_blank"  rel="noopener noreferrer" >SheerID Privacy Policy</a>. I understand that my information will be shared with SheerID.<br/>
                        Verification services powered by SheerID <a href="https://offers.sheerid.com/sheerid/help-center/" target="_blank" rel="noopener noreferrer" >Get Help</a>

                    </Typography>
                </Grid>

          </Grid>
        </CardContent>
      </Card>
        </Grid>
    );


    return (
          <Grid container spacing={0} className={this.props.classes.view}>
            <PopAlert open={this.props.error.toast} message={this.props.error.message} onClose={this.onMessageClose}  hasExit={true}/>

            <Grid item xs={12} md={6}>
              <LeftRail />
            </Grid>
              {view}
          </Grid>
    );

  }
}

const mapStateToProps = state => {
  return {
    alert: state.alert,
    error: state.error,
    flow: state.flow
  };
}

const ConnectedView = connect(mapStateToProps, null)(CollectInfoView);

export default withStyles(styles)(ConnectedView);
