import React from 'react';
import { Link } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import Api from '../../../../../../common/api';
import Util from '../../../../../../common/util';

const styles = theme => ({
    centerText: {
        textAlign: 'center',
    },
    dialogActions: {
        justifyContent: 'center',
        margin: '8px 4px 16px'
    },
    dialogBodyApplicationList: {
        marginTop: '0px'
    },
    dialogBodyText: {
        display: 'block',
        paddingBottom: '15px'
    },
    dialogContent: {
        paddingBottom: '0px'
    },
    dialogLink: {
        color: '#666',
        fontWeight: 'bold'
    },
    errorLink: {
        textDecoration: 'none',
        cursor: 'pointer',
        fontWeight: 'bold',
        color: '#FFF'
    },
    errorText: {
        margin: '0 0 10px',
        '&:last-of-type': {
            marginBottom: 0
        }
    }
});

class AuthInitiationRequestView extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            application: {},
            open: false,
            error: null
        };

        this.onApprove = this.onApprove.bind(this);
        this.onDeny = this.onDeny.bind(this);
        this.getApplication = this.getApplication.bind(this);
        this.runOauthFlow = this.runOauthFlow.bind(this);
        this.applyOauthResponses = this.applyOauthResponses.bind(this);
        this.api = new Api();
    }

    async onApprove() {

        try {
            const response = await this.api.post(`/application/${this.props.query.client_id}/grant`);
            if (response.code !== 202 && response.code !== 304) {
                return this.props.onError({
                    error: (
                        <div>
                            <p className={this.props.classes.errorText}>Sorry, we were unable to update your settings at this time.</p>
                            <p className={this.props.classes.errorText}>Please contact support for more details.</p>
                            <p className={this.props.classes.errorText}><a className={this.props.classes.errorLink} href="/">Click here</a> to go to your Golf Account account information.</p>
                        </div>
                    )
                })
            }
        }
        catch (e) {
            return this.props.onError({ error: e.toString() });
        }

        const auth = await this.runOauthFlow();
        return this.applyOauthResponses(auth);
    }

    onDeny() {

        this.props.onDeny(
            this.state.application.applicationNames ?
            this.state.application.organizationName :
            this.state.application.applicationName
        );
    }

    async getApplication() {
        try {
            const application = await this.api.get(`/application/${this.props.query.client_id}`);
            if (application.code !== 200) {
                if (application.code === 404) {
                    throw new Error(`We're sorry. This application either doesn't exist or has been disabled. Please call support for assistance, and mention this client_id: "${this.props.query.client_id}"`);
                }
                throw new Error('Invalid status code while fetching application data. Please try again later.' );
            }

            return application.content;
        }
        catch (e) {
            throw e;
        }
    }

    async runOauthFlow() {

        const granted = await this.api.get(`/application/token${Util.toCoreQueryString(this.props.query)}`);
        return {
            code: granted.code,
            context: await async function () {

                switch (granted.code) {
                    case 200: return { granted: granted.content };
                    case 401: return { logout: true };
                    case 403: return { application: await this.getApplication() };
                    case 428: return { reconcile: true };
                    default: return { code: granted.code };
                }
            }.bind(this)()
        };
    }

    applyOauthResponses(results) {

        if (results.context.logout) {
            return this.props.onLogout();
        }
        else if (results.context.reconcile) {
            return this.props.onRequiresReconciliation();
        }
        else if (results.context.application) {
            if (this.props.pixel)
              return this.props.onApprove(null);

            return this.setState({ open: true, application: results.context.application });
        }
        else if (results.context.granted) {
            return this.props.onApprove(results.context.granted);
        }
        else {
            return this.props.onError({
                error: (
                    <div>
                        <p className={this.props.classes.errorText}>Sorry, the application that sent you here is either misconfigured, or cannot be found in our system.</p>
                        <p className={this.props.classes.errorText}>Please contact support for more details.</p>
                        <p className={this.props.classes.errorText}>
                            <a className={this.props.classes.errorLink} href="/">Click here</a> to go to your Golf Account account information.
                        </p>
                    </div>
                ),
                critical: true
            });
        }
    }

    async componentDidMount() {

        if (this.props.open) {
            try {
                const results = await this.runOauthFlow();
                this.applyOauthResponses(results);
            }
            catch (e) {
                return this.props.onError({ error: e.stack || e.toString() });
            }
        }
    }

    render() {
        const displayables = this.state.application.displayable ? this.state.application.displayable : {};
        const termsUrl = displayables.termsAndConditionsUrl ? displayables.termsAndConditionsUrl : null;
        const privacyUrl = displayables.privacyPolicyUrl ? displayables.privacyPolicyUrl : null;
        let applicationName = this.state.application.applicationName

        if (displayables.content && displayables.content.title)
          applicationName = displayables.content.title;

        let policyLinks = null;

        if (termsUrl !== null && privacyUrl !== null) {
          policyLinks = <span className={this.props.classes.dialogBodyText + ' ' + this.props.classes.centerText}>I agree to the <a href={termsUrl}>Terms of Use</a> and <a href={privacyUrl}>Privacy Policy</a></span>;
        }

        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={Object.keys(this.state.application).length > 0 && this.props.open}
            >
                {
                    this.state.application.applicationNames ?
                    (
                        <DialogTitle>
                            Authorize these applications?
                        </DialogTitle>
                    )
                    :
                    (
                        <DialogTitle>
                                Authorize <strong>{this.state.application.applicationName}</strong>?
                        </DialogTitle>
                    )
                }
                <DialogContent className={this.props.classes.dialogContent}>
                    {
                        this.state.application.applicationNames ?
                        (
                            <DialogContentText component="div">
                                <span className={this.props.classes.dialogBodyText}>The following applications by <strong>{this.state.application.organizationName}</strong> would like to access your account information.</span>
                                <ul className={this.props.classes.dialogBodyApplicationList}>
                                    {this.state.application.applicationNames.map((a) => {

                                        return (<li key={a}>{a}</li>)
                                    })}
                                </ul>
                                <span className={this.props.classes.dialogBodyText}>You can always manage access to your data on your <Link className={this.props.classes.dialogLink} to="/preferences">Preferences</Link> page.</span>
                                {policyLinks}
                            </DialogContentText>
                        )
                        :
                        (
                            <DialogContentText>
                                <span className={this.props.classes.dialogBodyText}>The application <strong>{applicationName}</strong> by <strong>{this.state.application.organizationName}</strong> would like to access your account information.</span>
                                <span className={this.props.classes.dialogBodyText}>By authorizing this application you are agreeing to its terms, conditions and privacy policy.</span>
                                <span className={this.props.classes.dialogBodyText}>You can always manage access to your data on your <Link className={this.props.classes.dialogLink} to="/preferences">Preferences</Link> page.</span>
                                {policyLinks}
                            </DialogContentText>
                        )
                    }
                </DialogContent>
                <DialogActions className={this.props.classes.dialogActions}>
                    <Button color="primary" onClick={this.onDeny} variant="contained">Decline</Button>
                    <Button color="primary" onClick={this.onApprove} variant="outlined">Approve</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(withMobileDialog()(AuthInitiationRequestView));
