import React from 'react';
import { Redirect } from 'react-router';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from "@material-ui/core/Typography";

import Heading from '../../../../shared/components/heading';

const styles = theme => ({
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  },
  text: {
    color: '#666',
    fontSize: '14px'
  },
  centerText: {
    textAlign: 'center'
  },
  progress: {
    marginTop: '30px'
  },
  homeLinkText: {
    fontSize: '14px',
    paddingTop: '40px',
    textAlign: 'center'
  },
  linkText: {
    color: '#222222',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
});

class RedirectView extends React.Component {
  constructor(props) {
      super(props);

      this.state = {
          displayHomeLink: false,
          redirect: null
      };

      this.onHomeClick = this.onHomeClick.bind(this);
      this.onTimeout = this.onTimeout.bind(this);

      setTimeout(this.onTimeout, 15 * 1000);
  }

  onHomeClick(e) {
    e.preventDefault();
    this.setState({ redirect: '/cancel' });
  }

  onTimeout() {
    this.setState({displayHomeLink: true});
  }

  render() {
    let homeLink = null;

    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    if (this.state.displayHomeLink) {
      homeLink = (
        <Grid item xs={12} md={12}>
          <Typography className={this.props.classes.homeLinkText} color="initial" >
            <Link className={this.props.classes.linkText} onClick={this.onHomeClick} to="/">Back to home</Link>
          </Typography>
        </Grid>
      );
    }

    window.location.href = decodeURIComponent(this.props.redirectTo);

    return (
      <Grid className={this.props.classes.centerText} container spacing={0} justify="center">
        <Grid item xs={12} sm={12}>
          <Heading text="Redirecting" />
        </Grid>
        <Grid item xs={12} sm={12}>
          <CircularProgress className={this.props.classes.progress} size={40} color="inherit" />
        </Grid>
        {homeLink}
      </Grid>
    );
  }
}

export default withStyles(styles)(RedirectView);
