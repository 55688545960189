import React from 'react';
import { connect } from 'react-redux'

import Auth from '../../../../../../common/auth';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  }
});

class View extends React.Component {

  constructor(props) {

    super(props);

    this.onLogout = this.onLogout.bind(this);
  }

  async componentWillMount() {

    if (this.props.query.aslogin) {
      return this.setState({
        username: this.props.query.aslogin
      });
    }
  }

  onLogout() {
    Auth.userLogout(this.props.dispatch);
  }

  redirect(redirect) {
    this.setState({ redirect });
  }

  render() {
    return (
      <Grid container spacing={0} className={this.props.classes.view}>
        <Grid item xs={12} md={6}>

          <Typography className={this.props.classes.listLabel}>
            TwoFactor
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    flow: state.flow
  };
}

const ConnectedView = connect(mapStateToProps, null)(View);

export default withStyles(styles)(ConnectedView);
