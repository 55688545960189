import React from 'react';
import { Redirect } from 'react-router';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import Heading from '../../../shared/components/heading';

const styles = theme => ({
  pageContainer: theme.pageContainer,
  link: {
    cursor: 'pointer'
  },
  text: {
    fontSize: '14px',
    margin: 0,
  },
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  },
});

class NotFoundApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null
    };

    this.goHome = this.goHome.bind(this);
  }

  goHome(e) {
    e.preventDefault();
    this.setState({redirect: '/'});
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    return (
      <div className={this.props.classes.pageContainer}>
        <Grid container spacing={0} className={this.props.classes.view} justify="center">
          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Heading text="Not Found!" />
              </Grid>
              <Grid item xs={12}>
                <Typography color="textPrimary">
                  <p className={this.props.classes.text}>
                    We've got nothing for you here! <Link color="textPrimary" className={this.props.classes.link} onClick={this.goHome} href="/"><strong>Click here</strong></Link> to return to the Golf Account home page.
                  </p>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(NotFoundApp);
