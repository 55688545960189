import React, { Component }  from 'react';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  footer: {
    //bottom: 0,
    display: 'block',
    //left: 0,
    //position: 'fixed',
    paddingTop: '20px'
  },
  footerContainer: {
  },
  footerDivider: {
    height: '2px'
  },
  footerItem: {
    fontSize: '13px',
    fontWeight: 'normal',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px'
    },
  },
  footerLink: {
    textDecoration: 'none',
  },
  footerModeToggleIcon: {
    cursor: 'pointer',
    width: '15px'
  },
  footerModeToggleContainer: {
    paddingTop: '8px'
  },
  footerStopHand: {
    height: '16px',
    marginRight: '4px',
    width: '16px'
  },
  footerText: {
    fontSize: '13px',
    fontWeight: 'normal',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px'
    },
  }
});

class Footer extends Component {
  constructor(props) {
    super(props);

    this.theme = 1;

    this.toggleDayNight = this.toggleDayNight.bind(this);
  }

  toggleDayNight() {
    const mode = this.props.mode === 'light' ? 'dark' : 'light';
    this.props.toggleMode(mode);
  }

  render() {
    return (
      <footer className={this.props.classes.footer}>
        <Grid container spacing={1} justify="center" alignContent="center" direction="column" className={this.props.classes.footerContainer}>
          <Grid item>
            <Typography color="textSecondary">
              COPYRIGHT &copy; 2020 NBC UNIVERSAL, ALL RIGHTS RESERVED.
            </Typography>
          </Grid>
          <Grid item>
            <Grid container justify="center" spacing={2}>
              <Grid item>
                <Link style={{ cursor: "pointer" }} color="textSecondary" href={this.props.privacyPolicyUrl}>PRIVACY POLICY - NEW</Link>
              </Grid>
              <Grid item>
                <Link style={{ cursor: "pointer" }} color="textSecondary" href={this.props.termsAndConditionsUrl}>TERMS &amp; CONDITIONS</Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
          <Grid container justify="center" spacing={2}>
            <Grid item>
                <Link style={{ cursor: "pointer" }} color="textSecondary" href={this.props.privacyOptOut}>DO NOT SELL MY PERSONAL INFORMATION</Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </footer>
    );
  }
}

export default withStyles(styles)(Footer);
