import React, { Component } from 'react';
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import Auth from '../../../../../common/auth';

import AuthRequestView from './views/auth-request';
import GeneralView from './views/general';
import LoginView from './views/login';
import ReconcileView from './views/reconcile';
import TwoFactorView from './views/two-factor';
import UpgradeView from './views/upgrade';


const styles = theme => ({
  pageContainer: theme.pageContainer
});

class Page extends Component {
  render() {
    let view = <LoginView query={this.props.query}/>;

    if (!this.props.query.reauthenticate) {
      if (this.props.user.isSignedIn) {
        view = <GeneralView query={this.props.query}/>;

        if (this.props.flow) {
          if (this.props.flow.isAuthInitiationRequest) {
            view = <AuthRequestView query={this.props.query}/>;
          }
        }
      }

      if (this.props.flow) {
        if (this.props.flow.isTwoFactor) {
          view = <TwoFactorView query={this.props.query}/>;
        } else if (this.props.flow.isUpgradeAccount) {
          view = <UpgradeView query={this.props.query}/>;
        } else if (this.props.flow.isReconciliation) {
          view = <ReconcileView query={this.props.query}/>;
        }
      }
    }

    return (
      <Grid container justify="center" className={this.props.classes.pageContainer}>
        <Grid item>
          {view}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    flow: state.flow,
    user: Auth.userState(state)
  };
}

const ConnectedPage = connect(mapStateToProps, null)(Page);

export default withStyles(styles)(ConnectedPage);
