import React from 'react';

import Local from '../../../../../../common/local';
import Util from '../../../../../../common/util';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  buttonControls: {
    display: "flex",
    justifyContent: "center",
    marginTop: "25px"
  }
});

class Logout extends React.Component {

    constructor(props) {

        super(props);
        this.onLogoutClick = this.onLogoutClick.bind(this);
    }

    onLogoutClick() {

        Local.userLogout(Local.getCurrentUser().username);
        const newQuery = Object.assign(this.props.query, {});
        if (this.props.query.was_reauthenticating) {
            newQuery.reauthenticate = true;
        }
        window.location.href = `/${Util.toQueryString(this.props.query, ['was_reauthenticating'])}`;
    }

    render() {

        return (
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Button className={this.props.classes.buttonControls}
                        color="primary"
                        id="logout-click"
                        onClick={this.onLogoutClick}
                        style={{
                            width: '100%'
                        }}
                        variant="contained">
                        Click Here to Logout
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(Logout);
