import React, { Component } from 'react';


import { withStyles } from '@material-ui/core';


const styles = theme => ({
  pageContainer: theme.pageContainer
});

class Page extends Component {
  render() {
    return (
      <div className={this.props.classes.pageContainer}>
        NOT FOUND
      </div>
    );
  }
}


export default withStyles(styles)(Page);
