import Url from 'url';

import Api from './../api';
import Local from './../local';
import Util from './../util';

import { setFlow } from './../../actions/flow';
import { setAlert, setError, setHasValidated, setSignedIn, setUser } from './../../actions/auth';

const api = new Api();

const _dispatchErrors = (dispatch, data) => {
  if (data.toast) {
    dispatch(setAlert({
      message: data.message,
      open: true
    }));
  }

  dispatch(setError({
    code: data.code,
    field: data.field,
    message: data.message,
    previous: data.previous,
    rawMessage: data.rawMessage,
    toast: data.toast,
  }));
}

const getApplication = async (dispatch, clientId) => {
  const application = await api.get(`/application/${clientId}`);
  if (application.code !== 200) {
      let message = "";
      if (application.code === 404) {
          message = `We're sorry. This application either doesn't exist or has been disabled. Please call support for assistance, and mention this client_id: "${clientId}"`;
      }
      message = 'Invalid status code while fetching application data. Please try again later.';

      return _dispatchErrors(
        dispatch,
        {
          code: application.code,
          field: null,
          message: message,
          rawMessage: application.content.message,
          toast: true
        }
      );
  }

  return application.content;
};

const postMessage = (code, state, uri) => {
  let callTo = window.parent;
  if (window.opener) callTo = window.opener;

  return callTo.postMessage({
    code: code,
    state: state,
    uri: uri
  }, '*');
}

const twoFactorUpdated = (user, data) => {
  if (user.twoFactor.enabled !== data.enabled) return true;
  if (user.twoFactor.type !== data.type) return true;
  if (user.twoFactor.data !== data.data) return true;

  return false;
}

const _doLogin = async (dispatch, username, password, recaptcha, code=null) => {
  try {
    const query = Util.queryToObject(window.location.search);
    let payload = {
      username,
      password,
      recaptcha,
      parameters: Util.toCoreQueryObject(query)
    };

    if (code) {
      payload.code = code;
    }

    const response = await api.post(
      `/user/authenticate`,
      payload
    );


    if (!response.code) {
      return _dispatchErrors(
        dispatch,
        {
          code: null,
          field: null,
          message: "We're sorry. Our servers are having trouble logging you in right now.",
          rawMessage: null,
          toast: true
        }
      );
    }

    if (response.code === 400) {
      let message = 'Please double-check your email address for mistakes and try again.';

      if (response.content.validation && response.content.validation.keys.length && response.content.validation.keys[0] === 'username') {
        message = 'This doesn\'t look like a valid email address. Please check it and try again.';
      }

      return _dispatchErrors(
        dispatch,
        {
          code: response.code,
          field: 'username',
          message: message,
          rawMessage: response.content.message,
          toast: false
        }
      );
    }

    if (response.code === 401) {
      return _dispatchErrors(
        dispatch,
        {
          code: response.code,
          field: null,
          message: "We couldn't find a user with that address, or the password didn't match. Please check your credentials and try again.",
          rawMessage: response.content.message,
          toast: true
        }
      );
    }
    else if (response.code === 422) {
      return dispatch(setFlow({
        isResetPassword: true,
        username: username,
      }));
    }
    else if (response.code === 423) {
      return _dispatchErrors(
        dispatch,
        {
          code: response.code,
          field: null,
          message: "This account has been temporary locked for too many failed login attempts. Please try again in a while, or contact customer support for immediate assistance.",
          rawMessage: response.content.message,
          toast: true
        }
      );
    }
    else if (response.code === 417) {
      // If a code was passed, we are attempting 2FA, 417 results in a bad code
      if (code) {
        return _dispatchErrors(
          dispatch,
          {
            code: response.code,
            field: 'code',
            message: 'Your 2-FA code is either incorrect or expired.',
            rawMessage: response.content.message,
            toast: false
          }
        );
      }

      return dispatch(setFlow({
        isTwoFactor: true,
        password: password,
        username: username,
      }));
    }
    else if (response.code === 426) {
      return dispatch(setFlow({
        isUpgradeAccount: true,
        username: username,
      }));
    }
    else if (response.code === 428) {
      return dispatch(setFlow({
        isReconciliation: true,
        username: username,
      }));
    }

    if (!response.content || !response.content.token) {
      return _dispatchErrors(
        dispatch,
        {
          code: null,
          field: null,
          message: "We're sorry. Our servers are having trouble logging you in right now.  Please try again later",
          rawMessage: null,
          toast: true
        }
      );
    }

    Local.userLogin(response.content.token);
    dispatch(setError({}));
    dispatch(setFlow({}));
    const reauthed = query.reauthenticate ? true : false;
    await userValidate(dispatch, reauthed);

  } catch (e) {
    return _dispatchErrors(
      dispatch,
      {
        code: null,
        field: null,
        message: e,
        rawMessage: e,
        toast: true
      }
    );
  }
};

const userValidate = async (dispatch, reauthed=false) => {
  if (Local.hasToken()) {
    try {
      const result = await api.get('/user');
      if (result.code === 401) {
        dispatch(setSignedIn(false));
        dispatch(setHasValidated(true));
        return null;
      }

      dispatch(setUser(result.content));
      dispatch(setSignedIn(true));
      dispatch(setHasValidated(true));

      const query = Util.queryToObject(window.location.search);
      if (Util.isAuthInitiationRequest(query)) {
        dispatch(setFlow({isAuthInitiationRequest: true, isReauth: reauthed}));
      }
    }
    catch (error) {
      return error
    }
  } else {
    dispatch(setSignedIn(false));
    dispatch(setHasValidated(true));
  }
};

export default {
  applicationGrant: async (dispatch) => {
    const query = Util.queryToObject(window.location.search);
    try {
      const response = await api.post(`/application/${query.client_id}/grant`);
      if (response.code !== 202 && response.code !== 304) {
        return _dispatchErrors(
          dispatch,
          {
            code: response.code,
            field: null,
            message: `Sorry, we were unable to update your settings at this time.`,
            rawMessage: response.content.message,
            toast: false
          }
        );
      }
    }
    catch (e) {
      return _dispatchErrors(
        dispatch,
        {
          code: null,
          field: null,
          message: e,
          rawMessage: e,
          toast: true
        }
      );
    }
  },

  applicationRevoke: async (dispatch, application) => {
    try {
        const response = await api.delete(`/application/${application.clientId}/grant`);

        if (response.code !== 202 && response.code !== 304) {
          return _dispatchErrors(
            dispatch,
            {
              code: response.code,
              field: null,
              message: "We're sorry. There was a problem updating your account. Please try again later or call customer support.",
              rawMessage: response.content.message,
              toast: true
            }
          );
        }

        dispatch(setUser(response.content))
        return dispatch(setFlow({
          isApplicationRevoked: true,
          application: application
        }));
    }
    catch (e) {
      return _dispatchErrors(
        dispatch,
        {
          code: 500,
          field: null,
          message: "We're sorry. There was a problem updating your account. Please try again later or call customer support.",
          rawMessage: e,
          toast: true
        }
      );
    }
  },

  applicationToken: async (dispatch) => {
    const query = Util.queryToObject(window.location.search);
    const granted = await api.get(`/application/token${Util.toCoreQueryString(query)}`);
    let flow = {
      code: granted.code,
      isAuthInitiationRequest: true
    }

    switch (granted.code) {
        case 200:
          flow.isGranted = true;
          flow.grant = granted.content;
          break;
        case 401:
          flow.logout = true;
          break;
        case 403:
          flow.isApprovalNeeded = true;
          flow.application = await getApplication(dispatch, query.client_id);
          break;
        case 428:
          flow.reconcile = true;
          break;
        default:
          break;
    }

    dispatch(setFlow(flow));
  },

  createUser: async (dispatch, firstname, lastname, username, password) => {
    const payload = {
      firstName: firstname,
      lastName: lastname,
      username,
      password,
      twoFactor: {
        enabled: false
      }
    };

    const query = Util.queryToObject(window.location.search);
    const response = await api.post(`/user${query.client_id ? `?clientId=${query.client_id}` : ''}`, payload);

    if (response.code !== 200) {
      if (response.code === 412) {
        return _dispatchErrors(
          dispatch,
          {
            code: response.code,
            field: 'username',
            message: 'Error: Sorry, that email address is already registered.',
            rawMessage: response.content.message,
            toast: false
          }
        );
      }
      else if (response.code === 400) {
        if ((response.content.validation &&
             response.content.validation.keys.length &&
             response.content.validation.keys[0] === 'username') ||
             (response.content && response.content.message === 'The email address is not valid.')
           ) {
          return _dispatchErrors(
            dispatch,
            {
              code: response.code,
              field: 'username',
              message: 'Please enter a valid email address.',
              rawMessage: response.content.message,
              toast: false
            }
          );
        }

        if (response.content &&
            response.content.message === 'Password does not meet policy requirements. Password must be at least 8 characters long, contain no spaces, have at least one letter and one numeric character.') {
          return _dispatchErrors(
            dispatch,
            {
              code: response.code,
              field: 'password',
              message: 'Password does not meet policy requirements.',
              rawMessage: response.content.message,
              toast: false
            }
          );
        }

        return _dispatchErrors(
          dispatch,
          {
            code: response.code,
            field: 'username',
            message: 'Please double-check your email address for mistakes and try again.',
            rawMessage: response.content.message,
            toast: false
          }
        );
      }
      else {
        return _dispatchErrors(
          dispatch,
          {
            code: response.code,
            field: null,
            message: 'Sorry, an unknown error occurred. Please try again shortly or contact customer support for immediate assistance.',
            rawMessage: response.content.message,
            toast: true
          }
        );
      }
    }

    Local.userLogin(response.content.token, username);
    dispatch(setError({}));
    await userValidate(dispatch);

    return dispatch(setFlow({
      isCreateRedirect: true,
      redirect: `/${Util.toQueryString(query, ['reauthenticate'])}`
    }));
  },

  emailHasAccount: async (email) => {
    const response = await api.get(`/user/email?email=${email}`);

    if (response.code === 200) {
      return true;
    } else if (response.code === 404) {
      return false;
    }

    return null;
  },

  emailIsValidated: async (email) => {
    const response = await api.get(`/user/email/validation?email=${email}`);

    if (response.code === 200) {
      return response.content.validated;
    }

    return false;
  },

  getRedirectUrl: (grant) => {
    const query = Util.queryToObject(window.location.search);
    const parsed = decodeURIComponent(query.redirect_uri);
    const parts = Url.parse(parsed);
    const queryParameters = (parts.query ? parts.query.split('&') : []);

    if (!grant)
      return postMessage(null, query.state, null);

    queryParameters.push(`code=${grant.code}`, `state=${grant.state}`);

    let redirect = `${parts.protocol}//${parts.host}`;

    if (parts.pathname) redirect += `${parts.pathname}`;

    redirect += `?${queryParameters.join('&')}`;

    // if (this.state.postMessage || this.state.pixel) {
    //   return postMessage(grant.code, grant.state, redirect);
    // }

    return redirect
  },

  userChangePassword: async (dispatch, oldPassword, newPassword) => {
    try {
      const response = await api.put('/user', { password: { before: oldPassword, after: newPassword }});

      if (response) {
        if (response.code === 201) {
          dispatch(setAlert({
            message: 'Password updated successfully!',
            open: true,
            type: 'success'
          }))

          dispatch(setFlow({
            isPasswordUpdated: true
          }));

          return true;
        }

        if (response.code === 400) {
          let field = null;
          if (response.content.message === 'You cannot re-use your last four passwords. Please enter another password.') {
            field = 'previousPassword';
          }

          _dispatchErrors(
            dispatch,
            {
              code: response.code,
              field: field,
              message: response.content.message,
              rawMessage: response.content.message,
              toast: false
            }
          );

          return false;
        }

        if (response.code === 401) {
          _dispatchErrors(
            dispatch,
            {
              code: response.code,
              field: 'oldPassword',
              message: 'Your current password is incorrect. Please try entering it again.',
              rawMessage: response.content.message,
              toast: false
            }
          );

          return false;
        }
      }
    }
    catch (e) {
      _dispatchErrors(
        dispatch,
        {
          code: null,
          field: null,
          message: e,
          rawMessage: e,
          toast: true
        }
      );

      return false;
    }
  },

  userForgot: async (dispatch, username) => {
    try {
      const query = Util.queryToObject(window.location.search);
      const set = query.set ? '?set=true' : '';

      let payload = { username };

      if (query && Object.keys(query).length) {
        payload.parameters = Util.toCoreQueryObject(query) || {};
        payload.parameters.username = username;
      }

      const response = await api.post(`/user/authenticate/forgot${set}`, payload);

      if (response.code > 299) {
        if (response.code === 429) {
          return _dispatchErrors(
            dispatch,
            {
              code: response.code,
              field: null,
              message: `A ${set ? 'set password' : 'password reset'} email has recently been sent. Please wait a moment before trying to resend.`,
              rawMessage: response.content.message,
              toast: true
            }
          );
        }

        if (response.code === 400) {
          let message = 'Please double-check your email address for mistakes and try again.';

          if (response.content.validation && response.content.validation.keys.length && response.content.validation.keys[0] === 'username') {
            message = 'This doesn\'t look like a valid email address. Please check it and try again.';
          }

          return _dispatchErrors(
            dispatch,
            {
              code: response.code,
              field: 'username',
              message: message,
              rawMessage: response.content.message,
              toast: false
            }
          );
        }

        return _dispatchErrors(
          dispatch,
          {
            code: response.code,
            field: null,
            message: "We're sorry. Our servers are having trouble logging you in right now.",
            rawMessage: response.content.message,
            toast: true
          }
        );
      }

      return dispatch(setFlow({
        isResetSent: true,
        username: username,
      }));
    } catch (e) {
      return _dispatchErrors(
        dispatch,
        {
          code: null,
          field: null,
          message: e,
          rawMessage: e,
          toast: true
        }
      );
    }
  },

  userLogin: async (dispatch, username, password, code=null) => {
    if (window.globals && window.globals.gidRecaptchaKey) {
      window.grecaptcha.ready(function() {
        window.grecaptcha.execute(window.globals.gidRecaptchaKey, {action: 'submit'}).then(async function(token) {
          await _doLogin(dispatch, username, password, token, code);
        });
      });
    } else {
      await _doLogin(dispatch, username, password, undefined, code);
    }
  },

  userLogout: async (dispatch) => {
    dispatch(setSignedIn(false));
    dispatch(setHasValidated(true));
    dispatch(setUser(null));
    dispatch(setFlow({}));
    await Local.userLogout(Local.getCurrentUser().username);
  },

  userResetPassword: async (dispatch, password) => {
    const query = Util.queryToObject(window.location.search);

    try {
      const forgotPayload = {
        password
      };

      if (query.clientId || query.client_id) {
        forgotPayload.client_id = query.clientId || query.client_id;
      }

      if (query.username) {
        forgotPayload.username = query.username;
      }

      if (query.sgn) {
        forgotPayload.sgn = query.sgn;
      }

      const response = await api.post(`/user/authenticate/forgot/${query.request}`, forgotPayload);
      switch (response.code) {
        case 201:
          Local.userLogin(response.content.token);
          userValidate(dispatch);
          if (query.username) {
            return dispatch(setFlow({
              isResetRedirect: true,
              redirect: `/${Util.toQueryString(query, ['request', 'username', 'sgn'])}`
            }));
          }
          else {
            let queryString = Util.toQueryString(query, ['request']);

            if (query.redirect_uri) {
              window.location.href = decodeURIComponent(query.redirect_uri);
              return;
            }

            return dispatch(setFlow({
              isResetRedirect: true,
              redirect: `/${queryString}`
            }));
          }
        case 400:
          return _dispatchErrors(
            dispatch,
            {
              code: response.code,
              field: null,
              message: "We're sorry. Our servers are having trouble logging you in right now.  Please try again later",
              rawMessage: response.content.message,
              toast: true
            }
          );
        case 404:
          return _dispatchErrors(
            dispatch,
            {
              code: response.code,
              field: null,
              message: "We're sorry, this reset password link has either expired or is invalid.",
              rawMessage: response.content.message,
              toast: true
            }
          );
        case 409:
        case 412:
          var previous = false;
          if (response.content.message === 'Your new password cannot match any of your last 4 passwords. Please try again.') {
            previous = true;
          }
          return _dispatchErrors(
            dispatch,
            {
              code: response.code,
              field: 'password',
              message: response && response.content ? response.content.message || 'Your password could not be accepted. Please choose a password that meets the requirements above.' : 'Your password could not be accepted. Please choose a password that meets the requirements above.',
              previous: previous,
              rawMessage: response.content.message,
              toast: true
            }
          );
        default:
          return _dispatchErrors(
            dispatch,
            {
              code: response.code,
              field: null,
              message: "We're sorry, an unexpected error occurred.",
              rawMessage: response.content.message,
              toast: true,
            }
          );
      }
    }
    catch (e) {
      return _dispatchErrors(
        dispatch,
        {
          code: null,
          field: null,
          message: e,
          rawMessage: e,
          toast: true
        }
      );
    }
  },

  userRequestVerify: async (dispatch, username, validationRequest=false, ignoreQuery=false) => {
    try {
      const query = ignoreQuery ? {} : Util.queryToObject(window.location.search);

      delete query.email;

      let payload = { username };

      if (validationRequest) {
        payload.validationRequest = true;
      }

      if (query && Object.keys(query).length) {
        payload.parameters = Util.toCoreQueryObject(query) || {};
        payload.parameters.username = username;
      }

      const response = await api.post('/user/authenticate/verify/email', payload);

      if (response.code > 299) {
        if (response.code === 429) {
          _dispatchErrors(
            dispatch,
            {
              code: response.code,
              field: null,
              message: `A verify email has recently been sent. Please wait a moment before trying to resend.`,
              rawMessage: response.content.message,
              toast: true
            }
          );

          return response;
        }

        _dispatchErrors(
          dispatch,
          {
            code: response.code,
            field: null,
            message: "We're sorry. Our servers are having trouble logging you in right now.",
            rawMessage: response.content.message,
            toast: true
          }
        );

        return response
      }

      dispatch(setFlow({
        isVerifySent: true
      }));

      dispatch(setAlert({
        message: 'Verification email has been sent!',
        open: true,
        type: 'success'
      }));

      return response;
    } catch (e) {
      _dispatchErrors(
        dispatch,
        {
          code: null,
          field: null,
          message: e,
          rawMessage: e,
          toast: true
        }
      );
    }
  },

  userState: (state) => {
    let user = Object.assign({}, state.user, {
      hasValidated: state.hasValidated,
      isSignedIn: state.isSignedIn
    });

    return user;
  },

  userUpdateTwoFactor: async (dispatch, user, enabled, type, number, countryCode, areaCode) => {
    let twoFactor = {
      data: enabled ? {} : null,
      enabled: enabled,
      type: enabled ? type : null
    };

    if (twoFactor.type === 'phone') {
      twoFactor.data.phoneNumber = {};
      twoFactor.data.phoneNumber.areaCode = areaCode || '+1';
      twoFactor.data.phoneNumber.countryCode = countryCode || 'US';
      twoFactor.data.phoneNumber.number = number;
    }

    if (!twoFactorUpdated(user, twoFactor)) return;

    const response = await api.put('/user', { twoFactor: twoFactor });
    if (response.code !== 201) {
      if (response.code === 401) {
        return dispatch(setFlow({
          shouldLogout: true,
        }));
      }
      else if (response.code === 400) {
        return _dispatchErrors(
          dispatch,
          {
            code: response.code,
            field: null,
            message: response.content.message,
            rawMessage: response.content.message,
            toast: true,
          }
        );
      }
      else {
        return _dispatchErrors(
          dispatch,
          {
            code: response.code,
            field: null,
            message: 'Sorry, an unexpected problem occurred. Please try again later, or contact support for immediate assistance.',
            rawMessage: response.content.message,
            toast: true,
          }
        );
      }
    }

    user.twoFactor = twoFactor;

    dispatch(setUser(user));
    dispatch(setAlert({
      message: '2-Factor settings updated successfully!',
      open: true,
      type: 'success'
    }));
  },

  userValidate: userValidate,

  userVerifyEmail: async (dispatch) => {
    const query = Util.queryToObject(window.location.search);
    let response;

    try {
      let uri = `/user/authenticate/verify/email/${query.request}`;

      if (query.redirectUri) {
        uri += `?redirect_uri=${query.redirectUri}`;
      }

      response = await api.post(uri);
      if (response.code === 404) {
        return _dispatchErrors(
          dispatch,
          {
            code: response.code,
            field: 'global',
            message: "We're sorry, this verify link has either expired or is invalid. You can request a new verify link for your account preferences page.",
            rawMessage: response.content.message,
            toast: false,
          }
        );
      }
      if (response.code === 400) {
        return _dispatchErrors(
          dispatch,
          {
            code: response.code,
            field: 'global',
            message: "We're sorry, this verify link contains a different redirect location than expected.",
            rawMessage: response.content.message,
            toast: false,
          }
        );
      }
    }
    catch (e) {
      return _dispatchErrors(
        dispatch,
        {
          code: 500,
          field: 'global',
          message: "We're sorry, an unexpected error has occurred.",
          toast: false,
        }
      );
    }

    _dispatchErrors(
      dispatch,
      {
        code: null,
        field: null,
        toast: true
      }
    );
    dispatch(setFlow({
      isVerified: true,
      validationRequest: response.content ? response.content.validationRequest : undefined,
      email: response.content ? response.content.username : undefined
    }));
    dispatch(setAlert({
      message: 'Email verification successful.',
      open: true,
      type: 'success'
    }));
  },

  validateResetToken: async (dispatch) => {
    const query = Util.queryToObject(window.location.search);

    try {
      let response = await api.get(`/user/authenticate/forgot/${query.request}`);
      if (response.code === 404) {
        return _dispatchErrors(
          dispatch,
          {
            code: response.code,
            field: 'global',
            message: "We're sorry, this reset password link has either expired or is invalid.",
            rawMessage: response.content.message,
            toast: false,
          }
        );
      }
    }
    catch (e) {
      return _dispatchErrors(
        dispatch,
        {
          code: 500,
          field: 'global',
          message: "We're sorry, an unexpected error has occurred.",
          toast: false,
        }
      );
    }
  }
};
