import React from 'react';

import InvitationApp from './components/invitation-app';
import WithQuery from '../../../../common/components/with-query';

const Invitation = props => {
  return (
    <WithQuery query={props.location.search}>
      <InvitationApp />
    </WithQuery>
  );
}

export default Invitation;
