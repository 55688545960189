import React from 'react';

import Page from './components/page';
import WithQuery from '../../../../common/components/with-query';

const Index = props => {
  return (
    <WithQuery query={props.location.search}>
      <Page />
    </WithQuery>
  );
}

export default Index;
