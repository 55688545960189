import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Api from '../../../../../../common/api';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Local from '../../../../../../common/local';

const styles = theme => ({
    btn: {
      margin: '0 10px 10px auto',
    },
    checkboxCtrl: {
      marginBottom: '10px'
    }
});

class LogoutEverywhereWarning extends Component {

    constructor(props) {

        super(props);

        this.state = {
            revokeTokens: true
        };

        this.api = new Api();
        this.onRevokeTokensApprove = this.onRevokeTokensApprove.bind(this);
        this.onRevokeTokensDeny = this.onRevokeTokensDeny.bind(this);
    }

    async onRevokeTokensApprove() {
        let username, newToken;
        try {
            username = Local.getCurrentUser().username;
            this.setState({revokeTokens: true});
            newToken = await this.api.put('/user', { revokeTokens: this.state.revokeTokens });
            if (newToken.content.tokenResult) {
                Local.userLogin(newToken.content.tokenResult.token, username);
            }
        }
        catch (e) {
            console.log(e.toString());
            this.props.onRevokeTokensApprove();
            return (e.toString());

        }
        this.props.onRevokeTokensApprove();
    }

    onRevokeTokensDeny() {
        this.props.onRevokeTokensDeny();
    }

    render() {
      if (this.props.open) {
          return (
              <Dialog
                  disableEscapeKeyDown
                  fullWidth={true}
                  open={this.props.open}>
                      <DialogContent>
                          <DialogContentText>
                            Logging out of all devices will log you out of all applications using GolfID.
                          </DialogContentText>
                          <DialogContentText>
                            Are you sure you want to logout of all devices?
                          </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                            <Grid container spacing={0} justify="center">
                                <Grid item sm={4}>
                                    <Button className={this.props.classes.btn}
                                    color="primary"
                                    onClick={this.onRevokeTokensDeny}
                                    variant="contained">Remain Logged In</Button>
                                </Grid>
                                <Grid item sm={4}>
                                    <Button className={this.props.classes.btn}
                                    color="primary"
                                    onClick={this.onRevokeTokensApprove}
                                    variant="outlined">Logout</Button>
                                </Grid>
                            </Grid>
                      </DialogActions>
              </Dialog>
          );
      } else {
          return null;
      }

    }
}

export default withStyles(styles)(withMobileDialog()(LogoutEverywhereWarning));
