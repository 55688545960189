import React from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';

import {setError} from '../../../../../../../actions/auth';

import PageHeader from '../../../../../shared/components/page-header';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import PopAlert from "../../../../../shared/components/pop-alert";
import LeftRail from "../../../../../shared/components/left-rail";
import Typography from '@material-ui/core/Typography';
import Util from "../../../../../../../common/util";
import Verification from "../../../../../../../common/verification";

const styles = theme => ({
    view: {
      ...theme.internalContainer,
      [theme.breakpoints.up('md')]: {
        padding: theme.internalContainer._themeMdPadding
      },
    },
    buttonControls: {
      display: "flex",
      justifyContent: "center",
      marginTop: "35px"
    },
    card: {
      background: theme.palette.background.light,
    }
});

class SuccessView extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      pop: this.props.error.toast,
      redirectTo: null,
    };

    this.goToCheckout = this.goToCheckout.bind(this);
    this.onMessageClose = this.onMessageClose.bind(this);
  }

  onMessageClose() {
    this.props.dispatch(setError({}));
  }

  async goToCheckout(e) {

    const newQuery = Object.assign(this.props.query, {});
    delete newQuery.identity;
    const redirect = `/${Util.toQueryString(newQuery)}`;

    if (newQuery.client_id || !this.props.verificationId) {
      this.setState({
        redirectTo: redirect
      });
    }
    else {
      const info = await Verification.getVerificationInformation(this.props.dispatch, this.props.verificationId, 'military');

      if (!info || !info.queryParams) {
        return this.setState({
          redirectTo: redirect
        });
      }

      const retrievedQuery = Object.assign(info.queryParams, {});

      this.setState({
        redirectTo: `/${Util.toQueryString(retrievedQuery)}`
      });
    }
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect push to={this.state.redirectTo} />;
    }

    let view = (
        <Grid item xs={12} md={6}>
          <Card elevation={13} className={this.props.classes.card}>
            <CardContent>
              <Grid container direction="column" spacing={1}>

                <Grid item>
                  <PageHeader text='Verification Successful'/>
                </Grid>

                <Grid item>
                  <Typography>You have verified your military status, click to continue to your purchase.</Typography>
                </Grid>

                <Grid item>
                  <Button className={this.props.classes.buttonControls} color="primary" id="cancel" onClick={this.goToCheckout} variant="contained">
                    Back to GolfPass
                  </Button>
                </Grid>

                <Grid direction="row" container spacing={5}>
                  <Grid item xs={6}>
                  </Grid>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
      </Grid>
    );


    return (
          <Grid container spacing={0} className={this.props.classes.view}>
            <PopAlert open={this.props.error.toast} message={this.props.error.message} onClose={this.onMessageClose}  hasExit={true}/>
            <Grid item xs={12} md={6}>
              <LeftRail />
            </Grid>
              {view}
          </Grid>
    );

  }
}

const mapStateToProps = state => {
  return {
    alert: state.alert,
    error: state.error,
    flow: state.flow
  };
}

const ConnectedView = connect(mapStateToProps, null)(SuccessView);

export default withStyles(styles)(ConnectedView);
