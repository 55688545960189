import Local from './../local';

class Api {

    constructor() {
        this.uri = `http://localhost:18934/api/v1/sso/oauth`;

        if (window.globals) {
          this.uri = `${window.globals.gidApi}/api/v1/sso/oauth`
        }
    }

    __fetch(method, resource, payload) {

        let token = "";

        if (Local.getCurrentUser()) {
          token = Local.getCurrentUser().token;
        }

        const request = {
            method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        };

        if (payload) {
            request.body = typeof payload !== 'string' ? JSON.stringify(payload) : payload;
        }

        return new Promise((resolve, reject) => {

            fetch(`${this.uri}${resource}`, request)
            .then(async (response) => {
                let content;
                try {
                    content = await response.json();
                }
                catch (e) {
                    content = null;
                }

                return resolve({
                    code: response.status,
                    content
                });
            })
            .catch(async (err) => {
              this.fetchError(resource, err);

              return reject('We were unable to process your request, please try again in a few minutes.');
              // Failed to fetch
              // return reject(err);
            });
        });
    }

    get(url) {

        return this.__fetch('GET', url);
    }

    put(url, payload) {

        return this.__fetch('PUT', url, payload);
    }

    post(url, payload) {

        return this.__fetch('POST', url, payload);
    }

    delete(url, payload) {

        return this.__fetch('DELETE', url, payload);
    }

    fetchError(resource, error) {
      if (window.newrelic) {
        const prodApi = `https://api.golfid.io/health`;
        let healthRes;

        fetch(prodApi,  {method: 'GET'})
        .then(async (response) => {
          healthRes = response;
        }).catch((err) => {
        }).finally(() => {
          const hostname = window && window.location && window.location.hostname;
          let attr = {
            apiHealth: null,
            apiUri: this.uri,
            endPoint: resource,
            hostname: hostname,
          };

          if (healthRes)
            attr.apiHealth = healthRes.status;

          window.newrelic.noticeError(error, attr);
        });
      }
    }
}

export default Api;
