import React from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Auth from '../../../../../common/auth';
import Util from '../../../../../common/util';

import { setUser } from '../../../../../actions/auth';

import LeftRail from '../../../shared/components/left-rail';
import PageHeader from '../../../shared/components/page-header';
import PopAlert from '../../../shared/components/pop-alert';

const styles = (theme) => ({
  link: {
    cursor: "pointer"
  },
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  },
  pageContainer: theme.pageContainer,
});

class View extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      redirect: null
    };

    this.onCancel = this.onCancel.bind(this);
    this.onVaidatedEmail = this.onVaidatedEmail.bind(this);
    this.onVerified = this.onVerified.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  async componentWillMount() {
    Auth.userVerifyEmail(this.props.dispatch);
  }

  onCancel() {
    const newQuery = Object.assign(this.props.query, {});
    delete newQuery.request;
    delete newQuery.redirectUri;
    this.redirect(`/${Util.toQueryString(newQuery)}`);
  }

  onVaidatedEmail() {
    const newQuery = Object.assign(this.props.query, {email: this.props.flow.email});
    delete newQuery.request;
    this.redirect(`/new-account${Util.toQueryString(newQuery)}`);
  }

  onVerified() {
    if (this.props.query.redirectUri) {
      window.location = decodeURIComponent(this.props.query.redirectUri);
      return;
    }
    const newQuery = Object.assign(this.props.query, {});
    delete newQuery.request;
    delete newQuery.redirectUri;

    let user = Object.assign(this.props.user, { verified: true });
    this.props.dispatch(setUser(user));

    this.redirect(`/${Util.toQueryString(newQuery)}`);
  }

  redirect(redirect) {
    this.setState({ redirect });
  }

  render() {
    if (this.state.redirect !== null) {
      return (
        <Redirect to={this.state.redirect} />
      );
    }

    let view;
    let buttonText = this.props.query.redirectUri ? 'Proceed' : 'Return to Login';

    if (this.props.error.field === 'global') {
      buttonText = 'Return to Login';
      view = (
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PageHeader text={'Verifying Email'} />
              <Typography color="textSecondary" className={this.props.classes.text}>{this.props.error.message}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary" className={this.props.classes.text}>Please contact support for more information.</Typography>
            </Grid>
            <Grid item>
              <Button color="primary" onClick={this.onCancel} variant="contained">Return to Login</Button>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    if (this.props.flow.isVerified) {
      if (!this.props.flow.validationRequest) {
        view = (
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PageHeader text={'Verifying Email'} />
                <Typography color="textPrimary" className={this.props.classes.text}>Success! Your email address has been verified.</Typography>
              </Grid>
              <Grid item>
                <Button color="primary" onClick={this.onVerified} variant="contained">{buttonText}</Button>
              </Grid>
            </Grid>
          </Grid>
        );
      } else {
        view = (
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PageHeader text={'Verifying Email'} />
                <Typography color="textPrimary" className={this.props.classes.text}>Success! The email <Typography color="primary" display="inline">{this.props.flow.email}</Typography> has been validated for account creation.  Click below to create your account.</Typography>
              </Grid>
              <Grid item>
                <Button color="primary" onClick={this.onVaidatedEmail} variant="contained">Create Account</Button>
              </Grid>
            </Grid>
          </Grid>
        );
      }
    }

    return (
      <div className={this.props.classes.pageContainer}>
        <Grid container spacing={0} className={this.props.classes.view}>
          <PopAlert open={this.state.pop} message={this.state.error} onClose={this.onRequestClose} />
          <Grid item xs={12} md={6}>
            <LeftRail />
          </Grid>
          {view}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.error,
    flow: state.flow,
    user: Auth.userState(state)
  };
}

const ConnectedView = connect(mapStateToProps, null)(View);

export default withStyles(styles)(ConnectedView);
