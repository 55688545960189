import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Api from '../../../../../../common/api'
import Util from '../../../../../../common/util';

import Heading from '../../../../shared/components/heading';
import LeftRail from '../../../../shared/components/left-rail';
import StandardInput from '../../../../shared/components/standard-input';


const styles = (theme) => ({
    noHeaderContainer: {
      padding: '0px 20px 0 20px',
      maxWidth: '1200px',
      margin: '0 auto 50px auto'
    },
    text: {
      fontSize: '14px'
    },
    view: {
      ...theme.internalContainer,
      [theme.breakpoints.up('md')]: {
        padding: theme.internalContainer._themeMdPadding
      },
    }
});


class TwoFactorView extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            code: '',
            processing: false,
            error: null,
            validation: {
                code: null
            }
        };

        this.api = new Api();
        this.generateOnInput = this.generateOnInput.bind(this);
        this.onLogin = this.onLogin.bind(this);
        this.onError = this.onError.bind(this);
        this.onSubmitPressed = this.onSubmitPressed.bind(this);
        this.startLogin = this.startLogin.bind(this);
    }

    generateOnInput(stateKey) {

        return function (e) {

            this.setState({
                [stateKey]: e.target.value,
                validation: { code: null }
            });
        }.bind(this);
    }

    onError(errObj) {

        this.setState({ processing: false });
        this.props.onError(errObj);
    }

    async onSubmitPressed(e) {

        e.preventDefault();
        if (e.key === 'Enter') {
            return await this.startLogin();
        }
    }

    async startLogin() {
      if (window.globals && window.globals.gidRecaptchaKey) {
        const that = this;
        window.grecaptcha.ready(function() {
          window.grecaptcha.execute(window.globals.gidRecaptchaKey, {action: 'submit'}).then(async function(token) {
            await that.onLogin(token);
          });
        });
      } else {
        await this.onLogin();
      }
    }

    async onLogin(recaptcha) {

        const {
            username,
            password
        } = this.props;
        const { code } = this.state;

        const validation = {
            code: code === '' ? 'Error: Please enter your 6-digit code.' : false
        };

        if (code.length !== 6) {
            validation.code = 'Error: Your code must be six digits long.';
        }

        if (Object.values(validation).some((v) => { return v !== false; })) {
            return this.setState({ validation });
        }

        this.setState({ processing: true });

        try {
            const response = await this.api.post(
                                `/user/authenticate${this.props.clientId ? `?clientId=${this.props.clientId}` : ''}`,
                                {
                                    username,
                                    password,
                                    code,
                                    recaptcha,
                                    parameters: Util.toCoreQueryObject(this.props.query)
                                }
                            );

            if (response.code === 201) {
                return this.props.onLoginSuccess(response.content.token);
            }
            else if (response.code === 400) {
                return this.onError({
                    error: (
                        <div>{response.content.message}</div>
                    )
                });
            }
            if (response.code === 401) {
                return this.onError({
                    error: (
                        <div>
                            <div>We couldn't find a user with that address, or the password didn't match.</div>
                            <div>Please check your credentials and try again.</div>
                        </div>
                    )
                });
            }
            else if (response.code === 423) {
                return this.onError({
                    error: (
                        <div>
                            <div>
                                This account has been temporary locked for too many failed login attempts.
                            </div>
                            <div>
                                Please try again in a while, or contact customer support for immediate assistance.
                            </div>
                        </div>
                    )
                });
            }
            else if (response.code === 417){
                return this.onError({
                    error: (
                        <div>
                            <p>Your 2-FA code is either incorrect or expired.</p>
                        </div>
                    )
                });
            }
            else if (response.code === 426) {
                return this.props.onUpgradeAccount(username);
            }
            else if (response.code === 428) {
                return this.props.onRequiresReconciliation(username);
            }
        }
        catch (e) {
            return this.onError({ error: e.toString() });
        }
    }

    render() {

      const gridSize = (
        this.props.query.noHeader ?
        12 : 6
      );

      const containerStyle =
        this.props.query.noHeader ?
        this.props.classes.noHeaderContainer :
        this.props.classes.view;

      const leftPane = (
        this.props.query.noHeader ?
          null :
          <Grid item xs={12} md={6} className={this.props.classes.copycontainer} >
            <LeftRail clientId={this.props.clientId} />
          </Grid>
      );

        return (
            <Grid container spacing={0} className={containerStyle}>
                {leftPane}
                <Grid item xs={12} md={gridSize}>
                <Grid item xs={12} className="general-text text-center">
                    <Heading text="Two-Factor Authentication Required" />
                    <Typography color="textPrimary" >
                      <p className={this.props.classes.text}>We just sent you an email or text message with a 6-digit code. Please enter that code here to continue logging in.</p>
                    </Typography>
                </Grid>
                <Grid item xs={12} className={this.props.classes.textInputContainer}>
                    <StandardInput
                        pattern="\d+"
                        style={{ marginBottom: '2rem' }}
                        error={this.state.validation.code}
                        attribute="code"
                        label="Code"
                        focus={true}
                        onChange={this.generateOnInput('code')}
                        onKeyUp={this.onSubmitPressed} />
                </Grid>
                <Grid item xs={12}>
                    <Button color="primary" onClick={this.startLogin} variant="contained" id="login2FA">
                        {this.state.processing ?
                            (<CircularProgress color="inherit" size={14} />)
                            : 'Login'
                        }</Button>
                </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(TwoFactorView);
