import React from 'react';

import Api from '../../../../../../common/api';

import StandardInput from '../../../../shared/components/standard-input';
import Heading from '../../../../shared/components/heading';
import CountryCodeAdornment from '../../../../shared/components/country-code-adornment';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    text: {
        fontSize: '14px',
        marginBottom: '10px'
    },
    view: {
        marginTop: '40px'
    },
    tooltipHeading: {
        fontSize: '15px',
        fontWeight: 'bold'
    },
    tooltipText: {
        fontSize: '13px'
    },
    twoFactorBlock: {
        marginBottom: '20px'
    },
    buttonControls: {
      display: "flex",
      justifyContent: "center",
      marginTop: "10px"
    },
});


class TwoFactorUpdate extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            enabled: false,
            open: false,
            type: null,
            data: null,
            countryCode: 'US:+1',
            phoneNumber: '',
            validation: {
                phoneNumber: null
            }
        };

        this.api = new Api();

        this.generateOnInput = this.generateOnInput.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    generateOnInput(field) {

        return function (e) {

            return this.setState({
                [field]: e.target.value,
                validation: {
                    phoneNumber: null
                }
            });
        }
    }

    handleTooltipClose() {

        this.setState({ open: false });
    }

    handleTooltip() {

        const open = !this.state.open;
        this.setState({ open: open });
    }

    async onSubmit() {

        const validation = {
            phoneNumber: this.state.enabled && this.state.type === 'phone' ?
                         (
                             this.state.phoneNumber.length ? null : 'Error: Please enter a phone number.' : null
                         )
                         : null
        };

        if (Object.values(validation).some((v) => { return v !== null; })) {
            return this.setState({ validation });
        }

        return this.props.onSubmit();
    }

    render() {

        const {
            enabled,
            type,
            data
        } = this.props.twoFactor ? this.props.twoFactor :
        {
          enabled: false,
          type: null,
          data: null
        };
        let countryCode;

        if (data && data.phoneNumber && data.phoneNumber.countryCode && data.phoneNumber.areaCode) {
            countryCode = `${data.phoneNumber.countryCode}:${data.phoneNumber.areaCode}`;
        }
        else {
            countryCode = `US:+1`;
        }

        return (
            <Grid container spacing={0} className={this.props.classes.view}>
                <Grid item xs={12}>
                    <Heading text='Update Your Two-Factor Authentication Information'/>
                    <Typography color="textSecondary" className={this.props.classes.text}>Two-factor authentication keeps your account secure by sending you an authorization code before you log in.</Typography>
                </Grid>
                <Grid item xs={12} className={this.props.classes.twoFactorBlock}>
                    <FormControlLabel
                        control={<Checkbox checked={enabled} color="primary" onChange={this.props.onTwoFactorCheck} />}
                        label="Enable 2-FA"/>
                    <ClickAwayListener onClickAway={this.handleTooltipClose.bind(this)}>
                        <Tooltip
                          open={this.state.open}
                          placement="top"
                          title={
                            <div>
                                <p className={this.props.classes.tooltipHeading}>Two-Factor Authentication (2-FA)</p>
                                <p className={this.props.classes.tooltipText}>Enabling 2-FA enhances your account security by requiring you to enter a 6-digit code sent to your email or phone before logging in.</p>
                                <p className={this.props.classes.tooltipText}>Golf Account will not share your phone number with anyone, for any reason.</p>
                            </div>
                          }>
                            <InfoIcon onClick={this.handleTooltip.bind(this)} color="action" style={{cursor: 'pointer', position: 'relative', top: '4px', width: '16px', height: '16px'}} />
                        </Tooltip>
                    </ClickAwayListener>
                </Grid>
                <Grid item style={{ display: enabled ? 'block' : 'none' }}>
                    <RadioGroup
                        aria-label="two-factor-preference"
                        name="twoFactorPreference"
                        value={type}
                        onChange={this.props.onTwoFactorTypeChange}>
                        <FormControlLabel value="email" control={<Radio color="primary"/>} label="Email" />
                        <FormControlLabel value="phone" control={<Radio color="primary"/>} label="Phone" />
                      </RadioGroup>
                      <Grid item style={{ display: (enabled && type === 'phone' ? 'block' : 'none') }}>
                          <StandardInput
                              value={((data || {}).phoneNumber || {}).number}
                              style={{ marginBottom: '2rem' }}
                              error={this.state.validation.phoneNumber}
                              attribute="phonenumber"
                              label="Phone Number"
                              focus={true}
                              startAdornment={
                                  <CountryCodeAdornment
                                      onChange={this.props.onTwoFactorCountryCodeChange}
                                      value={countryCode} />
                              }
                              onChange={this.props.onTwoFactorPhoneKeyUp}
                          />
                      </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Button className={this.props.classes.buttonControls} color="primary" onClick={this.onSubmit} variant="contained">Update 2-FA</Button>
                </Grid>
            </Grid>

        );
    }
}

export default withStyles(styles)(TwoFactorUpdate);
