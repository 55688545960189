const tokenName = 'gnid.__atstk';

var storage = {};
var useCookie = false;
var localData = undefined;

const getCurrentUser = () => {
  if (!localData) return undefined;
  return localData.current;
};

const getAvailableUsers = () => {
  if (!localData) return undefined;

  return localData.ids;
}

const hasToken = () => {
  if (!localData) return false;
  if (!localData.current) return false;
  return localData.current.token ? true : false;
};

const isLoggedOutOf = (clientId) => {
  if (!localData) return false
  if (!localData.properties) return false;
  if (!localData.properties[clientId]) return false;

  return true;
};

const logoutAll = async () => {
  await _storageClear();
};

const propertyLogout = async (clientId) => {
  if (!localData.properties[clientId]) {
    localData.properties[clientId] = {};
  }

  localData.properties[clientId].loggedOut = true;
  localData.properties[clientId].date = Date.now();

  await _storageWrite(localData);
};

const readStorage = async () => {
  return await _storageRead();
}

const setCurrentUsername = async (username) => {
  if (!localData) return false;
  if (!localData.current) return false;

  localData.current.username = username;

  await _storageWrite(localData);

  return true;
}

const switchUser = async (username) => {
  if (!localData) return;
  if (!localData.ids) return;

  if (localData.current && localData.current.token) {
    localData.ids[localData.current.username] = localData.current;
  }

  if (!username) {
    localData.current = {};
    localData.properties = {};
  }

  const switchUser = localData.ids[username];

  if (!switchUser) return;

  localData.current = switchUser;
  localData.properties = {};

  await _storageWrite(localData);
};

const userLogin = async (token, username='') => {
  const cur = localData.current;

  if (!localData.current) {
    localData.current = {};
  }

  localData.current.token = token;
  localData.current.username = username;
  localData.properties = {};

  if (!localData.ids) {
    localData.ids = {};
  }

  localData.ids[username] = {
    username: username,
    token: token
  };

  if (cur) {
    localData.ids[cur.username] = cur;
  }

  await _storageWrite(localData);
};

const userLogout = async (username=null) => {

  if (username && localData.ids) {
    delete localData.ids[username];

    if (username === localData.current.username) {
        localData.current = {};
        localData.properties = {};
    }
  } else {
    localData.current = {};
    localData.properties = {};
  }

  await _storageWrite(localData);
};

const _getCookie = (cname) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const _storageClear = () => {
  try {
    if (useCookie)
    {
      let cookie = `${tokenName}=; Max-Age=-9999999; SameSite=Lax;`;
      if (window.globals && window.globals.gidDomain) {
        console.log(`Domain set: ${window.globals.gidDomain}`);
        if (document.location.host.indexOf(window.globals.gidDomain) !== -1) {
          console.log(`Setting cookie with domain`);
          cookie = `${cookie} domain=${window.globals.gidDomain}`;
        }
      }
      document.cookie = cookie;
      return;
    }
    localStorage.removeItem(tokenName);
  } catch (e) {
    return storage[tokenName] = undefined;
  }
};

const _storageRead = async () => {
  let data;
  try {
    if (useCookie) {
      data = _getCookie(tokenName);
    } else {
      data = localStorage.getItem(tokenName);
    }

    if (!data) {
      localData = {};
    }
    else {
      localData = JSON.parse(data);
    }
  } catch (e) {
    localData = {};
  }
};

const _storageWrite = async (local) => {
  const data = JSON.stringify(local);
  try {
    if (useCookie) {
      var expires = new Date();
      expires.setFullYear(expires.getFullYear() + 1);
      let cookie = `${tokenName}=${data}; expires=${expires.toUTCString()}; SameSite=Lax;`
      if (window.globals && window.globals.gidDomain) {
        if (document.location.host.indexOf(window.globals.gidDomain) !== -1) {
          cookie = `${cookie} domain=${window.globals.gidDomain}`;
        }
      }
      document.cookie = cookie;
      return;
    }

    localStorage.setItem(tokenName, data);
  } catch (e) {
    return storage[tokenName] = data;
  }
};

export default {
  getCurrentUser: getCurrentUser,
  getAvailableUsers: getAvailableUsers,
  hasToken: hasToken,
  isLoggedOutOf: isLoggedOutOf,
  logoutAll: logoutAll,
  propertyLogout: propertyLogout,
  readStorage: readStorage,
  setCurrentUsername: setCurrentUsername,
  switchUser: switchUser,
  userLogin: userLogin,
  userLogout: userLogout
};
