import React from 'react';

import CancelApp from './components/cancel-app';
import WithQuery from '../../../../common/components/with-query';

const Cancel = props => {
  return (
    <WithQuery query={props.location.search}>
      <CancelApp />
    </WithQuery>
  );
}

export default Cancel;
