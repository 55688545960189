import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import Auth from '../../../../../../common/auth';
import Util from '../../../../../../common/util';

import { setError } from '../../../../../../actions/auth';

import Heading from '../../../../shared/components/heading';
import StandardInput from '../../../../shared/components/standard-input';

const styles = (theme) => ({
  buttonControls: {
    paddingTop: '10px'
  },
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  }
});

class View extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      username: '',
      validation: {
        username: null
      }
    };

    this.generateOnInput = this.generateOnInput.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitPressed = this.onSubmitPressed.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  generateOnInput(stateKey) {

    return function (e) {

      this.setState({
        [stateKey]: e.target.value,
        validation: { username: null }
      });

      if (this.props.error.field) {
        this.props.dispatch(setError({}));
      }
    }.bind(this);
  }

  onCancel() {
    this.redirect(`/${Util.toQueryString(this.props.query)}`);
  }

  async onSubmitPressed(e) {

    if (e.key === 'Enter') {
      return await this.onSubmit();
    }
  }

  async onSubmit() {
    const {
      username
    } = this.state;

    if (this.props.error.field) {
      this.props.dispatch(setError({}));
    }

    const validation = {
      username: username === '' ? 'Please enter an email address.' : null,
    };

    if (Object.values(validation).some((v) => { return v !== null; })) {
      return this.setState({ validation });
    }

    Auth.userForgot(this.props.dispatch, username);
  }

  redirect(redirect) {
    this.setState({ redirect });
  }

  render() {
    let usernameErr = this.state.validation.username || null;

    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    if (this.props.error) {
      if (this.props.error.field === 'username') {
        usernameErr = this.props.error.message;
      }
    }

    return (
      <Card elevation={3} className={this.props.classes.view}>
        <CardContent>
          <Grid container spacing={0}>
            <Grid item>
              <Heading text={this.props.query.set ? 'Set Password' : 'Forgot Password'} />
            </Grid>
            <Grid item xs={12}>
              <p>We will send you an email with a link to {this.props.query.set ? '' : 're'}set your password. Your {this.props.query.set ? 'set password' : 'reset'} link will expire in 24 hours.</p>
              <StandardInput
                inputType="email"
                style={{ marginBottom: '10px', width: '100%' }}
                error={usernameErr}
                attribute="username"
                label="EMAIL ADDRESS"
                labelWidth={116}
                focus={true}
                value={this.props.query.email}
                onChange={this.generateOnInput('username')}
                onKeyUp={this.onSubmitPressed} />
              <Grid className={this.props.classes.buttonControls} container spacing={2}>
                <Grid item>
                  <Button className={this.props.classes.topButton} color="primary" onClick={this.onSubmit} variant="contained">Send</Button>
                </Grid>
                <Grid item>
                  <Button className={this.props.classes.button} color="secondary" onClick={this.onCancel} variant="contained">Cancel</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.error
  };
}

const ConnectedView = connect(mapStateToProps)(View);

export default withStyles(styles)(ConnectedView);
