import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import Auth from '../../../../../common/auth';

import { setFlow } from '../../../../../actions/flow';

import ApprovedView from './views/approved-applications';
import PasswordChangeView from './views/password-change';
import TwoFactorView from './views/two-factor';
import UserInfo from './views/user-info';

const styles = theme => ({
  pageContainer: theme.pageContainer,
  innerContainer: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '1030px'
    }
  },
  innerContainerCener: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    },
  }
});

class Page extends Component {
  componentWillUnmount() {
    this.props.dispatch(setFlow({}));
  }

  render() {
    if (this.props.user.hasValidated && !this.props.user.isSignedIn) {
      return <Redirect to={`/?logged_out=true`} />
    }

    if (!this.props.user.hasValidated) {
      // Showing Waiting thing, then fade
      return <div>Loading</div>
    }

    return (
      <div>
        <Grid container justify="center" spacing={2} className={this.props.classes.pageContainer}>
          <Grid item xs={12} className={this.props.classes.innerContainer}>
            <Grid container spacing={3} className={this.props.classes.innerContainerCener}>
              <Grid item>
                <UserInfo query={this.props.query}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={2}>
              <Grid item>
                <PasswordChangeView query={this.props.query}/>
              </Grid>
              <Grid item>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <TwoFactorView query={this.props.query}/>
                  </Grid>
                  <Grid item>
                    <ApprovedView query={this.props.query}/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    flow: state.flow,
    user: Auth.userState(state)
  };
}

const ConnectedPage = connect(mapStateToProps, null)(Page);

export default withStyles(styles)(ConnectedPage);
