import React from 'react';
import Api from '../../../../../common/api';
import Heading from '../../../shared/components/heading';
import LeftRail from '../../../shared/components/left-rail';
import Util from '../../../../../common/util';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import { Redirect, Link } from 'react-router-dom';

const styles = theme => ({
    pageContainer: theme.pageContainer,
    text: {
        color: '#666',
        fontSize: '14px'
    },
    view: {
      ...theme.internalContainer,
      [theme.breakpoints.up('md')]: {
        padding: theme.internalContainer._themeMdPadding
      },
    }
});

class VerifyApp extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            redirect: null,
            error: null
        };

        this.api = new Api();
    }

    async componentWillMount() {

        const generateError = (message) => {

            return (
                <div>
                    <div className="row">
                        <div className="col-12">
                            {message}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                             Please contact support for more information.
                        </div>
                    </div>
                </div>
            );
        };

        try {
            const response = await this.api.post('/user/authenticate/verify', { verificationCode: this.props.verificationCode });
            switch (response.code) {
                case 201:
                case 304:
                    const query = Object.assign({}, this.props.query, { verified: 'true' });
                    this.setState({
                        redirect: '/' + Util.toQueryString(query)
                    });
                    break;
                case 401:
                    this.setState({
                        error: generateError('We\'re sorry, this verification code couldn\'t be found or has expired.')
                    });
                    break;
                case 404:
                    this.setState({
                        error: generateError('We\'re sorry, we couldn\'t find your account.')
                    });
                    break;
                default:
                    this.setState({
                        error: generateError('We\'re sorry, an unexpected error occurred.')
                    });
                    break;
            }
        }
        catch (e) {
            console.error(e);
            return this.setState({
                error: generateError('We\'re sorry, an unexpected error occurred.')
            });
        }
    }

    render() {

        if (this.state.redirect !== null) {
            return (
                <Redirect to={this.state.redirect} />
            );
        }

        let view;

        if (this.state.error) {
            view = (
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        {this.state.error}
                    </Grid>
                    <Grid item xs={12}>
                        <p className={this.props.classes.text}>You can <strong><Link to="/">click here</Link></strong> to return to the Golf Account home page.</p>
                    </Grid>
                </Grid>
            );
        }
        else {
            view = (
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <p className={this.props.classes.text}>One moment please...</p>
                    </Grid>
                </Grid>
            );
        }

        return (
          <div className={this.props.classes.pageContainer}>
            <Grid container spacing={0} className={this.props.classes.view} justify="center">
              <Grid item xs={12} md={6}>
                <LeftRail />
              </Grid>
              <Grid item xs={12} md={6}>
                  <Heading text="Verify your Account"/>
                  {view}
              </Grid>
            </Grid>
          </div>
        );
    }
}

export default withStyles(styles)(VerifyApp);
