import React from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router';

import Api from '../../../../../../common/api'
import Auth from '../../../../../../common/auth';
import Util from '../../../../../../common/util';
import Recaptcha from '../../../../../../common/components/recaptcha';

import Heading from '../../../../shared/components/heading';
import StandardInput from '../../../../shared/components/standard-input';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const styles = (theme) => ({
  forgotPassword: {
    marginTop: '8px'
  },
  // heading: {
  //   marginBottom: '20px'
  // },
  link: {
    cursor: 'pointer'
  },
  loginControls: {
    width: '100%'
  },
  recaptchaLinks: {
    color: '#fdba11'
  },
  recaptchaText: {
    fontSize: '12px'
  },
  // subHeading: {
  //   marginBottom: '10px'
  // },
  view: {
    ...theme.internalContainer,
  }
});

class View extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      client: null,
      username: '',
      password: '',
      processing: false,
      error: this.props.loggedOut === 'true' ?
      'You have been logged out. Please re-authenticate to continue.'
      : null,
      placeholders: {
        username: 'Email Address',
        password: 'Password'
      },
      redirect: null,
      showPassword: false,
      validation: {
        username: null,
        password: null
      }
    };

    this.api = new Api();
    this.continueAsLogin = this.continueAsLogin.bind(this);
    this.generateOnInput = this.generateOnInput.bind(this);
    this.onCreateAccount = this.onCreateAccount.bind(this);
    this.onError = this.onError.bind(this);
    this.onForgotPassword = this.onForgotPassword.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.onShowPassword = this.onShowPassword.bind(this);
    this.onSubmitPressed = this.onSubmitPressed.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  generateOnInput(stateKey) {

    return function (e) {

      this.setState({
        [stateKey]: e.target.value,
        validation: { username: null, password: null }
      });
    }.bind(this);
  }

  onError(errObj) {

    this.setState({ processing: false });
    this.props.onError(errObj);
  }

  async onSubmitPressed(e) {

    if (e.key === 'Enter') {
      return await this.onLogin();
    }
  }

  onCreateAccount() {
    this.redirect(`/new-account${Util.toQueryString(this.props.query)}`);
  }

  onForgotPassword() {
    this.redirect(`/forgot${Util.toQueryString(this.props.query)}`);
  }

  async onLogin() {

    const {
      username,
      password
    } = this.state;

    const validation = {
      username: username === '' ? 'Please enter an email address.' : null,
      password: password === '' ? 'Please enter a password.' : null
    };

    if (Object.values(validation).some((v) => { return v !== null; })) {
      return this.setState({ validation });
    }

    Auth.userLogin(this.props.dispatch, username, password);
  }

  onShowPassword() {
    this.setState({showPassword: !this.state.showPassword});
  }

  continueAsLogin() {
    const newQuery = Object.assign(this.props.query, {});
    delete newQuery.reauthenticate;
    window.location.href = `/${Util.toQueryString(newQuery)}`;
  }

  async componentWillMount() {

    if (this.props.query.aslogin) {
      return this.setState({
        username: this.props.query.aslogin
      });
    }
  }

  redirect(redirect) {
    this.setState({ redirect });
  }

  render() {
    let usernameErr = this.state.validation.username || null;
    let passwordErr = this.state.validation.password || null;

    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    if (this.props.flow.isReauth) {
      const newQuery = Object.assign(this.props.query, {});
      delete newQuery.reauthenticate;
      return <Redirect push to={`/${Util.toQueryString(newQuery)}`} />;
    }

    if (this.props.error) {
      if (this.props.error.field === 'username') {
        usernameErr = this.props.error.message;
      } else if (this.props.error.field === 'password') {
        passwordErr = this.props.error.message;
      }
    }

    const switchAccount =
      this.props.user.isSignedIn && this.props.query.reauthenticate ?
      (
        <Grid item>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography color="textSecondary" className={this.props.classes.subHeading}>Currently logged in as:</Typography>
              <Typography color="textPrimary" className={this.props.classes.username}><strong>{this.props.user.username}</strong></Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button color="primary" onClick={this.continueAsLogin} variant="contained">
                Use This Account
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )
    :
    null;

    const switchAccountSubHeader =
      this.props.user.isSignedIn && this.props.query.reauthenticate ?
      (
        <Grid item>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography color="textSecondary" className={this.props.classes.subHeading}>- or -</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography color="textPrimary" className={this.props.classes.subHeading}>Login to Another Account</Typography>
            </Grid>
          </Grid>
        </Grid>
      )
    :
    null;

    const heading = this.props.user.isSignedIn && this.props.query.reauthenticate ?
      'Switch Accounts' : 'Sign In with NBC Sports ID'

    const endAdornment = this.state.showPassword ?
      <VisibilityOffIcon style={{'cursor': 'pointer'}} onClick={this.onShowPassword} /> :
      <VisibilityIcon style={{'cursor': 'pointer'}} onClick={this.onShowPassword} />;

    const recaptcha = (window.globals && window.globals.gidRecaptchaKey && !this.props.query.noRc) ?
      (<Grid item xs={12}>
        <Recaptcha textClassname={this.props.classes.recaptchaText} linkClassname={this.props.classes.recaptchaLinks} />
      </Grid>) : null;

    return (
      <Card elevation={3} className={this.props.classes.view}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item className={this.props.classes.heading}>
              <Heading text={heading}/>
            </Grid>
            {switchAccount}
            {switchAccountSubHeader}
            <Grid item xs={12}>
              <StandardInput
                inputType="email"
                value={this.state.username}
                disabled={!!this.props.query.aslogin}
                style={{ width: '100%' }}
                error={usernameErr}
                attribute="username"
                label="EMAIL ADDRESS"
                labelWidth={116}
                focus={true}
                onChange={this.generateOnInput('username')}
                onKeyUp={this.onSubmitPressed} />
            </Grid>
            <Grid item xs={12}>
              <StandardInput
                inputType={this.state.showPassword ? 'text' : 'password'}
                style={{ width: '100%' }}
                error={passwordErr}
                password={true}
                attribute="password"
                label="PASSWORD"
                labelWidth={80}
                endAdornment={endAdornment}
                onChange={this.generateOnInput('password')}
                onKeyUp={this.onSubmitPressed} />
            </Grid>
            {recaptcha}
            <Grid item  className={this.props.classes.loginControls}>
              <Grid container justify="space-between">
                <Grid item>
                  <Button color="primary" onClick={this.onLogin} variant="contained" id="login">
                    {this.state.processing ?
                    (<CircularProgress size={14} color="inherit" />)
                    : 'Login'
                    }
                  </Button>
                </Grid>
                <Grid item className={this.props.classes.forgotPassword}>
                  <Link color="textSecondary" className={this.props.classes.link} onClick={this.onForgotPassword} attribute="forgotPassword">
                    FORGOT PASSWORD?
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button color="secondary" onClick={this.onCreateAccount} variant="contained" id="createAccount">
                Create Account
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.error,
    flow: state.flow,
    user: Auth.userState(state)
  };
}

const ConnectedView = connect(mapStateToProps, null)(View);

export default withStyles(styles)(ConnectedView);
