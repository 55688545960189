import React from 'react';
import ScriptLoader from 'react-script-loader-hoc';
import { connect } from 'react-redux'

import Api from '../../../../../../../common/api'

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PopAlert from "../../../../../shared/components/pop-alert";
import LeftRail from "../../../../../shared/components/left-rail";

import PageHeader from '../../../../../shared/components/page-header';

const styles = (theme) => ({
    view: {
      ...theme.internalContainer,
      [theme.breakpoints.up('md')]: {
        padding: theme.internalContainer._themeMdPadding
      },
    },
    text: {
        color: '#666',
        fontSize: '14px'
    },
    card: {
        background: theme.palette.background.light,
    }
});

class DocUploadView extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            file1: null,
            processing: false,
            error: null,
            validation: {
                file1: null
            },
        };

        this.api = new Api();
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitPressed = this.onSubmitPressed.bind(this);
    }

    async onSubmitPressed(e) {
        e.preventDefault();
        if (e.key === 'Enter') {
            return await this.onSubmit();
        }
    }


    componentDidMount() {
        this.addStyle("https://cdn.jsdelivr.net/npm/@sheerid/jslib@1.59.0/sheerid.css");
    }

    addStyle = url => {
        const style = document.createElement("link");
        style.href = url;
        style.rel = "stylesheet";
        style.async = true;
        document.head.appendChild(style);
    };

    async onSubmit() {
        console.log("TODO on Submit")
    }

    componentDidUpdate(nextProps) {
        const { scriptsLoadedSuccessfully } = this.props
        if (nextProps.scriptsLoadedSuccessfully !== scriptsLoadedSuccessfully) {
            if (scriptsLoadedSuccessfully) {
                let sheerId = window.sheerId;
                sheerId.setOptions({
                    // logLevel: 'info',
                    verificationId:this.props.verificationId,
                    // mockStep:'error'
                });
                let myProgramId = this.props.programId;
                let myDiv = document.getElementById('sheerIdForm');
                // eslint-disable-next-line
                let myForm = new sheerId.VerificationForm(myDiv, myProgramId);

                sheerId.addHook({
                    name: 'ON_VERIFICATION_STEP_CHANGE',
                    callback: async (verificationResponse) => {
                        // if the sheerId form is ever attempting to go in collect info state, set an
                        // internal _forceRestart status
                        if (verificationResponse.currentStep === 'collectTeacherPersonalInfo') {
                            this.props.onVerificationId(null)
                            this.props.onUserStatus('_forceRestart');
                        } else if (verificationResponse.currentStep === 'success') {
                            this.props.onUserStatus('success');
                        }
                    }
                });
            }
        }
    }

    render() {

        let view = (
          <Grid item xs={12} md={6}>
            <Grid item xs={12}>
              <PageHeader text='Verify your Teacher Status'/>
            </Grid>
              <div id="sheerIdForm"></div>
          </Grid>
        );

        return (
              <Grid container spacing={0} className={this.props.classes.view} justify="center">
                  <PopAlert open={this.props.error.toast} message={this.props.error.message} onClose={this.onMessageClose}  hasExit={true}/>

                  <Grid item xs={12} md={6}>
                      <LeftRail />
                  </Grid>
                  {view}
              </Grid>
        );
    }
}

const mapStateToProps = state => {
    return {
        error: state.error
    };
}

const ConnectedView = connect(mapStateToProps, null)(
  ScriptLoader('https://cdn.jsdelivr.net/npm/@sheerid/jslib@1.59.0/metrics.js',
               'https://cdn.jsdelivr.net/npm/@sheerid/jslib@1.59.0/sheerid.js')(DocUploadView) );

export default withStyles(styles)(ConnectedView);
