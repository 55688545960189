import React from 'react';

import { withStyles } from '@material-ui/core';
import DotIcon from '@material-ui/icons/Remove';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Clear';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  container: {
    // paddingBottom: '20px'
  },
  dotIcon: {
    height: '16px',
    width: '16px'
  },
  list: {
    paddingBottom: '14px',
    paddingTop: '14px',
  },
  listIcon: {
    marginRight: '10px',
  },
  listItem: {
    paddingBottom: '0px',
    paddingTop: '0px',
  },
  listText: {
    paddingLeft: '0px',
    fontSize: '14px'
  },
  ruleFailed: {
    color: '#f44336 !important',
  },
  rulePassed: {
    color: '#009900 !important',
  },
  statusIcon: {
    height: '16px',
    width: '16px'
  },
  text: {

  }
});

class ResetPasswordRules extends React.Component {

  constructor(props) {
    super(props);
    this.generateRuleSet = this.generateRuleSet.bind(this);
    this.getList = this.getList.bind(this);
    this.getListItem = this.getListItem.bind(this);
  }

  /**
   * Rule Set:
   * Have at least 8 characters.
   * Must not contain any spaces.
   * Must contain one letter and one number.
   * Must not include any of the following characters: +@#$%^&*()\/&lt;&gt;'.
   * Passwords must match.
   * Password must not match previous 4 passwords.
   */
  generateRuleSet(password, repeat) {
    const invalidChars = "+@#$%^&*()/<>'";
    const invalidCharsRegex = /[+@#$%^&*()/<>']/;
    var set = [
      {
        text: `Have at least 10 characters.`,
        passed: null
      },
      {
        text: `Must not contain any spaces.`,
        passed: null
      },
      {
        text: `Must contain one letter and one number.`,
        passed: null
      },
      {
        text: `Passwords must match.`,
        passed: null
      }
    ];

    if (this.props.previous == true) {
      set.push({
        text: `Password must not match previous 4 passwords.`,
        passed: null
      });

      if (this.props.previous)
        set[set.length - 1].passed = false;
    }

    if (!password || password.length <= 0)
      return set;

    let rule = 0;
    set[rule].passed = false;
    if (password.length >= 10)
      set[rule].passed = true;

    rule++;
    set[rule].passed = false;
    if (password.search(/\s/) === -1)
      set[rule].passed = true;

    rule++;
    set[rule].passed = false;
    if ((password.search(/[a-zA-Z]/) !== -1) &&
        (password.search(/[0-9]/) !== -1))
      set[rule].passed = true;

    rule++;
    set[rule].passed = false;
    if (password === repeat)
      set[rule].passed = true;

    return set;
  }

  getList() {
    const ruleSet = this.generateRuleSet(
      this.props.password,
      this.props.passwordRepeat
    );
    const that = this;

    return (
      <List className={this.props.classes.list}>
        {
          ruleSet.map((value, index) =>
            that.getListItem(index, value.text, value.passed),
          )
        }
      </List>
    );
  }

  getListItem(key, text, passed) {
    const textClass = this.props.textClass ? this.props.textClass : this.props.classes.text;
    var cssClass = passed ? this.props.classes.rulePassed : this.props.classes.ruleFailed;
    var icon = passed ?
      <CheckIcon className={this.props.classes.statusIcon + ' ' + cssClass} /> :
      <ErrorIcon className={this.props.classes.statusIcon + ' ' + cssClass} />;

    if (passed === null) {
      cssClass = textClass;
      icon = <DotIcon className={this.props.classes.dotIcon + ' ' + cssClass} />;
    }

    return (
      <ListItem key={key} className={this.props.classes.listItem}>
        <ListItemIcon className={this.props.classes.listIcon}>
          {icon}
        </ListItemIcon>
        <ListItemText
          className={this.props.classes.listText}
          primary={text}
          primaryTypographyProps={{color: "textSecondary", className: this.props.classes.listText + ' ' + cssClass + ' ' + this.props.textClass}}
        />
      </ListItem>
    );
  }

  render() {
    const list = this.getList();
    const textClass = this.props.textClass ? this.props.textClass : this.props.classes.text;
    return (
      <Grid className={this.props.classes.container} item xs={12}>
        <Typography color="textPrimary" className={textClass}>
          Password requirements:
        </Typography>
        {list}
      </Grid>
    );
  }
}

export default withStyles(styles)(ResetPasswordRules);
