import React from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router';

import Auth from '../../../../../../common/auth';

import RedirectView from '../../../../../../common/views/redirect-view';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  button: {
    padding: "15px 20px",
  },
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  }
});

class View extends React.Component {

  constructor(props) {

    super(props);

    this.state = {};

    this.onApprove = this.onApprove.bind(this);
    this.onApproved = this.onApproved.bind(this);
  }

  async componentDidMount() {
    Auth.applicationToken(this.props.dispatch);
  }

  async onApprove() {
    await Auth.applicationGrant(this.props.dispatch);
    Auth.applicationToken(this.props.dispatch);
  }

  onApproved() {
    const redirect = Auth.getRedirectUrl(this.props.flow.grant);
    return <RedirectView redirectTo={redirect} />;
  }

  redirect(redirect) {
    this.setState({ redirect });
  }

  render() {
    let view = null;

    if (this.props.flow.isApprovalNeeded) {
      const application = this.props.flow.application;
      const displayables = application.displayable ? application.displayable : {};
      const termsUrl = displayables.termsAndConditionsUrl ? displayables.termsAndConditionsUrl : null;
      const privacyUrl = displayables.privacyPolicyUrl ? displayables.privacyPolicyUrl : null;
      let applicationName = application.applicationName

      if (displayables.content && displayables.content.title)
        applicationName = displayables.content.title;

      let policyLinks = null;

      if (termsUrl !== null && privacyUrl !== null) {
        policyLinks = <Grid item xs={12}><span className={this.props.classes.dialogBodyText + ' ' + this.props.classes.centerText}>I agree to the <a href={termsUrl}>Terms of Use</a> and <a href={privacyUrl}>Privacy Policy</a></span></Grid>;
      }

      view = (
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <Typography>Authorize <strong>{application.applicationName}</strong>?</Typography>
          </Grid>
          <Grid item>
            <span className={this.props.classes.dialogBodyText}>The application <strong>{applicationName}</strong> by <strong>{application.organizationName}</strong> would like to access your account information.</span>
            <span className={this.props.classes.dialogBodyText}>By authorizing this application you are agreeing to its terms, conditions and privacy policy.</span>
            <span className={this.props.classes.dialogBodyText}>You can always manage access to your data on your <Link className={this.props.classes.dialogLink} to="/preferences">Preferences</Link> page.</span>
          </Grid>
          {policyLinks}
          <Grid item xs={6}>
            <Button color="primary" onClick={this.onDeny} variant="contained">Decline</Button>
          </Grid>
          <Grid item xs={6}>
            <Button color="primary" onClick={this.onApprove} variant="outlined">Approve</Button>
          </Grid>
        </Grid>
      );
    } else if (this.props.flow.logout) {
      Auth.userLogout();
      return <Redirect push to={`/`} />;
    } else if (this.props.flow.isGranted) {
      view = this.onApproved();
    }

    return (
      <Card elevation={3} className={this.props.classes.view}>
        <CardContent>
          {view}
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    flow: state.flow
  };
}

const ConnectedView = connect(mapStateToProps, null)(View);

export default withStyles(styles)(ConnectedView);
