import React from 'react';
import { Redirect } from 'react-router';

import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";

import LockOpen from '@material-ui/icons/LockOpen';


const styles = theme => ({
  identityName: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  view: {
    marginTop: '40px'
  },
  text: {
    fontSize: '14px'
  },
  textApproved: {
    fontSize: '14px'
  },
  status: {
    color:'#083900'
  },
  verifyButton: {
    display: "inline",
    fontSize: "14px",
    justifyContent: "center",
    marginTop: "3px",
    marginLeft: "10px",
    backgroundColor: "#0076BB",
    width: "120px"
  },
  verifiedIcon: {
    paddingTop: '3px',
    color: '#009900 !important',
  }
});

class IdentityStatus extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
    };
    this.onVerifyClick = this.onVerifyClick.bind(this);
    this.redirect = this.redirect.bind(this);

  }

  onVerifyClick(e) {
    e.preventDefault();
    this.props.onClickScreen(this.props.type)
  }

  redirect(redirect) {
    this.setState({ redirect });
  }

  render() {

    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />
    }

    const id4type = this.props.identities[this.props.type];
    let view;
    let date = (
      <Typography variant="inherit" color="textSecondary">
        Not Verified
      </Typography>
    );
    let button = (
      <Button className={this.props.classes.verifyButton}
              color="secondary"
              onClick={this.onVerifyClick}
              variant="contained"
              size="small">
        Verify
      </Button>
    );
    let check = null;

    if (id4type) {
      const asDate = new Date(id4type.date);
      const asPrintableDate = id4type.status === 'success' ? `${asDate.getMonth() + 1}-${asDate.getDate()}-${asDate.getFullYear()}` : id4type.status;
      date = (
        <Typography variant="inherit" color="textSecondary">
          Date Verified: <Typography variant="inherit" color="textPrimary"> {asPrintableDate}</Typography>
        </Typography>
      );

      if (id4type.status === 'success') {
        check = <Grid item><CheckCircleIcon className={this.props.classes.verifiedIcon}/></Grid>;

        if (!id4type.canReverify) {
          button = null;
        }
      }
    }

    view = (
      <Grid container spacing={0}>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item>
              <Typography color="textPrimary" className={this.props.classes.identityName}>{this.props.label}</Typography>
            </Grid>
            <Grid item>
              {check}
            </Grid>
          </Grid>
          <Typography variant="inherit" color="textSecondary">
            {date}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          {button}
        </Grid>
      </Grid>
    );

    return view
  }
}

export default withStyles(styles)(IdentityStatus);
