import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
  logo: {
    width: '400px',
    paddingTop: '50px'
  }
});

class Logo extends Component {

  render() {
    const publicDir = window.globals ? window.globals.gidPublic : '';
    return (
      <Grid container justify="center">
        <Grid item>
          <img className={this.props.classes.logo} src={`${publicDir}/img/nbcsports/nbcsports.svg`} alt='NBC Sports'/>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Logo);
