
export default {
  MuiOutlinedInput: {
    input: {
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 100px #252b33 inset',
        WebkitTextFillColor: 'rgba(255, 255, 255, 0.87)',
      },
    },
  },
};
