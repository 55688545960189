import React from 'react';
import { connect } from 'react-redux'

import Auth from '../../../../../../common/auth';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Heading from '../../../../shared/components/heading';
import StandardInput from '../../../../shared/components/standard-input';

const styles = (theme) => ({
  text: {
    fontSize: '14px'
  },
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  }
});

class View extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      code: '',
      validation: {
        code: null
      }
    };

    this.generateOnInput = this.generateOnInput.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.onSubmitPressed = this.onSubmitPressed.bind(this);
  }

  generateOnInput(stateKey) {
    return function (e) {
      this.setState({
        [stateKey]: e.target.value,
        validation: { code: null }
      });
    }.bind(this);
  }

  async onLogin() {

    const {
      username,
      password
    } = this.props.flow;

    const { code } = this.state;

    const validation = {
      code: code === '' ? 'Error: Please enter your 6-digit code.' : false
    };

    if (code.length !== 6) {
      validation.code = 'Error: Your code must be six digits long.';
    }

    if (Object.values(validation).some((v) => { return v !== false; })) {
      return this.setState({ validation });
    }

    Auth.userLogin(this.props.dispatch, username, password, code);
  }

  async onSubmitPressed(e) {
    e.preventDefault();
    if (e.key === 'Enter') {
      return await this.onLogin();
    }
  }

  redirect(redirect) {
    this.setState({ redirect });
  }

  render() {
    let codeErr = this.state.validation.code || null;
    if (this.props.error) {
      codeErr = this.props.error.message;
    }

    return (
      <Card elevation={3} className={this.props.classes.view}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item>
              <Heading text='Two-Factor Code Required'/>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography color="textPrimary" component="div">
                <p className={this.props.classes.text}>We just sent you an email or text message with a 6-digit code. Please enter that code here to continue logging in.</p>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <StandardInput
                  pattern="\d+"
                  style={{ width: "100%" }}
                  error={codeErr}
                  attribute="code"
                  label="Code"
                  labelWidth={40}
                  focus={true}
                  onChange={this.generateOnInput('code')}
                  onKeyUp={this.onSubmitPressed} />
            </Grid>
            <Grid item xs={12}>
              <Button color="primary" onClick={this.onLogin} variant="contained" id="login2FA">
                  {this.state.processing ?
                      (<CircularProgress color="inherit" size={14} />)
                      : 'Login'
                  }</Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.error,
    flow: state.flow
  };
}

const ConnectedView = connect(mapStateToProps, null)(View);

export default withStyles(styles)(ConnectedView);
