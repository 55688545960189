import overrides from './overrides';

export default {
  overrides,
  palette: {
    common: {
      black: '#000',
      white: 'rgba(255, 255, 255, 1)'
    },
    background: {
      default: '#181b1f',
      dark: '#181b1f',
      light: '#181b1f',
      paper: '#252b33'
    },
    primary: {
      light: '#fdba11',
      main: '#fdba11',
      dark: '#fdba11',
      contrastText: '#111'
    },
    secondary: {
      light: '#181b1f',
      main: '#181b1f',
      dark: '#181b1f',
      contrastText: '#ddd'
    },
    error: {
      light: '#f44336',
      main: '#f44336',
      dark: '#f44336',
      contrastText: '#fff'
    },
    text: {
      primary: "rgba(255, 255, 255, 0.87)",
      secondary: "rgba(240, 240, 240, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(200, 200, 200, 0.38)"
    },
    type: 'light'
  },
  // shadows: [
  //   'none',
  //   'none',
  //   'none',
  //   'none',
  //   'none',
  //   'none',
  //   'none',
  //   'none',
  //   'none',
  //   'none',
  //   'none',
  //   'none',
  //   'none',
  //   'none',
  //   'none',
  //   'none',
  //   'none',
  //   'none',
  //   'none',
  //   'none',
  //   'none',
  //   'none',
  //   'none',
  //   'none',
  //   'none',
  // ],
  typography: {
    fontFamily: '"Karla", sans-serif',
    fontSize: 14,
    useNextVariants: true,
  },
  pageContainer: {
    paddingTop: '30px',
  },
  internalContainer: {
    maxWidth: '500px',
    minWidth: '400px'
  },
  body: {
    height: "100%"
  }
}
