import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  heading: {
    fontSize: '32px',
    fontWeight: 'normal',
  }
});

class Heading extends React.Component {
  render() {
    return (
      <Typography component="h2" color="textPrimary" className={this.props.classes.heading}>{this.props.text}</Typography>
    );
  }
}


export default withStyles(styles)(Heading);
