import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  pageContainer: theme.pageContainer,
});
class Loading extends Component {

  render() {

    return (
      <Grid container direction="column" className={this.props.classes.pageContainer} spacing={0} alignContent="center">
        <CircularProgress />
      </Grid>
    );
  }
}

const ConnectedLoading = connect()(Loading);

export default withStyles(styles)(ConnectedLoading);
