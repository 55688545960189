import React from 'react';

import NewAccountApp from './components/new-account-app';
import WithQuery from '../../../../common/components/with-query';

const NewAccount = props => {
  return (
    <WithQuery query={props.location.search}>
      <NewAccountApp />
    </WithQuery>
  );
}

export default NewAccount;
