import React from 'react';
import { Redirect } from 'react-router';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import PeopleIcon from '@material-ui/icons/People';
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';

import Api from '../../../../../../common/api'
import Local from '../../../../../../common/local'
import Util from '../../../../../../common/util';
import Recaptcha from '../../../../../../common/components/recaptcha';

import CurrentUser from '../../../../shared/components/current-user';
import Heading from '../../../../shared/components/heading';
import LeftRail from '../../../../shared/components/left-rail';
import PageHeader from '../../../../shared/components/page-header';
import StandardInput from '../../../../shared/components/gn-standard-input';

const styles = (theme) => ({
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  },
  copyContainer: {
    padding: "0 0 0 0",
    [theme.breakpoints.up('md')]: {
      padding: "0 25px 0 0",
    },
  },
  forgotContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px"
  },
  buttonControls: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px"
  },
  button: {
    borderRadius: '10px',
    fontWeight: '600',
    fontSize: '16px',
    height: '45px',
    width: '100%',
  },
  continueButton: {
    width: '100%'
  },
  profileButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  orText: {
    textAlign: 'center',
    width: '100%'
  },
  ctaBlock: {
    paddingTop: '40px'
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: '13px',
    margin: "10px 0",
  },
  createAccount: {
    textAlign: 'right',
  },
  loginButton: {
  },
  gnLoginButton: {
    marginBottom: '10px',
    borderRadius: '4px',
    backgroundColor: '#1aaf4b !important',
    '&:hover': {
      backgroundColor: '#1aaf4b !important',
      color: '#FFF'
    },
  },
  noHeaderContainer: {
    padding: '20px 20px 0px 20px',
    maxWidth: '1200px',
    margin: '0 auto 50px auto'
  },
  recaptchaText: {
    fontSize: '12px'
  },
  textChangeUser: {
    fontSize: '16px',
    cursor: 'pointer',
  },
});

class AuthenticationView extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      client: null,
      username: '',
      password: '',
      processing: false,
      error: this.props.loggedOut === 'true' ?
      'You have been logged out. Please re-authenticate to continue.'
      : null,
      placeholders: {
        username: 'Email Address',
        password: 'Password'
      },
      redirect: null,
      showPassword: false,
      validation: {
        username: null,
        password: null
      }
    };

    this.api = new Api();
    this.continueAsLogin = this.continueAsLogin.bind(this);
    this.generateOnInput = this.generateOnInput.bind(this);
    this.onError = this.onError.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.onShowPassword = this.onShowPassword.bind(this);
    this.onSubmitPressed = this.onSubmitPressed.bind(this);
    this.startLogin = this.startLogin.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  generateOnInput(stateKey) {

    return function (e) {

      this.setState({
        [stateKey]: e.target.value,
        validation: { username: null, password: null }
      });
    }.bind(this);
  }

  onError(errObj) {

    this.setState({ processing: false });
    this.props.onError(errObj);
  }

  async onSubmitPressed(e) {

    if (e.key === 'Enter') {
      return await this.startLogin();
    }
  }

  async startLogin() {
    if (window.globals && window.globals.gidRecaptchaKey) {
      const that = this;
      window.grecaptcha.ready(function() {
        window.grecaptcha.execute(window.globals.gidRecaptchaKey, {action: 'submit'}).then(async function(token) {
          await that.onLogin(token);
        });
      });
    } else {
      await this.onLogin();
    }
  }

  async onLogin(recaptcha) {

    const {
      username,
      password
    } = this.state;

    const validation = {
      username: username === '' ? 'Please enter an email address.' : null,
      password: password === '' ? 'Please enter a password.' : null
    };

    if (Object.values(validation).some((v) => { return v !== null; })) {
      return this.setState({ validation });
    }

    this.setState({ processing: true });
    const that = this;

    try {
      const response = await this.api.post(
        `/user/authenticate${this.props.clientId ? `?clientId=${this.props.clientId}` : ''}`,
        {
          username,
          password,
          recaptcha,
          parameters: Util.toCoreQueryObject(this.props.query)
        }
      );

      if (!response.code) {
        return this.onError({
          error: (
            <div>
              <div>
                We're sorry. Our servers are having trouble logging you in right now.
              </div>
              <div>
                Please try again later.
              </div>
            </div>
          ), critical: true
        });
      }

      if (response.code === 400) {
        return this.onError({
          error: function() {
            if (response.content.validation && response.content.validation.keys.length && response.content.validation.keys[0] === 'username') {
              validation.username = 'Please enter a valid email address.';
              that.setState({ validation });
              return 'This doesn\'t look like a valid email address. Please check it and try again.';
            }
            return undefined;
          }() || 'Please double-check your email address for mistakes and try again.'
        });
      }

      if (response.code === 401) {
        return this.onError({
          error: (
            <div>
              <div>We couldn't find a user with that address, or the password didn't match.</div>
              <div>Please check your credentials and try again.</div>
            </div>
          )
        });
      }
      else if (response.code === 422) {
        return this.props.onRequireResetPassword(username);
      }
      else if (response.code === 423) {
        return this.onError({
          error: (
            <div>
              <div>
                This account has been temporary locked for too many failed login attempts.
              </div>
              <div>
                Please try again in a while, or contact customer support for immediate assistance.
              </div>
            </div>
          )
        });
      }
      else if (response.code === 417) {
        return this.props.onTwoFactor(username, password);
      }
      else if (response.code === 426) {
        return this.props.onUpgradeAccount(username);
      }
      else if (response.code === 428) {
        return this.props.onRequiresReconciliation(username);
      }

      if (!response.content || !response.content.token) {
        return this.onError({
          error: (
            <div>
              <div>
                We're sorry. Our servers are having trouble logging you in right now.
              </div>
              <div>
                Please try again later.
              </div>
            </div>
          ), critical: true
        });
      }

      Local.userLogin(response.content.token, username);
      return this.props.onLoginSuccess();
    }
    catch (e) {
      return this.onError({ error: e.toString() });
    }
  }

  onShowPassword() {
    this.setState({showPassword: !this.state.showPassword});
  }

  continueAsLogin() {
    const newQuery = Object.assign(this.props.query, {});
    delete newQuery.reauthenticate;
    window.location.href = `/${Util.toQueryString(newQuery)}`;
  }

  async componentWillMount() {

    if (this.props.query.aslogin) {
      return this.setState({
        username: this.props.query.aslogin
      });
    }
  }

  redirect(redirect) {
    this.setState({ redirect });
  }

  render() {
    const gridSize = (
      this.props.query.noHeader ?
      12 : 6
    );

    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    const buttonStyle = !this.props.query.buttonColor ? null :
        {
          backgroundColor: `#${this.props.query.buttonColor}`
        };

    const bottomControls = (
      <Grid className={this.props.classes.buttonControls} item xs={12} spacing={0}>
        <Button style={buttonStyle} className={this.props.classes.button} color="primary" onClick={this.startLogin} variant="contained" id="login">
          {this.state.processing ?
            (<CircularProgress size={14} color="inherit" />)
            : 'Login'
          }
        </Button>
      </Grid>
    );

    const containerStyle =
      this.props.query.noHeader ?
      this.props.classes.noHeaderContainer :
      this.props.classes.view;

    const endAdornment = this.state.showPassword ?
      <VisibilityOffIcon color="action" style={{'cursor': 'pointer'}} onClick={this.onShowPassword} /> :
      <VisibilityIcon color="action" style={{'cursor': 'pointer'}} onClick={this.onShowPassword} />;

    const endAdornmentWrapper = (
      <InputAdornment position="end">
        <IconButton
            aria-label="toggle password visibility"
            onClick={this.onShowPassword}
            edge="end"
          >
            {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
      </InputAdornment>
    )

    const recaptcha = (window.globals && window.globals.gidRecaptchaKey && !this.props.query.noForgot) ?
      <Recaptcha textClassname={this.props.classes.recaptchaText}/> : null;

    const inputStyle = {
      borderStyle: 'ridge',
    };

    const emailInput = (
      <Grid item xs={12} md={gridSize}>
        <StandardInput
          inputType="email"
          value={this.state.username}
          disabled={!!this.props.query.aslogin}
          style={{ marginBottom: '15px' }}
          error={this.state.validation.username}
          attribute="username"
          label="Email Address"
          labelWidth={85}
          focus={true}
          onChange={this.generateOnInput('username')}
          onKeyUp={this.onSubmitPressed} />
      </Grid>
    );

    const passwordInput = (
      <Grid item xs={12} md={gridSize}>
        <StandardInput
          inputType={this.state.showPassword ? 'text' : 'password'}
          style={{ marginBottom: '15px' }}
          error={this.state.validation.password}
          password={true}
          attribute="password"
          label="Password"
          labelWidth={58}
          endAdornment={endAdornment}
          onChange={this.generateOnInput('password')}
          onKeyUp={this.onSubmitPressed} />
      </Grid>
    );

    return (
      <Grid container spacing={0} className={containerStyle} >
        {emailInput}
        {passwordInput}
        {bottomControls}
      </Grid>
    );
  }
}

export default withStyles(styles)(AuthenticationView);
