import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

const propertiesLocation = 'https://static.golfchannel.com/global-nav/global-nav.v2.json';
const styles = (theme) => ({
  logo: {
    margin: '50px auto 30px auto',
    maxWidth: '1000px',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  properties: {
    margin: '10px 0',
  },
  subLogos: {
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  leftColumn: {
    float: 'left'
  },
  rightColumn: {
    float: 'right'
  },
  subLogosImg: {
    margin: '0 auto',
    display: 'block'
  },
  logoGolfPass: {
    height: '11px'
  }
});

class PropertyLogosMobile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      properties: undefined
    }

    this.fallbackProperties = this.fallbackProperties.bind(this);
    this.getProperties = this.getProperties.bind(this);
  }

  async componentWillMount() {

    const request = {
      method: 'GET'
    };

    let propertiesUrl = window.globals && window.globals.gidPropertiesUrl ? window.globals.gidPropertiesUrl : propertiesLocation;

    let res = await fetch(propertiesUrl, request);
    const properties = await res.json();
    const localeProperties = properties.mainnav.filter((property) => {
      if (!property.locale || (property.locale && property.locale.includes('en_US')))
      return true;

      return false
    });

    this.setState({properties: localeProperties});
  }

  fallbackProperties() {
    return (
      <Grid className={this.props.classes.logo} container spacing={0} justifyContent="center" >
        <Grid className={this.props.classes.properties +' '+ this.props.classes.subLogos} container spacing={0} justifyContent="center">
          <Grid item xs={5}>
          <span className={this.props.classes.subLogosImg + ' ' + this.props.classes.leftColumn}>GOLF</span>
          </Grid>
          <Grid item xs={5}>
            <span className={this.props.classes.subLogosImg + ' ' + this.props.classes.rightColumn}>GOLFNOW</span>
          </Grid>
        </Grid>
        <Grid className={this.props.classes.properties +' '+ this.props.classes.subLogos} container spacing={0} justifyContent="center">
          <Grid item xs={5}>
            <span className={this.props.classes.subLogosImg + ' ' + this.props.classes.leftColumn}>REVOLUTION GOLF</span>
          </Grid>
          <Grid item xs={5}>
            <span className={this.props.classes.subLogosImg + ' ' + this.props.classes.rightColumn}>GOLF ADVISOR</span>
          </Grid>
        </Grid>
        <Grid className={this.props.classes.properties +' '+ this.props.classes.subLogos} container spacing={0} justifyContent="center">
          <Grid item xs={5}>
            <span className={this.props.classes.subLogosImg + ' ' + this.props.classes.leftColumn}>GOLF ACADEMY</span>
          </Grid>
          <Grid item xs={5}>
            <span className={this.props.classes.subLogosImg + ' ' + this.props.classes.rightColumn}>GOLF AM TOUR</span>
          </Grid>
        </Grid>
        <Grid className={this.props.classes.properties +' '+ this.props.classes.subLogos} container spacing={0} justifyContent="center">
          <Grid item xs={5}>
            <span className={this.props.classes.subLogosImg + ' ' + this.props.classes.leftColumn}>GOLFPASS</span>
          </Grid>
          <Grid item xs={5}>
            <span className={this.props.classes.subLogosImg + ' ' + this.props.classes.rightColumn}></span>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  getProperties() {
    if (!this.state.properties) return this.fallbackProperties();

    let properties = [];

    for (var i = 0; i < this.state.properties.length; i += 2) {
      const leftProperty = this.state.properties[i].name.toUpperCase();
      const rightProperty = (i + 1) < this.state.properties.length ? this.state.properties[i + 1].name.toUpperCase() : '';

      properties.push((
        <Grid key={`prop-grid-${i}-index`} className={this.props.classes.properties +' '+ this.props.classes.subLogos} container spacing={0} justifyContent="center">
          <Grid item xs={5}>
            <span className={this.props.classes.subLogosImg + ' ' + this.props.classes.leftColumn}>{leftProperty}</span>
          </Grid>
          <Grid item xs={5}>
            <span className={this.props.classes.subLogosImg + ' ' + this.props.classes.rightColumn}>{rightProperty}</span>
          </Grid>
        </Grid>
      ));
    }

    return (
      <Grid className={this.props.classes.properties +' '+ this.props.classes.subLogos} container spacing={0} justifyContent="center">
        {properties}
      </Grid>
    );
  }

  render() {
    const properties = this.getProperties();

    return (
      <Grid className={this.props.classes.logo} container spacing={0} justifyContent="center" >
        {properties}
      </Grid>
    );
  }
}

export default withStyles(styles)(PropertyLogosMobile);
