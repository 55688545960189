import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Redirect } from 'react-router-dom';

const styles = theme => ({

});

class CancelApp extends React.Component {
  render() {
    return (
      <Redirect to="/" />
    );
  }
}

export default withStyles(styles)(CancelApp);
