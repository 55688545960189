import Url from 'url';
import React, { Component } from 'react';
import { connect } from 'react-redux'

import { setReturnTo } from '../../../../../actions/return';

import { withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';

import Api from '../../../../../common/api'
import Local from '../../../../../common/local'
import Util from '../../../../../common/util';

import PopAlert from '../../../shared/components/pop-alert';

import AuthenticationView from './views/authentication-view';
import RedirectView from './views/redirect-view';
import ReconcileView from './views/reconcile-view';
import UpgradeView from './views/upgrade-view';
import AuthInitiationRequestView from './views/auth-initiation-request-view';
import TwoFactorView from './views/two-factor-view';

import StepCreateAccount from './views/step-create-account';
import StepEmailEntry from './views/step-email-entry';
import StepForgotPassword from './views/step-forgot-password';
import StepRedirect from './views/step-redirect';
import StepSignIn from './views/step-sign-in';
import StepTwoFactor from './views/step-two-factor';
import StepValidateEmail from './views/step-validate-email';

const styles = theme => ({
    pageContainer: theme.pageContainer
});

const STEPS = {
  CHECK_EMAIL: 1,
  CREATE_ACCOUNT: 2,
  SIGN_IN: 3,
  TWO_FACTOR: 4,
  AUTHORIZE: 5,
  REDIRECT: 6,
  VALIDATE_EMAIL: 7,
  FORGOT_PASSWORD: 8,
};

class HomeApp extends Component {

    constructor(props) {

        super(props);

        this.state = {
            redirect: null,
            error: function () {
                if (this.props.query.logged_out === 'true') {
                    return 'You have been logged out. Please re-authenticate to continue.'
                }
                if (this.props.query.pass_update === 'true') {
                    return 'Your password reset was successful! Login now!';
                }
                if (this.props.query.verified === 'true') {
                    return 'Your account was succesfully merged! Login to proceed.';
                }
                return null;
            }.bind(this)(),
            authorized: Local.hasToken(),
            critical: false,
            denied: false,
            errorOpen: function () {
              return this.props.query && (this.props.query.logged_out === 'true' || this.props.query.pass_update === 'true' || this.props.query.verified === 'true');
            }.bind(this)(),
            identity: this.props.query.identity || null,
            initiateForgot: false,
            initiateForgotUsername: '',
            isAuthInitiationRequest: Util.isAuthInitiationRequest(this.props.query),
            logout: this.props.query.logged_out === 'true',
            optIn: false,
            optInCopy: '',
            optInSelected: false,
            pixel: this.props.query.pixel === 'true',
            postMessage: this.props.query.postMessage === 'true',
            reauthenticate: this.props.query.reauthenticate === 'true' && Local.hasToken(),
            reconcile: false,
            reconcileUsername: '',
            showGuestLink: false,
            step: STEPS.CHECK_EMAIL,
            stopInitiate: false,
            twoFactor: false,
            twoFactorUsername: '',
            twoFactorPassword: '',
            upgrade: false,
            upgradeUsername: '',
            user: null,
            username: null,
        };

        this.api = new Api();
        this.goAuthorize = this.goAuthorize.bind(this);
        this.goCreateAccount = this.goCreateAccount.bind(this);
        this.goForgotPassword = this.goForgotPassword.bind(this);
        this.goGuest = this.goGuest.bind(this);
        this.goSignIn = this.goSignIn.bind(this);
        this.goTwoFactor = this.goTwoFactor.bind(this);
        this.goValidateEmail = this.goValidateEmail.bind(this);
        this.handlePostMessage = this.handlePostMessage.bind(this);
        this.onLoginSuccess = this.onLoginSuccess.bind(this);
        this.onLogout = this.onLogout.bind(this);
        this.onApprove = this.onApprove.bind(this);
        this.onDeny = this.onDeny.bind(this);
        this.onError = this.onError.bind(this);
        this.onErrorClose = this.onErrorClose.bind(this);
        this.onOptIn = this.onOptIn.bind(this);
        this.onOauthLoggedOut = this.onOauthLoggedOut.bind(this);
        this.onRequiresReconciliation = this.onRequiresReconciliation.bind(this);
        this.onUpgradeAccount = this.onUpgradeAccount.bind(this);
        this.onShowAvailableUsers = this.onShowAvailableUsers.bind(this);
        this.onTwoFactor = this.onTwoFactor.bind(this);
        this.onTwoFactorSuccess = this.onTwoFactorSuccess.bind(this);
        this.onRequireResetPassword = this.onRequireResetPassword.bind(this);
        this.onVerificationSuccess = this.onVerificationSuccess.bind(this);
        this.postMessage = this.postMessage.bind(this);
        this.postMessageRaw = this.postMessageRaw.bind(this);
        this.onEmailAccountCheck = this.onEmailAccountCheck.bind(this);
    }

    goAuthorize() {
      this.setState({ step: STEPS.AUTHORIZE });
    }

    goGuest(username) {
      this.postMessageRaw({step: 'guest', email: username});
    }

    goCreateAccount(username='') {
      this.postMessageRaw({step: 'createAccount'});
      this.setState({ step: STEPS.CREATE_ACCOUNT, username: username });
    }

    goForgotPassword(username='') {
      if (!this.props.query.noForgot) {
        this.setState({ step: STEPS.FORGOT_PASSWORD, username: username });
      }
      this.postMessageRaw({step: 'forgotPassword'});
    }

    goSignIn() {
      this.postMessageRaw({step: 'signIn'});
      this.setState({ step: STEPS.SIGN_IN, username: '' });
    }

    goTwoFactor() {
      this.postMessageRaw({step: 'twoFactor'});
      this.setState({ step: STEPS.TWO_FACTOR});
    }

    goValidateEmail(username='') {
      // this.postMessageRaw({step: 'validateEmail'});
      this.setState({ step: STEPS.VALIDATE_EMAIL, username: username});
    }

    handlePostMessage(e) {
      if (e.data === 'showGuestLink') {
        this.setState({showGuestLink: true});
      } else if (e.data.step) {
        if (e.data.step === 'emailOptIn') {
          this.setState({optIn: true, optInCopy: e.data.copy});
        }
      }
    }

    onEmailAccountCheck(username, exists) {
      const step = exists
        ? STEPS.SIGN_IN
        : STEPS.CREATE_ACCOUNT;
      const stepReadable = exists
        ? 'signIn'
        : 'createAccount';

      this.postMessageRaw({step: stepReadable});
      this.setState({username, step});
    }

    onLoginSuccess(token, username='') {

        if (token) {
            Local.userLogin(token, username);
        }

        if (!Local.hasToken()) {
            return this.setState({
                logout: false,
                twoFactor: false,
                error: (
                    <div>
                        <p>We're sorry, our servers are not available right now.</p>
                        <p>Please try logging in again shortly.</p>
                    </div>
                )
            })
        }

        return this.setState({
            changingUser: false,
            logout: false,
            twoFactor: false,
            authorized: true,
            reauthenticate: false,
            step: STEPS.AUTHORIZE
        });
    }

    onLogout() {

        return this.setState({ authorized: Local.hasToken() });
    }

    onApprove(grant) {
        const parsed = decodeURIComponent(this.props.query.redirect_uri);
        const parts = Url.parse(parsed);
        const queryParameters = (parts.query ? parts.query.split('&') : []);

        if (!grant)
          return this.postMessage(null, this.props.query.state, null);

        queryParameters.push(`code=${grant.code}`, `state=${grant.state}`);

        let redirect = `${parts.protocol}//${parts.host}`;

        if (parts.pathname) redirect += `${parts.pathname}`;

        redirect += `?${queryParameters.join('&')}`;

        if (this.state.postMessage || this.state.pixel) {
          this.setState({ step: STEPS.REDIRECT, redirect: null });
          this.postMessageRaw({event: 'login', success: true});
          return this.postMessage(grant.code, grant.state, redirect);
        }

        return this.setState({ step: STEPS.REDIRECT, redirect: redirect });
    }

    onDeny(applicationName) {

        return this.setState({
            denied: true,
            errorOpen: true,
            error: `You have denied "${applicationName}" access to your account. You can always try logging in again from the site that brought you here.`
        });
    }

    onOauthLoggedOut() {

        if (Local.getCurrentUser()) {
          Local.userLogout(Local.getCurrentUser().username);
        }

        if (this.state.pixel) {
            return this.postMessage(null, this.props.query.state, null);
        }

        return this.setState({
            logout: true,
            authorized: false,
            step: STEPS.CHECK_EMAIL
        });
    }

    onError(e) {

        return this.setState({ errorOpen: true, error: e.error, critical: e.critical || false });
    }

    onErrorClose() {

        return this.setState({ errorOpen: false });
    }

    onOptIn(e) {

      this.postMessageRaw({optIn: e.currentTarget.checked});
      this.setState({optInSelected: e.currentTarget.checked});
    }

    onRequiresReconciliation(username) {

        return this.setState({
            authorized: false,
            stopInitiate: true,
            reconcile: true,
            twoFactor: false,
            reconcileUsername: username
        });
    }

    onUpgradeAccount(username) {

        this.setState({ upgrade: true, twoFactor: false, upgradeUsername: username });
    }

    onShowAvailableUsers() {

        this.setState({changingUser: true});
    }

    onTwoFactor(username, password) {
        this.setState({
          twoFactor: true,
          twoFactorUsername: username,
          twoFactorPassword: password
        });
        this.goTwoFactor();
    }

    onTwoFactorSuccess(token, username='') {

        Local.userLogin(token, username);

        return this.setState({
            step: STEPS.AUTHORIZE,
            twoFactor: false,
            authorized: !!token
        });
    }

    onRequireResetPassword(initiateForgotUsername) {

        return this.setState({
            initiateForgot: true,
            initiateForgotUsername
        });
    }

    onVerificationSuccess() {

        return this.setState({
            authorized: true,
            stopInitiate: false,
            reconcile: false
        });
    }

    postMessage(code, state, uri) {
        let callTo = window.parent;
        if (window.opener) callTo = window.opener;

        return callTo.postMessage({
          code: code,
          state: state,
          uri: uri
        },  '*');
    }


    postMessageRaw(data) {
        let callTo = window.parent;
        if (window.opener) callTo = window.opener;

        return callTo.postMessage(data, '*');
    }

    wrapWithAlert(comp) {

        return (
            <Grid container spacing={0} justifyContent="center">
                <Snackbar
                    open={this.state.errorOpen}
                    autoHideDuration={5000}
                    message={this.state.error}
                    onClose={this.onErrorClose}
                    action={[
                        <IconButton
                          key="close"
                          aria-label="Close"
                          color="inherit"
                          onClick={this.onErrorClose}
                        >
                          <CloseIcon />
                        </IconButton>
                    ]} />
                {comp}
            </Grid>
        );
    }

    async componentDidMount() {
        window.addEventListener(
          'message',
          this.handlePostMessage,
          false,
        );

        if (this.props.query.forceLogout === 'true') {
            this.onOauthLoggedOut();
            return;
        }

        if (this.state.pixel && !this.state.authorized) {
            return this.postMessage(null, this.props.query.state, null);
        }

        if (this.props.query.reauthenticate === 'true') {
            try {
                const user = await this.api.get('/user');
                if (!user || user.code !== 200) {
                    return this.setState({ reauthenticate: false });
                }

                return this.setState({ user: user.content });
            }
            catch (e) {
                return this.setState({ error: e.toString() });
            }
        } else if (Local.hasToken()) {
          return this.setState({ step: STEPS.AUTHORIZE });
        }
    }

    componentWillUnmount() {
      window.removeEventListener(
        'message',
        this.handlePostMessage,
        false
      );
    }

    render() {

      let view;
      const step = this.state.step;

      switch (step) {
        case STEPS.CHECK_EMAIL:
          view = (
            <StepEmailEntry
              clientId={this.props.query.client_id}
              query={this.props.query}
              onEmailAccountCheck={this.onEmailAccountCheck}
              onError={this.onError}
            />
          );
          break;
        case STEPS.CREATE_ACCOUNT:
          view = (
            <StepCreateAccount
              clientId={this.props.query.client_id}
              optIn={this.state.optIn}
              optInCopy={this.state.optInCopy}
              optInSelected={this.state.optInSelected}
              query={this.props.query}
              showGuestLink={this.state.showGuestLink}
              username={this.state.username}
              goAuthorize={this.goAuthorize}
              goGuest={this.goGuest}
              goSignIn={this.goSignIn}
              goValidateEmail={this.goValidateEmail}
              onError={this.onError}
              onOptIn={this.onOptIn}
              postMessageRaw={this.postMessageRaw}
            />
          );
          break;
        case STEPS.SIGN_IN:
          view = (
            <StepSignIn
              clientId={this.props.query.client_id}
              query={this.props.query}
              username={this.state.username}
              goCreateAccount={this.goCreateAccount}
              goForgotPassword={this.goForgotPassword}
              onError={this.onError}
              onLoginSuccess={this.onLoginSuccess}
              onTwoFactor={this.onTwoFactor}
              postMessageRaw={this.postMessageRaw}
            />
          );
          break;
        case STEPS.TWO_FACTOR:
          view = (
            <StepTwoFactor
              clientId={this.props.query.client_id}
              password={this.state.twoFactorPassword}
              query={this.props.query}
              username={this.state.twoFactorUsername}
              onError={this.onError}
              onLoginSuccess={this.onLoginSuccess}
              postMessageRaw={this.postMessageRaw}
            />
          );
          break;
        case STEPS.AUTHORIZE:
          view = (
            <AuthInitiationRequestView
              clientId={this.props.query.client_id}
              query={this.props.query}
              onApprove={this.onApprove}
              onDeny={this.onDeny}
              onLogout={this.onOauthLoggedOut}
              onError={this.onError}
              onRequiresReconciliation={this.onRequiresReconciliation}
              open={!this.state.stopInitiate}
              onShowAvailableUsers={this.onShowAvailableUsers}
              pixel={this.state.pixel}
            />
          );
          break;
        case STEPS.REDIRECT:
          view = (
            <StepRedirect
              redirectTo={this.state.redirect}
            />
          );
          break;
        case STEPS.VALIDATE_EMAIL:
          view = (
            <StepValidateEmail
              query={this.props.query}
              username={this.state.username}
              goCreateAccount={this.goCreateAccount}
              goSignIn={this.goSignIn}
              onError={this.onError}
            />
          );
          break;
        case STEPS.FORGOT_PASSWORD:
          view = (
            <StepForgotPassword
              query={this.props.query}
              goCreateAccount={this.goCreateAccount}
              goSignIn={this.goSignIn}
              username={this.state.username}
            />
          );
          break;
        default:
          break;
      }

      return (
        <div className={this.props.classes.pageContainer}>
          <PopAlert
              color={'cc0000'}
              open={this.state.errorOpen}
              message={this.state.error}
              onClose={this.onErrorClose}
              hasExit={true}
          />
          {view}
        </div>
      );
    }
}

// export default withStyles(styles)(HomeApp);

const mapStateToProps = state => {
  return {
    returnTo: state.returnTo
  };
}

const ConnectedPage = connect(mapStateToProps, null)(HomeApp);

export default withStyles(styles)(ConnectedPage);
