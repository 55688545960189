import React from 'react';
import { connect } from 'react-redux'
import jwt_decode from "jwt-decode";

import { withStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Box from '@material-ui/core/Box';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import Local from '../../../../../../common/local';

import LeftRail from '../../../../shared/components/left-rail';
import PageHeader from '../../../../shared/components/page-header';

const styles = (theme) => ({
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  },
  icon: {
    paddingRight: '10px'
  },
  currentUser: {
    fontWeight: 'bold'
  },
  secondheadline: {
    fontSize: "28px",
    letterSpacing: "-1.3px",
    margin: "0 0 20px 0",
    fontWeight: "bold",
    [theme.breakpoints.up('md')]: {
      textAlign: "left",
      fontSize: "32px",
    },
  },
  borders: {
    borderColor: '#ddd'
  },
  buttonControls: {
    display: "flex",
    marginTop: "25px"
  },
  text: {
    fontSize: '14px',
  },
  textChangeUser: {
    fontSize: '16px',
  },
  ctaBlock: {
    paddingTop: '40px'
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  linkUnderline: {
    color: '#909090',
    borderBottom: 'solid 1px #909090',
    paddingBottom: '2px',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  list: {
    paddingLeft: '18px'
  },
  listItem: {
    marginBottom: '8px',
    listStyleType: 'disc',
  },
  listLabel: {
    margin: '20px 0 8px 0',
  }
});

class ChangeUserView extends React.Component {

  constructor(props) {
    super(props);

    this.getAvailableUsers = this.getAvailableUsers.bind(this);
    this.generateRemove = this.generateRemove.bind(this);
    this.onUserClick = this.onUserClick.bind(this);
    this.onRemoveClick = this.onRemoveClick.bind(this);
  }

  getAvailableUsers() {
    const users = Local.getAvailableUsers();
    let elements = [];
    const now = Date.now()/1000;

    const currentUsername = Local.hasToken() ? Local.getCurrentUser().username : undefined;

    if (users) {
      Object.keys(users).forEach(username => {
        const current = currentUsername === username;
        let secondary = '----------'
        if (users[username].token) {
          const decoded = jwt_decode(users[username].token);
          secondary = decoded.exp < now ? 'Signed Out' : 'Available'
        }
        secondary = current ? 'Current User' : secondary;
        elements.push((
          <Box key={username} borderBottom={1} className={this.props.classes.borders}>
            <ListItem button  onClick={this.onUserClick(username)}>
              <ListItemAvatar>
                <Typography color={current ? "primary" : "textSecondary"} ><AccountCircleIcon /></Typography>
              </ListItemAvatar>
              <ListItemText
                disableTypography
                primary={<Typography className={current ? this.props.classes.currentUser: null}>{username}</Typography>}
                secondary={secondary}
              />
              {
                !current ? <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="remove" onClick={this.generateRemove(username)}>
                    <HighlightOffIcon />
                  </IconButton>
                </ListItemSecondaryAction> : null
              }
            </ListItem>
          </Box>
        ));
      });
    }

    elements.push((
      <ListItem button key="usecreate"  onClick={this.onUserClick()}>
        <ListItemAvatar>

            <Typography><AddCircleOutlineIcon /></Typography>
        </ListItemAvatar>
        <ListItemText
          primary="Use Another Account"
        />
      </ListItem>
    ));

    return elements
  }

  onUserClick(username) {
    return async function() {
      return this.props.onChangeUser(username);
    }.bind(this);
  }

  onRemoveClick(username) {

    Local.userLogout(username);
    this.setState({});
  }

  generateRemove(username) {

    return async function() {

      return this.onRemoveClick(username);
    }.bind(this);
  }

  render() {
    return (
      <Grid container spacing={0} className={this.props.classes.view}>
        <Grid item xs={12} md={6}>
          <LeftRail />
        </Grid>
        <Grid item xs={12} md={6}>
          <PageHeader text="Switch User" />
          <List dense={false}>
            {this.getAvailableUsers()}
          </List>
        </Grid>
      </Grid>
    )
  }
}

const ConnectedChangeUserView = connect(null, null)(ChangeUserView);

export default withStyles(styles)(ConnectedChangeUserView);
