import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Api from '../../../../../../common/api';
import Local from '../../../../../../common/local';

import Heading from '../../../../shared/components/heading';
import LeftRail from '../../../../shared/components/left-rail';
import StandardInput from '../../../../shared/components/standard-input';


const styles = theme => ({
  buttonControls: {
    display: "flex",
    justifyContent: "center",
    marginTop: "25px"
  },
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  },
  text: {
    fontSize: '14px'
  }
});

class ReconcileView extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            processing: false,
            username: this.props.username,
            code: ''
        };

        this.generateOnInput = this.generateOnInput.bind(this);
        this.ensureVerification = this.ensureVerification.bind(this);
        this.onSubmitPressed = this.onSubmitPressed.bind(this);
        this.api = new Api();
    }

    generateOnInput(stateKey) {

        return function (e) {

            this.setState({
                [stateKey]: e.target.value
            });
        }.bind(this);
    }

    async ensureVerification() {

        this.setState({ processing: true });

        try {
            const response = await this.api.post('/user/authenticate/verify', { verificationCode: this.state.code });
            this.setState({ processing: false });

            switch (response.code) {
                case 401:
                    return this.props.onError({
                        error: `We're sorry, that code did not match our records. Please try again.`
                    });
                case 404:
                    return this.props.onError({
                        error: `We're sorry, your customer record could not be located. Please email support for assistance.`
                    });
                case 201:
                    Local.userLogin(response.content.token, this.state.username);
                    return this.props.onVerificationSuccess();
                default:
                    return this.props.onError({
                        error: `We're sorry, our servers are having trouble verifying your account right now. Please try again later.`
                    });
            }
        }
        catch (e) {
            console.error(e);
            this.setState({ processing: false });
            return this.props.onError({ error: e.toString() });
        }
    }

    async onSubmitPressed(e) {

        if (e.key === 'Enter') {
            return await this.ensureVerification();
        }
    }

    async componentDidMount() {

        if (!this.state.username) {
            try {
                const response = await this.api.get('/user');
                if (response.code !== 200) {
                    return this.props.onError({ error: 'We\'re sorry, we\'re having trouble communicating with our servers right now. Please try again later.' })
                }
                return this.setState({
                    username: response.content.username
                });
            }
            catch (e) {
                return this.props.onError({ error: e.toString() });
            }
        }
    }

    render() {
        return (
            <Grid container spacing={0} className={this.props.classes.view}>
              <Grid item xs={12} md={6}>
                <LeftRail clientId={this.props.clientId}/>
              </Grid>
              <Grid item xs={12} md={6}>
                <Heading text="Verify your Account"/>
                <Grid item xs={12}>
                <Typography>
                    <p className={this.props.classes.text}>We're dedicated to keeping your account secure. We need to verify your account before you can continue.</p>
                    <p className={this.props.classes.text}>An email has been sent to <strong>{this.state.username}</strong>. Please enter the code in this email here.</p>
                </Typography>
                </Grid>
                <Grid item xs={12}>
                    <StandardInput
                        style={{ marginBottom: '12px' }}
                        password={false}
                        attribute="code"
                        label="CODE"
                        onChange={this.generateOnInput('code')}
                        onKeyUp={this.onSubmitPressed} />
                </Grid>
                <Grid item xs={12}>
                  <div className={this.props.classes.buttonControls}>
                    <Button color="primary" onClick={this.ensureVerification} variant="contained">
                        {this.state.processing ?
                            <CircularProgress size={14} color="inherit" />
                            : 'Verify'
                        }</Button>
                    </div>
                </Grid>
              </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(ReconcileView);
