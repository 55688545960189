import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import Branding from './shared/components/branding';

import Applications from './pages/applications/index';
import Cancel from './pages/cancel/index';
import Faq from './pages/faq/index';
import Forgot from './pages/forgot/index';
import Home from './pages/home/index';
import GN from './pages/gn/index';
import Invitation from './pages/invitation/index';
import NewAccount from './pages/new-account/index';
import NotFound from './pages/not-found/index';
import Preferences from './pages/preferences/index';
import Reset from './pages/reset/index';
import Verify from './pages/verify/index';
import VerifyEmail from './pages/verify-email/index';
import VerifyIdentities from "./pages/identities/index";
import WhiteLabel from './pages/white-label/index';

const Main = () => (
  <main>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/applications" component={Applications} />
      <Route exact path="/cancel" component={Cancel} />
      <Route exact path="/faq" component={Faq} />
      <Route exact path="/forgot" component={Forgot} />
      <Route exact path="/identity" component={VerifyIdentities} />
      <Route exact path="/invitation" component={Invitation} />
      <Route exact path="/new-account" component={NewAccount} />
      <Route exact path="/preferences" component={Preferences} />
      <Route exact path="/reset" component={Reset} />
      <Route exact path="/verify" component={VerifyEmail} />
      <Route path="/verify/:verificationCode" component={Verify} />
      <Route exact path="/wlabel" component={WhiteLabel} />
      <Route exact path="/gn" component={GN} />
      <Route path="*" status={404} component={NotFound} />
    </Switch>
  </main>
);

class Skin extends Component {

  render() {

    if (this.props.loading) {
      return (
        <Grid item>
        </Grid>
      );
    }

    return (
      <Branding>
        <Main />
      </Branding>
    );
  }
}

export default Skin;
