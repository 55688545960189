import React from 'react';

import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({

});

class Recaptcha extends React.Component {

  render() {
    return (
      <Typography className={this.props.textClassname} color="textSecondary" >
        This site is protected by reCAPTCHA and the Google <a className={this.props.linkClassname} target="_blank" href="https://policies.google.com/privacy">Privacy Policy</a> and <a className={this.props.linkClassname} target="_blank" href="https://policies.google.com/terms">Terms of Service</a> apply.
      </Typography>
    );
  }
}

export default withStyles(styles)(Recaptcha);
