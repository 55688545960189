import React from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import Auth from '../../../../../../common/auth';
import Util from '../../../../../../common/util';

import Heading from '../../../../shared/components/heading';

const styles = (theme) => ({
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  }
});

class View extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      redirect: null
    };

    this.onLogout = this.onLogout.bind(this);
    this.onPreferencesClick = this.onPreferencesClick.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  async componentWillMount() {

    if (this.props.query.aslogin) {
      return this.setState({
        username: this.props.query.aslogin
      });
    }
  }

  onLogout() {
    Auth.userLogout(this.props.dispatch);
  }

  onPreferencesClick(e) {
    e.preventDefault();
    this.redirect(`/preferences${Util.toQueryString(this.props.query)}`);
  }

  redirect(redirect) {
    this.setState({ redirect });
  }

  render() {

    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />
    }

    return (
      <Card elevation={3} className={this.props.classes.view}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item>
              <Heading text="Welcome back" />
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary" className={this.props.classes.heading}>Currently logged in as:</Typography>
              <Typography color="textPrimary" className={this.props.classes.username}><strong>{this.props.user.username}</strong></Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography className={this.props.classes.listLabel}>
                    <Link color="inherit" className={this.props.classes.link} onClick={this.onPreferencesClick} href="/preferences"><strong>Click here</strong></Link>
                    <Typography variant="inherit" color="textSecondary"> to</Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ul className={this.props.classes.list}>
                    <Typography variant="inherit" color="textSecondary"><li className={this.props.classes.listItem}>Change your password</li></Typography>
                    <Typography variant="inherit" color="textSecondary"><li className={this.props.classes.listItem}>Update Two-Factor Authentication Preferences</li></Typography>
                    <Typography variant="inherit" color="textSecondary"><li className={this.props.classes.listItem}>Manage Application Access</li></Typography>
                    <Typography variant="inherit" color="textSecondary"><li className={this.props.classes.listItem}>Logout of all devices</li></Typography>
                  </ul>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={this.onLogout}>Logout</Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: Auth.userState(state)
  };
}

const ConnectedView = connect(mapStateToProps, null)(View);

export default withStyles(styles)(ConnectedView);
