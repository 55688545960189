import React from 'react';
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Util from '../../../../common/util'

const propertiesLocation = 'https://static.golfchannel.com/global-nav/global-nav.v2.json';
const styles = (theme) => ({
  logo: {
    margin: '10px auto 30px auto',
    maxWidth: '1000px',
  },
  lightMainLogo: {
    width: '270px',
    height: '100px',
    display: 'block'
  },
  darkMainLogo: {
    width: '300px',
    height: '100px',
    display: 'block'
  },
  properties: {
    margin: '10px 0',
  },
  subLogos: {
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  subLogosImg: {
    margin: "0 0 0 10px",
    padding: "0 10px 0 0",
    borderRight: "1px solid",
  },
  subLogosImgNoBorder: {
    margin: "0 0 0 10px",
    padding: "0 10px 0 0",
    borderRight: "none",
  },
  logoGolfPass: {
    height: '11px'
  }
});

class LogoMain extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      properties: undefined
    }

    this.getProperties = this.getProperties.bind(this);
  }

  async componentWillMount() {

    const request = {
      method: 'GET'
    };



    let propertiesUrl = window.globals && window.globals.gidPropertiesUrl ? window.globals.gidPropertiesUrl : propertiesLocation;

    let res = await fetch(propertiesUrl, request);
    const properties = await res.json();
    const localeProperties = properties.mainnav.filter((property) => {
      if (!property.locale || (property.locale && property.locale.includes('en_US')))
      return true;

      return false
    });

    this.setState({properties: localeProperties});
  }

  getProperties() {
    if (!this.state.properties)
      return (
        <Grid className={this.props.classes.properties +' '+ this.props.classes.subLogos} container spacing={0} justifyContent="center">
          <Typography />
        </Grid>
      );

    let properties = [];
    let temp = [];
    this.state.properties.forEach((property, count) => {
      if ((count + 1) % 6 === 0 ||
      (count + 1) === this.state.properties.length)
      {
        temp.push(<span key={property.code} className={this.props.classes.subLogosImgNoBorder}>{property.name.toUpperCase()}</span>);
        properties.push((
          <Grid key={`props-grid-${count}`} className={this.props.classes.properties +' '+ this.props.classes.subLogos} container spacing={0} justifyContent="center">
            {temp}
          </Grid>
        ));

        temp = [];

      }
      else {
        temp.push(<span key={property.code} className={this.props.classes.subLogosImg}>{property.name.toUpperCase()}</span>);
      }
    });

    if (temp.length > 0) {
      properties.push((
        <Grid key={`props-grid-end`} className={this.props.classes.properties +' '+ this.props.classes.subLogos} container spacing={0} justifyContent="center">
          {temp}
        </Grid>
      ));
    }

    return (
      <Grid className={this.props.classes.properties +' '+ this.props.classes.subLogos} container spacing={0} justifyContent="center">
        <Typography component="div">
          {properties}
        </Typography>
      </Grid>
    );
  }

  render() {
    // Because this component is not part of Router, props.location is not set.
    const params = Util.queryToObject(window.location.search);
    const properties = this.getProperties();
    const publicDir = !window.globals || !window.globals.gidPublic ? '' : window.globals.gidPublic;
    const mode = this.props.mode === 'light' ? 'dark' : 'light';

    if (params.hasOwnProperty('noLogos')) {
      return (
        <Grid className={this.props.classes.logo} container spacing={0} justifyContent="center" >
          <img className={this.props.classes[`${mode}MainLogo`]} src={`${publicDir}/img/${mode}-nbc-golf.svg`} alt='Golf Channel'/>
        </Grid>
      );
    }

    return (
      <Grid className={this.props.classes.logo} container spacing={0} justifyContent="center" >
        <img className={this.props.classes[`${mode}MainLogo`]} src={`${publicDir}/img/${mode}-nbc-golf.svg`} alt='Golf Channel'/>
        {properties}
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    mode: state.mode
  }
}

const ConnectedLogoMain = connect(mapStateToProps, null)(LogoMain);

export default withStyles(styles)(ConnectedLogoMain);
