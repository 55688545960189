import React from 'react';

import PreferencesApp from './components/preferences-app';
import WithQuery from '../../../../common/components/with-query';

const Preferences = props => {
  return (
    <WithQuery query={props.location.search}>
      <PreferencesApp />
    </WithQuery>
  );
}

export default Preferences;
