import React from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Auth from '../../../../../../common/auth';
import Util from '../../../../../../common/util';

import { setUser } from '../../../../../../actions/auth';

import Heading from '../../../../shared/components/heading';

const styles = (theme) => ({
  link: {
    cursor: "pointer"
  },
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  }
});

class View extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      redirect: null
    };

    this.onCancel = this.onCancel.bind(this);
    this.onVerified = this.onVerified.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  async componentWillMount() {
    Auth.userVerifyEmail(this.props.dispatch);
  }

  onCancel() {
    const newQuery = Object.assign(this.props.query, {});
    delete newQuery.request;
    delete newQuery.redirectUri;
    this.redirect(`/${Util.toQueryString(newQuery)}`);
  }

  onVerified() {
    if (this.props.query.redirectUri) {
      window.location = decodeURIComponent(this.props.query.redirectUri);
      return;
    }
    const newQuery = Object.assign(this.props.query, {});
    delete newQuery.request;
    delete newQuery.redirectUri;

    let user = Object.assign(this.props.user, { verified: true });
    this.props.dispatch(setUser(user));

    this.redirect(`/${Util.toQueryString(newQuery)}`);
  }

  redirect(redirect) {
    this.setState({ redirect });
  }

  render() {
    if (this.state.redirect !== null) {
      return (
        <Redirect to={this.state.redirect} />
      );
    }

    let view;
    let buttonText = this.props.query.redirectUri ? 'Proceed' : 'Return to Login';

    if (this.props.error.field === 'global') {
      buttonText = 'Return to Login';
      view = (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography color="textSecondary" className={this.props.classes.text}>{this.props.error.message}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary" className={this.props.classes.text}>Please contact support for more information.</Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    if (this.props.flow.isVerified) {
      view = (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography color="textPrimary" className={this.props.classes.text}>Success! Your email address has been verified.</Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <Card elevation={3} className={this.props.classes.view}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item>
              <Heading text={'Verifying Email'} />
            </Grid>
            {view}
            <Grid item xs={12}>
              <Button className={`${this.props.classes.button}`} color="primary" onClick={this.props.flow.isVerified ? this.onVerified : this.onCancel} variant="contained">
                  {buttonText}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.error,
    flow: state.flow,
    user: Auth.userState(state)
  };
}

const ConnectedView = connect(mapStateToProps, null)(View);

export default withStyles(styles)(ConnectedView);
