import React from 'react';
import Util from '../util';

class WithQuery extends React.Component {

  constructor(props) {

    super(props);
    this.__calculateQueryable = this.__calculateQueryable.bind(this);
  }

  __calculateQueryable() {

    return Util.queryToObject(this.props.query);
  }

  render() {

    return React.cloneElement(this.props.children, { query: this.__calculateQueryable() });
  }
}

export default WithQuery;
