import React from 'react';

import ResetApp from './components/reset-app';
import WithQuery from '../../../../common/components/with-query';

const Reset = props => {
  return (
    <WithQuery query={props.location.search}>
      <ResetApp />
    </WithQuery>
  );
}

export default Reset;
