import React from 'react';
import Api from '../../../../common/api';
import Local from '../../../../common/local';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({});

class LogoutButton extends React.Component {

  constructor(props) {
    super(props);
    this.clicked = this.clicked.bind(this);
    this.api = new Api();
  }

  async clicked() {

    try {
      await this.api.delete('/user/authenticate');
    }
    catch (e) {
      console.error(e);
    }

    Local.userLogout(Local.getCurrentUser().username);
    if (this.props.onLogout) {
      this.props.onLogout();
    }
  }

  render() {

    return (
      <Button color="primary" onClick={this.clicked} variant="contained">Logout</Button>
    );
  }
}

export default withStyles(styles)(LogoutButton);
