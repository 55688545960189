import React, { Component }  from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  header: {
    fontSize: "28px",
    fontWeight: "bold",
    letterSpacing: "-1.3px",
    margin: "0 0 10px 0",
    [theme.breakpoints.up('md')]: {
      fontSize: "32px",
      textAlign: "left"
    },
  }
});

class PageHeader extends Component {

  render() {
    return (
      <Typography color="textPrimary" className={this.props.classes.header}>{this.props.text}</Typography>
    );
  }
}

export default withStyles(styles)(PageHeader);
