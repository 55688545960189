import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    btn: {
      margin: '0 10px 10px auto',
    },
    checkboxCtrl: {
      marginBottom: '10px'
    }
});

class GrantRevocationDialog extends Component {

    constructor(props) {

        super(props);

        this.onApprove = this.onApprove.bind(this);
        this.onDeny = this.onDeny.bind(this);
        this.onWarnClick = this.onWarnClick.bind(this);
    }

    onApprove() {

        return this.props.onApprove(this.props.applicationName, this.props.clientId);
    }

    onDeny() {

        return this.props.onDeny();
    }

    onWarnClick(e) {

        return this.props.onWarnClick(e.target.checked);
    }

    render() {

        return (
          <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              fullWidth={true}
              open={this.props.open}>
              <DialogContent>
                  <DialogContentText>
                      You are about to disallow <strong>{this.props.applicationName}</strong> from using information in your account.
                  </DialogContentText>
                  <DialogContentText>
                      You can always re-allow this application by signing in through that website.
                  </DialogContentText>
                  <DialogContentText>
                      Are you sure you want to revoke {this.props.applicationName}&#39;s permission?
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Grid container spacing={0} justify="center">
                      <Grid item xs={12} sm={4} className={this.props.classes.checkboxCtrl} >
                        <FormControlLabel
                            control={
                                <Checkbox color="primary" defaultValue={this.props.mustWarn} onChange={this.onWarnClick} />
                            }
                            label={
                                <span>
                                    Do not warn me about revoking permissions again.
                                </span>
                            } />
                      </Grid>
                      <Grid item sm={4}>
                          <Button className={this.props.classes.btn} color="primary" onClick={this.onDeny} variant="contained">Cancel</Button>
                      </Grid>
                      <Grid item sm={4}>
                          <Button className={this.props.classes.btn} color="primary" onClick={this.onApprove} variant="outlined">Revoke</Button>
                      </Grid>
                  </Grid>
              </DialogActions>
          </Dialog>
        );
    }
}

export default withStyles(styles)(withMobileDialog()(GrantRevocationDialog));
