import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  heading: {
    fontSize: '18px',
    fontWeight: 'normal',
    marginBottom: '20px',
    [theme.breakpoints.up('lg')]: {
      fontSize: '20px',
    },
  }
});

class Heading extends React.Component {
  render() {
    return (
      <Typography color="textPrimary" className={this.props.classes.heading}>{this.props.text}</Typography>
    );
  }
}


export default withStyles(styles)(Heading);
