const tokenName = process.env.REACT_APP_TOKEN_NAME || 'gnid.__atstk';
const coreFields = [
  'response_type',
  'client_id',
  'redirect_uri',
  'scope',
  'state',
  'code_challenge',
  'code_challenge_method'
];

var storage = {};

module.exports = {
  toCoreQueryObject: (query) => {

    if (!query || !Object.keys(query).length) {
      return undefined;
    }

    const outcome = coreFields.reduce((o, curr) => {

      if (query[curr]) {
        o[curr] = query[curr];
      }
      return o;
    }, {});

    return Object.keys(outcome).length ? outcome : undefined;
  },
  toCoreQueryString: (query) => {

    if (!query || !Object.keys(query).length) {
      return '';
    }

    return '?' + Object.keys(query)
    .filter((k) => {
      return coreFields.some(c => c === k);
    })
    .map((k) => {

      return `${k}=${query[k]}`;
    }).join('&');
  },
  toQueryString: (query, skip = null) => {

    if (!query || !Object.keys(query).length) {
      return '';
    }

    return '?' +
    Object.keys(query).filter((k) => {
      if (!skip) {
        return true;
      }
      for (let i = 0; i < skip.length; ++i) {
        if (skip[i] === k) {
          return false;
        }
      }

      return true;
    })
    .map((k) => {

      if (Array.isArray(query[k])) {
        return query[k].map((v) => {

          return `${k}=${v}`;
        }).join('&');
      }
      else {
        return `${k}=${query[k]}`;
      }
    }).join('&');
  },
  queryToObject: (query) => {

    return query ?
    query.slice(1).replace(/[?]/g, '&').split('&').reduce((acc, s) => {

      const fields = s.split('=');
      if (fields.length !== 2) {
        return acc;
      }

      if (acc[fields[0]]) {
        if (Array.isArray(acc[fields[0]])) {
          acc[fields[0]].push(acc[fields[1]]);
        }
        else {
          acc[fields[0]] = [acc[fields[0]], fields[1]];
        }
      }
      else {
        acc[fields[0]] = fields[1];
      }

      return acc;
    }, {})
    : {};
  },
  isAuthInitiationRequest: (query) => {

    return ['client_id', 'redirect_uri', 'state', 'scope', 'response_type'].reduce((val, curr) => {

      return val && query[curr] !== undefined;
    }, true);
  },
  hasToken: () => {
    try {
      return localStorage.getItem(tokenName) !== null;
    } catch (e) {
      return storage[tokenName] !== undefined;
    }
  },
  setToken: (token) => {

    try {
      localStorage.setItem(tokenName, token);
    } catch (e) {
      return storage[tokenName] = token;
    }
  },
  removeToken: () => {

    try {
      localStorage.removeItem(tokenName);
    } catch (e) {
      return storage[tokenName] = undefined;
    }
  },
  getToken: () => {

    try {
      return localStorage.getItem(tokenName);
    } catch (e) {
      return storage[tokenName];
    }
  }
};
