import React from 'react';
import { Redirect } from 'react-router';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import PeopleIcon from '@material-ui/icons/People';
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';

import Api from '../../../../../../common/api'
import Auth from '../../../../../../common/auth'
import Local from '../../../../../../common/local'
import Util from '../../../../../../common/util';
import Recaptcha from '../../../../../../common/components/recaptcha';

import CurrentUser from '../../../../shared/components/current-user';
import Heading from '../../../../shared/components/heading';
import LeftRail from '../../../../shared/components/left-rail';
import PageHeader from '../../../../shared/components/page-header';
import StandardInput from '../../../../shared/components/gn-standard-input';

const styles = (theme) => ({
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  },
  button: {
    borderRadius: '10px',
    fontWeight: '600',
    fontSize: '16px',
    height: '55px',
    width: '100%',
  },
  buttonControls: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px"
  },
  noHeaderContainer: {
    padding: '20px 20px 0px 20px',
    maxWidth: '1200px',
    margin: '0 auto 50px auto'
  }
});

class StepEmailEntry extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      processing: false,
      username: '',
      validation: {
        username: null
      }
    };

    this.api = new Api();
    this.generateOnInput = this.generateOnInput.bind(this);
    this.onError = this.onError.bind(this);
    this.onCheckEmail = this.onCheckEmail.bind(this);
    this.onSubmitPressed = this.onSubmitPressed.bind(this);
  }

  generateOnInput(stateKey) {

    return function (e) {

      this.setState({
        [stateKey]: e.target.value,
        validation: { username: null, password: null }
      });
    }.bind(this);
  }

  onError(err) {

    this.setState({ processing: false });
    this.props.onError(err);
  }

  async onCheckEmail() {
    const {
      username
    } = this.state;

    const validation = {
      username: username === '' ? 'Please enter an email address.' : null
    };

    if (Object.values(validation).some((v) => { return v !== null; })) {
      return this.setState({ validation });
    }

    this.setState({ processing: true });
    const exists = await Auth.emailHasAccount(username);
    this.setState({ processing: false });

    if (exists === null) {
      validation.username = 'Please enter a valid email address.';
      this.setState({ validation });

      return;
    }

    this.props.onEmailAccountCheck(username, exists);
  }

  async onSubmitPressed(e) {

    if (e.key === 'Enter') {
      e.preventDefault();
      await this.onCheckEmail();
    }
  }

  render() {
    const containerStyle =
      this.props.query.noHeader ?
      this.props.classes.noHeaderContainer :
      this.props.classes.view;

    const emailInput = (
      <Grid item xs={12} md={12}>
        <StandardInput
          inputType="email"
          value={this.state.username}
          style={{ marginBottom: '15px' }}
          error={this.state.validation.username}
          attribute="username"
          label="Email Address"
          labelWidth={85}
          onChange={this.generateOnInput('username')}
          onKeyUp={this.onSubmitPressed} />
      </Grid>
    );

    const buttonStyle = !this.props.query.buttonColor ? null :
    {
      backgroundColor: `#${this.props.query.buttonColor}`
    };

    const bottomControls = (
      <Grid className={this.props.classes.buttonControls} item xs={12}>
        <Button style={buttonStyle} className={this.props.classes.button} color="primary" onClick={this.onCheckEmail} variant="contained" id="checkEmail">
          {this.state.processing ?
            (<CircularProgress size={14} color="inherit" />)
            : 'Continue'
          }
        </Button>
      </Grid>
    );

    return (
      <Grid container spacing={1} className={containerStyle} >
        {emailInput}
        {bottomControls}
      </Grid>
    );
  }
}

export default withStyles(styles)(StepEmailEntry);
