import React from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import PeopleIcon from '@material-ui/icons/People';
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';

import Api from '../../../../../../common/api'
import Auth from '../../../../../../common/auth';
import Local from '../../../../../../common/local'
import Util from '../../../../../../common/util';
import Recaptcha from '../../../../../../common/components/recaptcha';

import CurrentUser from '../../../../shared/components/current-user';
import Heading from '../../../../shared/components/heading';
import LeftRail from '../../../../shared/components/left-rail';
import PopAlert from '../../../../shared/components/pop-alert';
import PageHeader from '../../../../shared/components/page-header';
import StandardInput from '../../../../shared/components/gn-standard-input';

const styles = (theme) => ({
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  },
  button: {
    borderRadius: '10px',
    fontWeight: '600',
    fontSize: '16px',
    height: '55px',
    width: '100%',
  },
  buttonControls: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px"
  },
  guestText: {
    fontSize: '14px',
    paddingTop: '20px',
    textAlign: 'center'
  },
  noHeaderContainer: {
    padding: '20px 20px 0px 20px',
    maxWidth: '1200px',
    margin: '0 auto 50px auto'
  },
  infoText: {
    fontSize: '14px',
    textAlign: 'center'
  },
  linkText: {
    color: '#222222',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  signInText: {
    fontSize: '14px',
    paddingTop: '10px',
    textAlign: 'center'
  },
});

class StepValidateEmail extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      emailValidated: false,
      message: '',
      polling: false,
      pop: false,
      processing: false,
      username: props.username ? props.username : '',
      validateEmailSent: false,
      validation: {
        password: null,
        username: null,
      }
    };

    this.api = new Api();
    this.checkValidation = this.checkValidation.bind(this);
    this.generateOnInput = this.generateOnInput.bind(this);
    this.goGuest = this.goGuest.bind(this);
    this.goCreateAccount = this.goCreateAccount.bind(this);
    this.onPopClose = this.onPopClose.bind(this);
    this.onVerify = this.onVerify.bind(this);
    this.onSubmitPressed = this.onSubmitPressed.bind(this);
  }

  async checkValidation() {
    const validated = await Auth.emailIsValidated(this.state.username);

    if (validated) {
      this.setState({
        emailValidated: validated
      });
      return;
    }

    setTimeout(this.checkValidation, 5 * 1000);
  }

  generateOnInput(stateKey) {

    return function (e) {

      this.setState({
        [stateKey]: e.target.value,
        validation: { username: null, password: null }
      });
    }.bind(this);
  }

  goGuest(e) {
    e.preventDefault();
    this.props.goGuest(this.state.username);
  }

  goCreateAccount(e) {
    e.preventDefault();
    this.props.goCreateAccount(this.state.username);
  }

  onPopClose() {
    this.setState({
      pop: false
    });
  }

  async onSubmitPressed(e) {

    if (e.key === 'Enter') {
      return this.onVerify();
    }
  }

  async onVerify() {
    const res = await Auth.userRequestVerify(this.props.dispatch, this.state.username, true, true);

    if (res.code === 412) {
      return this.props.onError({
        error: `An account already exists with that email.`
      });
    } else if (res.code === 429) {
      return this.props.onError({
        error: `A verification email has recently been sent. Please wait a moment before trying to resend.`
      });
    } else if (res.code === 400) {
      return this.props.onError({
        error: res.content.message
      })
    }

    if (!this.state.polling) {
      setTimeout(this.checkValidation, 5 * 1000);
    }

    // Not an error, but use the toast
    this.setState({
      message: `A verification email has sent.`,
      polling: true,
      pop: true,
      validateEmailSent: true,
    });
  }

  render() {
    const containerStyle =
      this.props.query.noHeader ?
      this.props.classes.noHeaderContainer :
      this.props.classes.view;

    const endAdornment = this.state.showPassword ?
      <VisibilityOffIcon color="action" style={{'cursor': 'pointer'}} onClick={this.onShowPassword} /> :
      <VisibilityIcon color="action" style={{'cursor': 'pointer'}} onClick={this.onShowPassword} />;

    const endAdornmentWrapper = (
      <InputAdornment position="end">
        <IconButton
            aria-label="toggle password visibility"
            onClick={this.onShowPassword}
            edge="end"
          >
            {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
      </InputAdornment>
    )

    const emailInput = (
      <Grid item xs={12} md={12}>
        <StandardInput
          inputType="email"
          value={this.state.username}
          style={{ marginBottom: '15px', marginTop: '15px'}}
          error={this.state.validation.username}
          attribute="username"
          label="Email Address"
          labelWidth={85}
          focus={true}
          onChange={this.generateOnInput('username')}
          onKeyUp={this.onSubmitPressed} />
      </Grid>
    );

    const buttonStyle = !this.props.query.buttonColor ? null :
    {
      backgroundColor: `#${this.props.query.buttonColor}`
    };

    const bottomControls = (
      <Grid className={this.props.classes.buttonControls} item xs={12}>
        <Button style={buttonStyle} className={this.props.classes.button} color="primary" onClick={this.onVerify} variant="contained" id="login">
          {this.state.processing ?
            (<CircularProgress size={14} color="inherit" />)
            : 'Verify Email'
          }
        </Button>
      </Grid>
    );

    let view = (
      <Grid container spacing={1} className={containerStyle} >
        <PopAlert
            open={this.state.pop}
            message={this.state.message}
            onClose={this.onPopClose}
            hasExit={true}
        />
        <Grid item xs={12} md={12}>
          <Typography>
              We're sorry we couldn't validate your email automatically.  Enter the email address you are trying to create an account for and click the <Typography component="span" color='primary' display='inline'>Verify Email</Typography> button below.
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography>
              We will send an email to the address with a link to verify the address and allow the email to be used for account creation.
          </Typography>
        </Grid>
        {emailInput}
        <Grid className={this.props.classes.buttonControls} item xs={12}>
          <Button style={buttonStyle} className={this.props.classes.button} color="primary" onClick={this.onVerify} variant="contained" id="login">
            {this.state.processing ?
              (<CircularProgress size={14} color="inherit" />)
              : 'Verify Email'
            }
          </Button>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography className={this.props.classes.signInText} color="initial" >
            <Link className={this.props.classes.linkText} onClick={this.goCreateAccount} to="/gn">Back to create account</Link>
          </Typography>
        </Grid>
      </Grid>
    );

    if (this.state.validateEmailSent) {
      view = (
        <Grid container spacing={1} className={containerStyle} >
          <PopAlert
              open={this.state.pop}
              message={this.state.message}
              onClose={this.onPopClose}
              hasExit={true}
          />
          <Grid item xs={12} md={12}>
            <Typography>
                An email has been set to <Typography component='span' color='primary' display='inline'>{this.state.username}</Typography> with a link to validate your email.
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography>
                This link will expire in 24 hours, emails may take up to 10 minutes to arrive, and be sure to check your Spam folder.
            </Typography>
          </Grid>
          <Grid className={this.props.classes.buttonControls} item xs={12}>
            <Button style={buttonStyle} className={this.props.classes.button} color="primary" onClick={this.onVerify} variant="contained" id="login">
              {this.state.processing ?
                (<CircularProgress size={14} color="inherit" />)
                : 'Resend Email'
              }
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography className={this.props.classes.signInText} color="initial" >
              <Link className={this.props.classes.linkText} onClick={this.goCreateAccount} to="/gn">Back to create account</Link>
            </Typography>
          </Grid>
        </Grid>
      );
    }

    if (this.state.emailValidated) {
      view = (
        <Grid container spacing={1} className={containerStyle} >
          <PopAlert
              open={this.state.pop}
              message={this.state.message}
              onClose={this.onPopClose}
              hasExit={true}
          />
          <Grid item xs={12} md={12}>
            <Typography>
                <Typography component='span' color='primary' display='inline'>{this.state.username}</Typography> has been verified successfully.
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography>
                Click the back button below to return to the Create Account page.
            </Typography>
          </Grid>
          <Grid className={this.props.classes.buttonControls} item xs={12}>
            <Button style={buttonStyle} className={this.props.classes.button} color="primary" onClick={this.goCreateAccount} variant="contained" id="login">
              {'Back'}
            </Button>
          </Grid>
        </Grid>
      );
    }

    return view;
  }
}

const mapStateToProps = state => {
  return {
  };
}

const ConnectedStepValidateEmail = connect(mapStateToProps, null)(StepValidateEmail);

export default withStyles(styles)(ConnectedStepValidateEmail);
