import React from 'react';

import ApplicationsApp from './components/applications-app';
import WithQuery from '../../../../common/components/with-query';

const Applications = props => {
  return (
    <WithQuery query={props.location.search}>
      <ApplicationsApp />
    </WithQuery>
  );
}

export default Applications;
