import React from 'react';

import FaqApp from './components/faq-app';
import WithQuery from '../../../../common/components/with-query';

const Faq = props => {
  return (
    <WithQuery query={props.location.search}>
      <FaqApp />
    </WithQuery>
  );
}

export default Faq;
