import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  logo: {
    width: '90px',
    maxHeight: '50px'
  }
});

class Properties extends Component {

  render() {
    const publicDir = window.globals ? window.globals.gidPublic : '';

    return (
      <Grid container spacing={4} justify="center" alignContent="center" alignItems="center">
        <Grid item>
          <Grid container spacing={4} justify="center" alignContent="center" alignItems="center">
            <Grid item>
              <img className={this.props.classes.logo} src={`${publicDir}/img/nbcsports/golf.svg`} alt='Golf Channel'/>
            </Grid>
            <Grid item>
              <img className={this.props.classes.logo} src={`${publicDir}/img/nbcsports/olympics.svg`} alt='Olympics on NBC'/>
            </Grid>
            <Grid item>
              <img className={this.props.classes.logo} src={`${publicDir}/img/nbcsports/rotoworld.svg`} alt='RotoWorld'/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={4} justify="center" alignContent="center" alignItems="center">
            <Grid item>
              <img className={this.props.classes.logo} src={`${publicDir}/img/nbcsports/golfnow.svg`} alt='GolfNow'/>
            </Grid>
            <Grid item>
              <img className={this.props.classes.logo} src={`${publicDir}/img/nbcsports/golfpass.svg`} alt='GOLFPASS'/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const ConnectedProperties = connect()(Properties);

export default withStyles(styles)(ConnectedProperties);
