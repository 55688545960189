import React from 'react';
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import Auth from '../../../../../../common/auth';

const styles = theme => ({
  buttonControls: {
    display: "flex",
    justifyContent: "center",
    marginTop: "25px"
  }
});

class VerifyEmailButton extends React.Component {

    constructor(props) {

        super(props);
        this.onVerify = this.onVerify.bind(this);
    }

    onVerify() {
      Auth.userRequestVerify(this.props.dispatch, this.props.user.username);
      this.props.onSuccess('Verification email sent!');
    }

    render() {

        return (
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Button className={this.props.classes.buttonControls}
                        color="primary"
                        id="logout-click"
                        onClick={this.onVerify}
                        style={{
                            width: '100%'
                        }}
                        variant="contained">
                        Verify Email
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = state => {
  return {
    user: Auth.userState(state)
  };
}

const ConnectedVerifyEmailButton = connect(mapStateToProps, null)(VerifyEmailButton);

export default withStyles(styles)(ConnectedVerifyEmailButton);
