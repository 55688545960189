import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { setReturnTo } from '../../../../../actions/return';

import Api from '../../../../../common/api'
import Auth from '../../../../../common/auth';

import CurrentUser from '../../../shared/components/current-user';
import LeftRail from '../../../shared/components/left-rail';
import PageHeader from '../../../shared/components/page-header';
import PopAlert from '../../../shared/components/pop-alert';

import ApprovedApplications from './views/approved-applications';
import GrantRevocationDialog from './views/grant-revocation-dialog';
import Logout from './views/logout';
import LogoutEverywhere from './views/logout-everywhere';
import LogoutEverywhereWarning from './views/logout-everywhere-warning';
import Verify from './views/verify-email';
import PasswordUpdate from './views/password-update';
import TwoFactorUpdate from './views/two-factor-update';

const styles = theme => ({
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  },
  secondheadline: {
    fontSize: "28px",
    color: "#000000",
    letterSpacing: "-1.3px",
    margin: "0 0 20px 0",
    textAlign: "center",
    [theme.breakpoints.up('md')]: {
      textAlign: "left",
      fontSize: "32px",
    },
  },
});

class PreferencesApp extends Component {

    constructor(props) {

        super(props);
        this.state = {
            redirectTo: null,
            error: this.props.query.logged_out === 'true' ?
                   'You have been logged out. Please re-authenticate to continue.'
                   : null,
            user: {
                username: '',
                grants: [],
                twoFactor: {
                    enabled: false,
                    type: null,
                    data: {
                        phoneNumber: null
                    }
                }
            },
            message: null,
            messageOpen: false,
            revokeOpen: false,
            doNotWarn: false,
            revokeItem: {
                applicationName: null,
                clientId: null
            },
            revokeTokensWarning: false
        };

        this.api = new Api();
        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);
        this.onLoggedOut = this.onLoggedOut.bind(this);
        this.onTwoFactorCheck = this.onTwoFactorCheck.bind(this);
        this.onTwoFactorTypeChange = this.onTwoFactorTypeChange.bind(this);
        this.onTwoFactorPhoneKeyUp = this.onTwoFactorPhoneKeyUp.bind(this);
        this.onTwoFactorCountryCodeChange = this.onTwoFactorCountryCodeChange.bind(this);
        this.onTwoFactorSubmit = this.onTwoFactorSubmit.bind(this);
        this.onRevokeWarnChange = this.onRevokeWarnChange.bind(this);
        this.onRevokeApprove = this.onRevokeApprove.bind(this);
        this.onRevokeDeny = this.onRevokeDeny.bind(this);
        this.onRevokeClick  = this.onRevokeClick.bind(this);
        this.onMessageClose = this.onMessageClose.bind(this);
        this.onRevokeTokensClick = this.onRevokeTokensClick.bind(this);
        this.onRevokeTokensDeny = this.onRevokeTokensDeny.bind(this);
        this.onRevokeTokensApprove = this.onRevokeTokensApprove.bind(this);
    }

    onSuccess(message) {
        this.setState({ messageOpen: true, message });
    }

    onLoggedOut() {

        this.setState({ redirectTo: '/?logged_out=true' });
    }

    onError(message) {
        if (typeof message === 'object') {
            return this.setState({ messageOpen: true, message: message.error || '' });
        }

        this.setState({ messageOpen: true, message });
    }

    onMessageClose() {

        this.setState({ messageOpen: false });
    }

    onRevokeWarnChange(doNotWarn) {

        this.setState({ doNotWarn });
    }

    async onRevokeClick(applicationName, clientId) {
        if (this.state.doNotWarn) {
            try {
                return await this.onRevokeApprove(applicationName, clientId);
            }
            catch (e) {
                return this.setState({
                    message: e.toString(),
                    messageOpen: true
                });
            }
        }

        return this.setState({
            revokeOpen: true,
            revokeItem: {
                applicationName,
                clientId
            }
        });
    }

    onRevokeTokensClick() {

        return this.setState({
            revokeTokensWarning: true
        });
    }

    onRevokeTokensDeny() {

        return this.setState({
            revokeTokensWarning: false
        });
    }

    async onRevokeTokensApprove() {

        return this.setState({
            revokeTokensWarning: false
        });
    }

    async onRevokeApprove(appName, cid) {

        const applicationName = this.state.revokeItem.applicationName || appName;
        const clientId = this.state.revokeItem.clientId || cid;

        try {
            const user = await this.api.delete(`/application/${clientId}/grant`);

            if (user.code !== 202 && user.code !== 304) {
                throw new Error(`We're sorry. There was a problem updating your account. Please try again later or call customer support.`);
            }

            return this.setState({
                revokeOpen: false,
                revokeItem: {
                    applicationName: null,
                    clientId: null
                },
                user: user.content,
                message: (
                    <div>
                        <strong>{applicationName}</strong>&apos;s access to your account has been revoked.
                    </div>
                ),
                messageOpen: true
            });
        }
        catch (e) {
            return this.setState({
                revokeOpen: false,
                revokeItem: {
                    applicationName: null,
                    clientId: null
                },
                message: e.ToString(),
                messageOpen: true
            });
        }
    }

    onTwoFactorCheck() {

        const user = Object.assign({}, this.state.user);
        user.twoFactor.enabled = !user.twoFactor.enabled;
        return this.setState({ user });
    }

    onTwoFactorTypeChange(e, type) {

        const user = Object.assign({}, this.state.user);
        user.twoFactor.type = type;

        return this.setState({ user });
    }

    onTwoFactorPhoneKeyUp(e) {

        const user = Object.assign({}, this.state.user);
        user.twoFactor.data = user.twoFactor.data || { phoneNumber: {} };
        user.twoFactor.data.phoneNumber = user.twoFactor.data.phoneNumber || {};
        user.twoFactor.data.phoneNumber.number = e.target.value;

        return this.setState({ user });
    }

    onTwoFactorCountryCodeChange(e) {

        const user = Object.assign({}, this.state.user);
        user.twoFactor.data = user.twoFactor.data || { phoneNumber: {} };
        const fields = e.target.value.split(':');

        if (fields.length === 2) {
            user.twoFactor.data.phoneNumber.countryCode = fields[0];
            user.twoFactor.data.phoneNumber.areaCode = fields[1];
        }

        return this.setState({ user });
    }

    async onTwoFactorSubmit() {

        try {
            if (!this.state.user.twoFactor.enabled) {
                this.state.user.twoFactor.type = null;
                this.state.user.twoFactor.data = null;
            }
            else {
                if (!this.state.user.twoFactor.type) {
                    return this.onError({
                        error: 'Please select your contact preference ("Email" or "Phone").'
                    });
                }

                if (this.state.user.twoFactor.type === 'phone') {
                    if (!this.state.user.twoFactor.data || !this.state.user.twoFactor.data.phoneNumber || !this.state.user.twoFactor.data.phoneNumber.number) {
                        return this.onError({
                            error: 'Please provide a phone number.'
                        });
                    }

                    this.state.user.twoFactor.data = this.state.user.twoFactor.data || { phoneNumber : {} };
                    this.state.user.twoFactor.data.phoneNumber.countryCode =
                        this.state.user.twoFactor.data.phoneNumber.countryCode || 'US';
                    this.state.user.twoFactor.data.phoneNumber.areaCode =
                        this.state.user.twoFactor.data.phoneNumber.areaCode || '+1';
                } else {
                    if (!this.state.user.twoFactor.data) {
                        this.state.user.twoFactor.data = {};
                    }
                }
            }

            const user = await this.api.put('/user', { twoFactor: this.state.user.twoFactor });
            if (user.code !== 201) {
                if (user.code === 401) {
                    return this.onLoggedOut();
                }
                else if (user.code === 400) {
                    return this.onError({
                        error: user.content.message
                    })
                }
                else {
                    return this.onError({
                        error: 'Sorry, an unexpected problem occurred. Please try again later, or contact support for immediate assistance.'
                    });
                }
            }

            return this.onSuccess('Two-factor authentication settings were successfully applied.');
        }
        catch (e) {
            return this.onError({ error: e.toString() });
        }
    }

    onRevokeDeny() {

        return this.setState({
            revokeOpen: false,
            revokeItem: {
                applicationName: null,
                clientId: null
            },
            message: null,
            messageOpen: false
        });
    }

    async componentDidMount() {

        try {
            const user = await this.api.get('/user');
            if (!user || user.code !== 200) {
                this.props.dispatch(setReturnTo('/preferences'));
                return this.setState({ redirectTo: '/?logged_out=true' });
            }

            if (!user.content.twoFactor) {
                user.content.twoFactor = {
                    enabled: false,
                    type: null,
                    data: {
                        phoneNumber: null
                    }
                };
            }

            return this.setState({ user: user.content });
        }
        catch (e) {
            return this.setState({ error: e.toString() });
        }
    }

    render() {

      let logout = null;

        if (this.state.redirectTo) {
            return (<Redirect to={this.state.redirectTo} />);
        }

        if (this.state.revokeOpen) {
            return (
                <GrantRevocationDialog
                    open={this.state.revokeOpen}
                    mustWarn={this.state.doNotWarn}
                    applicationName={this.state.revokeItem.applicationName}
                    clientId={this.state.revokeItem.clientId}
                    onWarnClick={this.onRevokeWarnChange}
                    onApprove={this.onRevokeApprove}
                    onDeny={this.onRevokeDeny} />
            );
        }

        if (this.state.revokeTokensWarning) {
            logout = (
                <LogoutEverywhereWarning
                    open={this.state.revokeTokensWarning}
                    onRevokeTokensApprove={this.onRevokeTokensApprove}
                    onRevokeTokensDeny={this.onRevokeTokensDeny}
                    onRevokeTokensClick = {this.onRevokeTokensClick}
                />
            );
        }

        const verify = this.props.user && this.props.user.verified ? null :
          <Verify query={this.props.query} onSuccess={this.onSuccess} user={this.state.user}/>;

        let view = (
            <Grid container spacing={0} className={this.props.classes.view}>
              <PopAlert
                  open={this.state.messageOpen}
                  message={this.state.message}
                  onClose={this.onMessageClose}
                  hasExit={true}
              />
            <Grid item xs={12} md={6}>
            {logout}
            <LeftRail />
            </Grid>
              <Grid item xs={12} md={6}>
                <PageHeader text="Account Settings" />
                <CurrentUser user={this.state.user} showVerify={true}/>

                  {verify}

                  <Logout
                      query={this.props.query}
                  />

                  <LogoutEverywhere
                      onRevokeTokensClick = {this.onRevokeTokensClick}
                      onRevokeTokensDeny = {this.onRevokeTokensDeny}
                      onRevokeTokensApprove = {this.onRevokeTokensApprove}
                  />

                  <PasswordUpdate
                      onSuccess={this.onSuccess}
                      onError={this.onError}
                      onRevokeTokensClick = {this.onRevokeTokensClick}
                  />

                  <TwoFactorUpdate
                      twoFactor={this.state.user.twoFactor}
                      onTwoFactorCheck={this.onTwoFactorCheck}
                      onTwoFactorTypeChange={this.onTwoFactorTypeChange}
                      onTwoFactorPhoneKeyUp={this.onTwoFactorPhoneKeyUp}
                      onTwoFactorCountryCodeChange={this.onTwoFactorCountryCodeChange}
                      onSubmit={this.onTwoFactorSubmit}
                  />

                  <ApprovedApplications
                      grants={this.state.user.grants}
                      onRevokeClick={this.onRevokeClick}
                  />
              </Grid>
          </Grid>
        );

        return (
            <Grid container spacing={0} justify="center">
              {view}
            </Grid>
        );
    }
}

const mapStateToProps = state => {
  return {
    user: Auth.userState(state)
  };
}

const ConnectedPreferencesApp = connect(mapStateToProps, null)(PreferencesApp);

export default withStyles(styles)(ConnectedPreferencesApp);
