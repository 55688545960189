import overrides from './overrides';

export default {
  overrides,
  palette: {
    common: {
      black: '#000',
      white: 'rgba(255, 255, 255, 1)'
    },
    background: {
      dark: '#303030',
      light: '#fafafa'
    },
    primary: {
      light: '#0076bb',
      main: '#0076bb',
      dark: '#0076bb',
      contrastText: '#fff'
    },
    secondary: {
      light: '#101010',
      main: '#101010',
      dark: '#101010',
      contrastText: '#fff'
    },
    error: {
      light: '#e57373',
      main: '#e57373',
      dark: '#e57373',
      contrastText: '#fff'
    },
    type: 'light'
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  typography: {
    fontFamily: '"Karla", sans-serif',
    fontSize: 14,
    useNextVariants: true,
  },
  pageContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  internalContainer: {
    padding: '0px 20px 0 20px',
    maxWidth: '1200px',
    // margin: '0 auto 50px auto',
    marginBottom: '50px',
    marginTop: '0px',
    _themeMdPadding: '20px 20px 0 20px',
  }
}
