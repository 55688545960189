import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import Heading from '../../../../shared/components/heading';

const styles = theme => ({
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  },
  text: {
    color: '#666',
    fontSize: '14px'
  },
  centerText: {
    textAlign: 'center'
  },
  progress: {
    marginTop: '30px'
  }
});

class StepRedirect extends React.Component {
  render() {
    if (this.props.redirectTo) {
      window.location.href = decodeURIComponent(this.props.redirectTo);
    }
    return (
      <Grid className={this.props.classes.centerText} container spacing={0} justifyContent="center">
        <Grid item xs={12} sm={12}>
          <CircularProgress className={this.props.classes.progress} size={40} color="inherit" />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(StepRedirect);
