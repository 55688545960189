import React from 'react';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';

class CountryCodeAdornment extends React.Component {

  render() {
    return (
      <InputAdornment position="start">
        <Select
          onChange={this.props.onChange}
          disableUnderline={true}
          value={this.props.value}>
          <MenuItem key="US" value="US:+1">(US) +1</MenuItem>
          <MenuItem key="GB" value="GB:+44">(GB) +44</MenuItem>
          <MenuItem key="IE" value="IE:+353">(IE) +353</MenuItem>
        </Select>
      </InputAdornment>
    );
  }
}

export default CountryCodeAdornment;
