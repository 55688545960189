import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import Footer from './shared/components/footer';
import Forgot from './pages/forgot/index';
import Home from './pages/home/index';
import NewAccount from './pages/new-account/index';
import NotFound from './pages/not-found/index';
import Preferences from './pages/preferences/index';
import Reset from './pages/reset/index';
import Verify from './pages/verify/index';
import AlertBar from './shared/components/alert';
import Loading from './shared/components/loading';
import Logo from './shared/components/logo';
import Properties from './shared/components/properties';
import Theme from './theme/theme'


const styles = (theme) => ({
  container: {
    minHeight: `calc(100vh - 150px)`
  },
  logo: {
    width: '400px',
    paddingTop: '50px'
  }
});

const Main = () => (
  <main>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/forgot" component={Forgot} />
      <Route exact path="/new-account" component={NewAccount} />
      <Route exact path="/preferences" component={Preferences} />
      <Route exact path="/reset" component={Reset} />
      <Route exact path="/verify" component={Verify} />
      <Route path="/verify/:verificationCode" component={NotFound} />
      <Route path="*" status={404} component={NotFound} />
    </Switch>
  </main>
);

class Skin extends Component {

  componentWillMount() {
    this.props.applyTheme(Theme);
  }

  render() {
    let main = (
      <div>
        <Grid item>
          <Main />
        </Grid>
        <Grid item>
          <Footer />
        </Grid>
      </div>
    )

    if (this.props.loading) {
      main = (
        <Grid item>
          <Loading />
        </Grid>
      );
    }

    return (
      <div>
        <Grid className={this.props.classes.container} container direction="column" spacing={2}>
          <Grid item>
            <Logo />
          </Grid>
          <Grid item>
            <Properties />
          </Grid>
          {main}
        </Grid>
        <AlertBar />
      </div>
    );
  }
}

/**
 * This component can NOT be connected to redux due to the path routing.  Doing so will
 * break page transitions (white pages)
 */
export default withStyles(styles)(Skin);
