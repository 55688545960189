import React from 'react';

import Api from '../../../../../../common/api';
import Local from '../../../../../../common/local';
import Util from '../../../../../../common/util';

import Heading from '../../../../shared/components/heading';
import StandardInput from '../../../../shared/components/standard-input';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
    view: {
      ...theme.internalContainer,
      [theme.breakpoints.up('md')]: {
        padding: theme.internalContainer._themeMdPadding
      },
    },
    subheader: {
        fontSize: '14px',
        fontStyle: 'italic',
        fontWeight: 'normal',
        color: '#151519',
        marginTop: '-15px',
        marginBottom: '30px',
        paddingLeft: '20px'
    },
    continueButton: {
        width: '100%'
    },
    profileButton: {
      [theme.breakpoints.down('xs')]: {
          width: '100%'
      }
    },
    orBlock: {
        marginTop: '1%',
        marginBottom: '-5%'
    },
    orText: {
        textAlign: 'center',
        width: '100%'
    },
    ctaBlock: {
        paddingTop: '40px'
    },
    link: {
        color: '#202020',
        borderBottom: 'solid 1px #202020',
        paddingBottom: '2px',
        textDecoration: 'none',
        cursor: 'pointer',
        fontSize: '13px'
    },
    linkUnderline: {
        color: '#202020',
        borderBottom: 'solid 1px #202020',
        paddingBottom: '2px',
        textDecoration: 'none',
        cursor: 'pointer',
        marginBottom: '2px',
        fontSize: '14px',
        display: 'inline-block',
        top: '-5px',
        position: 'relative',
    },
    createAccount: {
      textAlign: 'right',
    },
    loginButton: {
        marginBottom: '10px'
    },
    gnLoginButton: {
        marginBottom: '10px',
        borderRadius: '4px',
        backgroundColor: '#77bc43 !important',
        '&:hover': {
            backgroundColor: '#77bc43 !important',
            color: '#FFF'
        },
    }
});

class AuthenticationView extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            username: '',
            password: '',
            processing: false,
            error: this.props.loggedOut === 'true' ?
                   'You have been logged out. Please re-authenticate to continue.'
                   : null,
            placeholders: {
                username: 'Email Address',
                password: 'Password'
            },
            validation: {
                username: null,
                password: null
            }
        };

        this.api = new Api();
        this.generateOnInput = this.generateOnInput.bind(this);
        this.viewProfile = this.viewProfile.bind(this);
        this.onLogin = this.onLogin.bind(this);
        this.onError = this.onError.bind(this);
        this.forNewAccount = this.forNewAccount.bind(this);
        this.onSubmitPressed = this.onSubmitPressed.bind(this);
        this.continueAsLogin = this.continueAsLogin.bind(this);
    }

    generateOnInput(stateKey) {

        return function (e) {

            this.setState({
                [stateKey]: e.target.value,
                validation: { username: null, password: null }
            });
        }.bind(this);
    }

    onError(errObj) {

        this.setState({ processing: false });
        this.props.onError(errObj);
    }

    async onSubmitPressed(e) {

        if (e.key === 'Enter') {
            return await this.onLogin();
        }
    }

    async onLogin() {

        const {
            username,
            password
        } = this.state;

        const validation = {
            username: username === '' ? 'Please enter an email address.' : null,
            password: password === '' ? 'Please enter a password.' : null
        };

        if (Object.values(validation).some((v) => { return v !== null; })) {
            return this.setState({ validation });
        }

        this.setState({ processing: true });
        const that = this;

        try {
            const response = await this.api.post(
                                `/user/authenticate${this.props.clientId ? `?clientId=${this.props.clientId}` : ''}`,
                                {
                                    username,
                                    password,
                                    parameters: Util.toCoreQueryObject(this.props.query)
                                }
                            );

            if (!response.code) {
                return this.onError({
                    error: (
                        <div>
                            <div>
                                We're sorry. Our servers are having trouble logging you in right now.
                            </div>
                            <div>
                                Please try again later.
                            </div>
                        </div>
                    ), critical: true });
            }

            if (response.code === 400) {
                return this.onError({
                  error: function() {
                      if (response.content.validation && response.content.validation.keys.length && response.content.validation.keys[0] === 'username') {
                          validation.username = 'Please enter a valid email address.';
                          that.setState({ validation });
                          return 'This doesn\'t look like a valid email address. Please check it and try again.';
                      }
                      return undefined;
                  }() || 'Please double-check your email address for mistakes and try again.'
                });
            }
            if (response.code === 401) {
                return this.onError({
                    error: (
                        <div>
                            <div>We couldn't find a user with that address, or the password didn't match.</div>
                            <div>Please check your credentials and try again.</div>
                        </div>
                    )
                });
            }
            else if (response.code === 422) {
                return this.props.onRequireResetPassword(username);
            }
            else if (response.code === 423) {
                return this.onError({
                    error: (
                        <div>
                            <div>
                                This account has been temporary locked for too many failed login attempts.
                            </div>
                            <div>
                                Please try again in a while, or contact customer support for immediate assistance.
                            </div>
                        </div>
                    )
                });
            }
            else if (response.code === 417) {
                return this.props.onTwoFactor(username, password);
            }
            else if (response.code === 426) {
                return this.props.onUpgradeAccount(username);
            }
            else if (response.code === 428) {
                return this.props.onRequiresReconciliation(username);
            }

            if (!response.content || !response.content.token) {
                return this.onError({
                    error: (
                        <div>
                            <div>
                                We're sorry. Our servers are having trouble logging you in right now.
                            </div>
                            <div>
                                Please try again later.
                            </div>
                        </div>
                    ), critical: true });
            }

            Local.userLogin(response.content.token, username);
            return this.props.onLoginSuccess();
        }
        catch (e) {
            return this.onError({ error: e.toString() });
        }
    }

    continueAsLogin() {
        const newQuery = Object.assign(this.props.query, {});
        delete newQuery.reauthenticate;
        window.location.href = `/${Util.toQueryString(newQuery)}`;
    }

    viewProfile() {

        const newQuery = Object.assign(this.props.query, {});
        newQuery.was_reauthenticating = 'true';
        window.location.href = `/preferences${Util.toQueryString(newQuery, ['reauthenticate'])}`;
    }

    forNewAccount() {

        window.location.href = `/new-account${Util.toQueryString(this.props.query)}`;
    }

    componentWillMount() {

        if (this.props.query.aslogin) {
            return this.setState({
                username: this.props.query.aslogin
            });
        }
    }

    render() {
        const loginButtonClass = this.props.query.gnButton ?
            this.props.classes.gnLoginButton :
            this.props.classes.loginButton;

        const bottomControls = (
            <Grid container spacing={0} alignItems="center">
                <Grid item sm={6} xs={6}>
                         <Button className={loginButtonClass} onClick={this.onLogin} id="login">
                        {this.state.processing ?
                          (<CircularProgress size={14} color="inherit" />)
                          : 'Login'
                        }
                    </Button>
              </Grid>
            </Grid>
        );

        return (
            <Grid container spacing={0} className={this.props.classes.view} >
                <Grid item xs={12}>
                    {
                        this.props.isReauthenticationRequest && this.props.user ?
                        (
                            <Grid container spacing={0}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <p className={this.props.classes.subheader}>
                                            Currently logged in as <strong>{this.props.user.username}</strong>
                                        </p>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0}>
                                    <Grid item sm={6} xs={12}>
                                        <Button className={this.props.classes.continueButton} onClick={this.continueAsLogin} style={{ marginBottom: '12px' }}>
                                            Use This Account
                                        </Button>
                                    </Grid>
                                    <Grid item sm={6} xs={12} align="right">
                                        <Button className={this.props.classes.profileButton} onClick={this.viewProfile}>View Profile</Button>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0} className={this.props.classes.orBlock}>
                                    <Grid item xs={12}>
                                        <p className={this.props.classes.orText}>- or -</p>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0}>
                                    <Heading text="Login to Another Account" />
                                </Grid>
                            </Grid>
                        ) :
                        null
                    }
                </Grid>
                <Grid item xs={12}>
                    <StandardInput
                        inputType="email"
                        value={this.state.username}
                        disabled={!!this.props.query.aslogin}
                        style={{ marginBottom: '32px' }}
                        error={this.state.validation.username}
                        attribute="username"
                        label="EMAIL ADDRESS"
                        focus={true}
                        onChange={this.generateOnInput('username')}
                        onKeyUp={this.onSubmitPressed} />
                </Grid>
                <Grid item xs={12}>
                    <StandardInput
                        style={{ marginBottom: '12px' }}
                        error={this.state.validation.password}
                        password={true}
                        attribute="password"
                        label="PASSWORD"
                        onChange={this.generateOnInput('password')}
                        onKeyUp={this.onSubmitPressed} />
                </Grid>
                <Grid className={this.props.classes.ctaBlock} container spacing={0} alignItems="center">
                    {bottomControls}
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(AuthenticationView);
