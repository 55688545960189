import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { setReturnTo } from '../../../../../actions/return';

import Api from '../../../../../common/api'
import Auth from '../../../../../common/auth';
import Util from '../../../../../common/util';

import CurrentUser from '../../../shared/components/current-user';
import LeftRail from '../../../shared/components/left-rail';
import PageHeader from '../../../shared/components/page-header';
import PopAlert from '../../../shared/components/pop-alert';

import ApprovedApplications from './views/approved-applications';
import GrantRevocationDialog from './views/grant-revocation-dialog';

const styles = theme => ({
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  },
  secondheadline: {
    fontSize: "28px",
    color: "#000000",
    letterSpacing: "-1.3px",
    margin: "0 0 20px 0",
    textAlign: "center",
    [theme.breakpoints.up('md')]: {
      textAlign: "left",
      fontSize: "32px",
    },
  },
});

class PreferencesApp extends Component {

    constructor(props) {

        super(props);
        this.state = {
            redirectTo: null,
            error: this.props.query.logged_out === 'true' ?
                   'You have been logged out. Please re-authenticate to continue.'
                   : null,
            user: {
                username: '',
                grants: [],
                twoFactor: {
                    enabled: false,
                    type: null,
                    data: {
                        phoneNumber: null
                    }
                }
            },
            message: null,
            messageOpen: false,
            revokeOpen: false,
            doNotWarn: false,
            revokeItem: {
                applicationName: null,
                clientId: null
            },
            revokeTokensWarning: false
        };

        this.api = new Api();
        this.onBackToHome = this.onBackToHome.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);
        this.onRevokeWarnChange = this.onRevokeWarnChange.bind(this);
        this.onRevokeApprove = this.onRevokeApprove.bind(this);
        this.onRevokeDeny = this.onRevokeDeny.bind(this);
        this.onRevokeClick  = this.onRevokeClick.bind(this);
        this.onMessageClose = this.onMessageClose.bind(this);
        this.onRevokeTokensClick = this.onRevokeTokensClick.bind(this);
        this.onRevokeTokensDeny = this.onRevokeTokensDeny.bind(this);
        this.onRevokeTokensApprove = this.onRevokeTokensApprove.bind(this);
    }

    onBackToHome() {
      this.setState({redirectTo: `/${Util.toQueryString(this.props.query)}`})
    }

    onSuccess(message) {
        this.setState({ messageOpen: true, message });
    }

    onError(message) {
        if (typeof message === 'object') {
            return this.setState({ messageOpen: true, message: message.error || '' });
        }

        this.setState({ messageOpen: true, message });
    }

    onMessageClose() {

        this.setState({ messageOpen: false });
    }

    onRevokeWarnChange(doNotWarn) {

        this.setState({ doNotWarn });
    }

    async onRevokeClick(applicationName, clientId) {
        if (this.state.doNotWarn) {
            try {
                return await this.onRevokeApprove(applicationName, clientId);
            }
            catch (e) {
                return this.setState({
                    message: e.toString(),
                    messageOpen: true
                });
            }
        }

        return this.setState({
            revokeOpen: true,
            revokeItem: {
                applicationName,
                clientId
            }
        });
    }

    onRevokeTokensClick() {

        return this.setState({
            revokeTokensWarning: true
        });
    }

    onRevokeTokensDeny() {

        return this.setState({
            revokeTokensWarning: false
        });
    }

    async onRevokeTokensApprove() {

        return this.setState({
            revokeTokensWarning: false
        });
    }

    async onRevokeApprove(appName, cid) {

        const applicationName = this.state.revokeItem.applicationName || appName;
        const clientId = this.state.revokeItem.clientId || cid;

        try {
            const user = await this.api.delete(`/application/${clientId}/grant`);

            if (user.code !== 202 && user.code !== 304) {
                throw new Error(`We're sorry. There was a problem updating your account. Please try again later or call customer support.`);
            }

            return this.setState({
                revokeOpen: false,
                revokeItem: {
                    applicationName: null,
                    clientId: null
                },
                user: user.content,
                message: (
                    <div>
                        <strong>{applicationName}</strong>&apos;s access to your account has been revoked.
                    </div>
                ),
                messageOpen: true
            });
        }
        catch (e) {
            return this.setState({
                revokeOpen: false,
                revokeItem: {
                    applicationName: null,
                    clientId: null
                },
                message: e.ToString(),
                messageOpen: true
            });
        }
    }

    onRevokeDeny() {

        return this.setState({
            revokeOpen: false,
            revokeItem: {
                applicationName: null,
                clientId: null
            },
            message: null,
            messageOpen: false
        });
    }

    async componentDidMount() {

        try {
            const user = await this.api.get('/user');
            if (!user || user.code !== 200) {
                this.props.dispatch(setReturnTo('/preferences'));
                return this.setState({ redirectTo: '/?logged_out=true' });
            }

            if (!user.content.twoFactor) {
                user.content.twoFactor = {
                    enabled: false,
                    type: null,
                    data: {
                        phoneNumber: null
                    }
                };
            }

            return this.setState({ user: user.content });
        }
        catch (e) {
            return this.setState({ error: e.toString() });
        }
    }

    render() {

        if (this.state.redirectTo) {
            return (<Redirect to={this.state.redirectTo} />);
        }

        if (this.state.revokeOpen) {
            return (
                <GrantRevocationDialog
                    open={this.state.revokeOpen}
                    mustWarn={this.state.doNotWarn}
                    applicationName={this.state.revokeItem.applicationName}
                    clientId={this.state.revokeItem.clientId}
                    onWarnClick={this.onRevokeWarnChange}
                    onApprove={this.onRevokeApprove}
                    onDeny={this.onRevokeDeny} />
            );
        }

        let view = (
            <Grid container spacing={0} className={this.props.classes.view}>
              <PopAlert
                  open={this.state.messageOpen}
                  message={this.state.message}
                  onClose={this.onMessageClose}
                  hasExit={true}
              />
            <Grid item xs={12} md={6}>
            <LeftRail />
            </Grid>
              <Grid item xs={12} md={6}>
                <PageHeader text="Manage Application Access" />

                  <ApprovedApplications
                      grants={this.state.user.grants}
                      onRevokeClick={this.onRevokeClick}
                  />

                  <Button className={this.props.classes.button} color="primary" onClick={this.onBackToHome} variant="contained">
                      Back to Home
                  </Button>
              </Grid>
          </Grid>
        );

        return (
            <Grid container spacing={0} justify="center">
              {view}
            </Grid>
        );
    }
}

const mapStateToProps = state => {
  return {
    user: Auth.userState(state)
  };
}

const ConnectedPreferencesApp = connect(mapStateToProps, null)(PreferencesApp);

export default withStyles(styles)(ConnectedPreferencesApp);
