import React from 'react';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  heading: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#151519',
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      fontSize: '20px',
    },
  },
  faqList: {
    paddingLeft: '15px',
    paddingRight: '15px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '40px',
      paddingRight: '40px'
    },
    [theme.breakpoints.up('lg')]: {
      paddingRight: '30px'
    }
  },
  faqListElement: {
    paddingLeft: '0'
  },
  question: {
    listStyle: 'none',
    fontSize: '16px',
    marginBottom: '10px',
    fontWeight: 'bold',
    [theme.breakpoints.up('lg')]: {
      fontSize: '18px'
    }
  },
  answer: {
    listStyle: 'none',
    fontSize: '14px',
    marginLeft: '0px',
    marginBottom: '10px',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '30px',
      fontSize: '16px'
    }
  },
  listAnswer: {
    listStyle: 'none',
    fontSize: '14px',
    marginLeft: '0px',
    marginBottom: '15px',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '30px',
      fontSize: '16px'
    }
  },
  listSpacing: {
    marginBottom: '5px'
  }
});

class FaqApp extends React.Component {
  render() {
    return (
      <Grid container spacing={0} className={this.props.classes.view} justify="center">
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <h3 className={this.props.classes.heading}>FAQ</h3>
            </Grid>
            <Grid item xs={12} className={this.props.classes.faqList}>
              <ul className={this.props.classes.faqListElement}>
                <li className={this.props.classes.question}>What is Golf Account?</li>
                <li className={this.props.classes.answer}>
                  <p>
                    Golf Account is a single sign-on system built by Golf Channel.
                    With Golf Account, you’ll be able to use multiple applications,
                    like Revolution Golf, using credentials you’ve already
                    created on GolfNow, Golf Channel and Golf Channel AmTour.
                  </p>
                </li>
                <li className={this.props.classes.question}>
                  Why do some applications use Golf Account and others don’t?
                </li>
                <li className={this.props.classes.answer}>
                  <p>
                    Golf Account is a new system which all GOLF applications will be
                    adopting over the coming months. During this transition,
                    your credentials will continue to work on each platform.
                  </p>
                </li>
                <li className={this.props.classes.question}>
                  I can’t remember my password. Where do I reset it?
                </li>
                <li className={this.props.classes.listAnswer}>
                  <p>
                    If you are logging into Golf Account for the first time, you will need to reset your password on the original application.
                  </p>
                  <ul>
                    <li className={this.props.classes.listSpacing}>
                      Note: For Revolution Golf, call (888) 388-7155 between the hours of 8 AM and 6 PM ET for assistance.
                    </li>
                  </ul>
                  <p>
                    If you are logging into Golf Account after initial sign-up or sign-in, simply tap “Sign In” to open Golf Account
                    and select “Reset Password” or go to <a href="https://my.golfid.io/forgot">https://my.golfid.io/forgot</a>.
                    Then, follow the instructions that are presented.
                  </p>
                </li>
                <li className={this.props.classes.question}>
                  What are the password requirements?
                </li>
                <li className={this.props.classes.answer}>
                  For security purposes, passwords must meet the following requirements:
                  <ul>
                    <li>Have at least 8 characters.</li>
                    <li>Must not contain any spaces.</li>
                    <li>Must contain one letter and one number.</li>
                    <li className={this.props.classes.listSpacing}>Must not include any of the following characters: <strong>+@#$%^&*()\/&lt;&gt;'</strong></li>
                  </ul>
                </li>
                <li className={this.props.classes.question}>
                  I have an existing subscription, such as RG+ or GolfNow VIP. What does this mean for me?
                </li>
                <li className={this.props.classes.answer}>
                  <p>
                    You will continue to enjoy your paid access by using the email address associated to the subscription plan.
                    When your subscription is due for renewal, it will continue to be processed on the original point of purchase.
                  </p>
                </li>
                <li className={this.props.classes.question}>
                  I use different emails to access your products, like GolfNow and Revolution Golf. How do I access my
                  information on each application?
                </li>
                <li className={this.props.classes.answer}>
                  <p>
                    Use the login with the email address that has access to your subscription. You can switch accounts by selecting
                    “Switch Accounts” on the application’s account menu. You will then be able to log in using the desired credentials.
                  </p>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(FaqApp);
