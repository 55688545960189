import React, { Component }  from 'react';
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";

import Auth from '../../../../common/auth';

const styles = (theme) => ({
  heading: {
    fontSize: '14px',
  },
  username: {
    fontSize: '18px'
  },
  verifiedIcon: {
    color: '#009900 !important',
  }
});

class CurrentUser extends Component {
  render() {

    const verified = this.props.user2.verified ?
        <Grid item><CheckCircleIcon className={this.props.classes.verifiedIcon}/></Grid> :
        null;

    return (
      <Grid item xs={12}>
        <Typography color="textSecondary" className={this.props.classes.heading}>Currently logged in as</Typography>
        <Grid container spacing={2}>
          <Grid item>
            <Typography color="textPrimary" className={this.props.classes.username}><strong>{this.props.user.username}</strong></Typography>
          </Grid>
          {verified}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    user2: Auth.userState(state)
  };
}

const ConnectedCurrentUser = connect(mapStateToProps, null)(CurrentUser);

export default withStyles(styles)(ConnectedCurrentUser);
