import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import reducers from './reducers';
import { createStore } from 'redux';
import App from './App';
import { unregister } from './registerServiceWorker';

const store = process.env.NODE_ENV === 'development' ?
         createStore(reducers, {},
             window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
        :createStore(reducers) ;

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

try {
  unregister();
}
catch (e) {
  console.error(e);
}
