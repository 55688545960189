import React from 'react';
import { Redirect } from 'react-router';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import Api from '../../../../../common/api';
import Local from '../../../../../common/local';
import Util from '../../../../../common/util';

import LeftRail from '../../../shared/components/left-rail';
import Link from '@material-ui/core/Link';
import PageHeader from '../../../shared/components/page-header';
import PopAlert from '../../../shared/components/pop-alert';
import ResetPasswordRules from '../../../shared/components/reset-password-rules';
import StandardInput from '../../../shared/components/standard-input';

const styles = theme => ({
  button: {
    // padding: "15px 20px",
    // margin: "0 10px 10px 10px"
  },
  topButton: {
    marginBottom: '10px'
  },
  buttonControls: {
    display: "flex",
    justifyContent: "center",
    marginTop: "25px"
  },
  pageContainer: theme.pageContainer,
  noAccountLink: {
    cursor: 'pointer',
    fontWeight: 'bold',
    textDecoration: 'none',
  },
  resetPassword: {
    marginTop: '12px',
    cursor: 'pointer'
  },
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  },
});

class InvitationApp extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      error: null,
      invite: null,
      isCurrentUser: false,
      loading: true,
      notFound: false,
      password: '',
      redirect: null,
      repeat: '',
      reset: false,
      showPassword: false,
      validation: {
        password: null,
        repeat: null
      }
    };
    this.api = new Api();
    this.getExistingView = this.getExistingView.bind(this);
    this.getLoginView = this.getLoginView.bind(this);
    this.getNewUserView = this.getNewUserView.bind(this);
    this.getResetView = this.getResetView.bind(this);
    this.generateOnInput = this.generateOnInput.bind(this);
    this.goHome = this.goHome.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onCreateAccountClick = this.onCreateAccountClick.bind(this);
    this.onError = this.onError.bind(this);
    this.onPopClose = this.onPopClose.bind(this);
    this.onResetToggle = this.onResetToggle.bind(this);
    this.onShowPassword = this.onShowPassword.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitInternal = this.onSubmitInternal.bind(this);
    this.onSubmitPressed = this.onSubmitPressed.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  async componentWillMount() {
    const code = this.props.query.code;
    let isCurrentUser = false;
    try {
      const res = await this.api.get(`/user/invite/${code}`);

      if (res.code === 404) {
        this.setState({
          loading: false,
          notFound: true
        });
        return;
      }

      const result = await this.api.get('/user');

      if (result.code === 200) {
        const curUser = await Local.getCurrentUser();

        if (curUser.username === res.content.username) {
          isCurrentUser = true;
        }
      }

      this.setState({
        invite: res.content,
        isCurrentUser: isCurrentUser,
        loading: false
      });
    } catch (e) {
      this.setState({
        loading: false,
        notFound: true
      });
    }
  }

  generateOnInput(stateKey) {
    return function (e) {
      this.setState({
        [stateKey]: e.target.value,
        validation: { [stateKey]: null }
      });
    }.bind(this);
  }

  getLoginView() {
    let passwordErr = this.state.validation.password || null;
    const terms = this.state.invite.applicationTerms.terms ?
      this.state.invite.applicationTerms.terms :
      'https://www.nbcuniversal.com/terms';

    const endAdornment = this.state.showPassword ?
      <VisibilityOffIcon style={{'cursor': 'pointer'}} onClick={this.onShowPassword} /> :
      <VisibilityIcon style={{'cursor': 'pointer'}} onClick={this.onShowPassword} />;

    if (this.state.isCurrentUser) {
      return (
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PageHeader text="Welcome back to GOLFID" />
            </Grid>
            <Grid item xs={12}>
              <Typography color="textPrimary" className={this.props.classes.subHeading}>
                <Typography component="span" color="primary">{this.state.invite.application}</Typography> uses your GOLFID account to securely access this site using a SSO login.  You are currently logged in as <Typography component="span" color="primary">{this.state.invite.username}</Typography>.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textPrimary" className={this.props.classes.subHeading}>
                I agree that <Typography component="span" color="primary">{this.state.invite.application}</Typography> can access my profile and accept the invitation and <a href={terms}>terms of service</a>.
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Button className={this.props.classes.button} color="primary" onClick={this.onSubmit} variant="contained">
                {this.state.processing ?
                    (
                        <CircularProgress size={14} color="inherit" />
                    )
                 : 'Accept Invitation'
                }
              </Button>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader text="Welcome back to GOLFID" />
          </Grid>
          <Grid item xs={12}>
            <Typography color="textPrimary" className={this.props.classes.subHeading}>
              <Typography component="span" color="primary">{this.state.invite.application}</Typography> uses your GOLFID account to securely access this site using a SSO login.  We see you already have GOLFID login for <Typography component="span" color="primary">{this.state.invite.username}</Typography>, please enter your password.  If you don’t know your password, use the password reset feature.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="textPrimary" className={this.props.classes.subHeading}>
              I agree that <Typography component="span" color="primary">{this.state.invite.application}</Typography> can access my profile and accept the invitation and <a href={terms}>terms of service</a>.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight="fontWeightBold" color="textPrimary" className={this.props.classes.subHeading}>
              Your username:
            </Typography>
            <Typography component="span" color="primary">{this.state.invite.username}</Typography>
          </Grid>
          <Grid item xs={12}>
            <StandardInput
                inputType={this.state.showPassword ? 'text' : 'password'}
                style={{ width: '100%' }}
                error={passwordErr}
                password={true}
                label="PASSWORD"
                labelWidth={80}
                attribute="currentpassword"
                value={this.state.password}
                endAdornment={endAdornment}
                onChange={this.generateOnInput('password')}
                onKeyUp={this.onSubmitPressed} />
          </Grid>
          <Grid item xs={4}>
            <Button className={this.props.classes.button} color="primary" onClick={this.onSubmit} variant="contained">
              {this.state.processing ?
                  (
                      <CircularProgress size={14} color="inherit" />
                  )
               : 'Accept Invitation'
              }
            </Button>
          </Grid>
          <Grid item xs={8} className={this.props.classes.resetPassword}>
            <Link color="textSecondary" className={this.props.classes.link} onClick={this.onResetToggle} >
              Reset Password
            </Link>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  getResetView() {
    let passwordErr = this.state.validation.password || null;
    let repeatErr = this.state.validation.repeat || null;
    const terms = this.state.invite.applicationTerms.terms ?
      this.state.invite.applicationTerms.terms :
      'https://www.nbcuniversal.com/terms';

    const endAdornment = this.state.showPassword ?
      <VisibilityOffIcon style={{'cursor': 'pointer'}} onClick={this.onShowPassword} /> :
      <VisibilityIcon style={{'cursor': 'pointer'}} onClick={this.onShowPassword} />;

    return (
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader text="Welcome back to GOLFID" />
          </Grid>
          <Grid item xs={12}>
            <Typography color="textPrimary" className={this.props.classes.subHeading}>
              <Typography component="span" color="primary">{this.state.invite.application}</Typography> uses your GOLFID account to securely access this site using a SSO login.  Clicking the login link allowed us to verify your email address.  Please set a new password for <Typography component="span" color="primary">{this.state.invite.username}</Typography> below.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="textPrimary" className={this.props.classes.subHeading}>
              I agree that <Typography component="span" color="primary">{this.state.invite.application}</Typography> can access my profile and accept the invitation and <a href={terms}>terms of service</a>.
            </Typography>
          </Grid>
          <Grid item>
            <ResetPasswordRules
              password={this.state.password}
              passwordRepeat={this.state.repeat}
            />
          </Grid>
          <Grid item xs={6}>
            <StandardInput
                inputType={this.state.showPassword ? 'text' : 'password'}
                style={{ width: '100%' }}
                error={passwordErr}
                password={true}
                label="PASSWORD"
                labelWidth={80}
                attribute="password"
                value={this.state.password}
                endAdornment={endAdornment}
                onChange={this.generateOnInput('password')}
                onKeyUp={this.onSubmitPressed} />
          </Grid>
          <Grid item xs={6}>
            <StandardInput
                inputType={this.state.showPassword ? 'text' : 'password'}
                style={{ width: '100%' }}
                error={repeatErr}
                password={true}
                label="REPEAT PASSWORD"
                labelWidth={140}
                attribute="repeat"
                value={this.state.repeat}
                onChange={this.generateOnInput('repeat')}
                onKeyUp={this.onSubmitPressed} />
          </Grid>
          <Grid item xs={4}>
            <Button className={this.props.classes.button} color="primary" onClick={this.onSubmit} variant="contained">
              {this.state.processing ?
                  (
                      <CircularProgress size={14} color="inherit" />
                  )
               : 'Accept Invitation'
              }
            </Button>
          </Grid>
          <Grid item xs={8} className={this.props.classes.resetPassword}>
            <Link color="textSecondary" className={this.props.classes.link} onClick={this.onResetToggle} >
              Cancel Reset
            </Link>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  getExistingView() {
    return this.state.reset ? this.getResetView() : this.getLoginView();
  }

  getNewUserView() {
    let passwordErr = this.state.validation.password || null;
    let repeatErr = this.state.validation.repeat || null;
    const terms = this.state.invite.applicationTerms.terms ?
      this.state.invite.applicationTerms.terms :
      'https://www.nbcuniversal.com/terms';

    const endAdornment = this.state.showPassword ?
      <VisibilityOffIcon style={{'cursor': 'pointer'}} onClick={this.onShowPassword} /> :
      <VisibilityIcon style={{'cursor': 'pointer'}} onClick={this.onShowPassword} />;

    return (
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader text="Welcome to GOLFID" />
          </Grid>
          <Grid item xs={12}>
            <Typography color="textPrimary" className={this.props.classes.subHeading}>
              <Typography component="span" color="primary">{this.state.invite.application}</Typography> uses your GOLFID account to securely access this site using a SSO login.  Please create a secure password and login below.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="textPrimary" className={this.props.classes.subHeading}>
              I agree that <Typography component="span" color="primary">{this.state.invite.application}</Typography> can access my profile and accept the invitation and <a href={terms}>terms of service</a>.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight="fontWeightBold" color="textPrimary" className={this.props.classes.subHeading}>
              Your username:
            </Typography>
            <Typography component="span" color="primary">{this.state.invite.username}</Typography>
          </Grid>
          <Grid item>
            <ResetPasswordRules
              password={this.state.password}
              passwordRepeat={this.state.repeat}
            />
          </Grid>
          <Grid item xs={6}>
            <StandardInput
                inputType={this.state.showPassword ? 'text' : 'password'}
                style={{ width: '100%' }}
                error={passwordErr}
                password={true}
                label="PASSWORD"
                labelWidth={80}
                attribute="password"
                endAdornment={endAdornment}
                onChange={this.generateOnInput('password')}
                onKeyUp={this.onSubmitPressed} />
          </Grid>
          <Grid item xs={6}>
            <StandardInput
                inputType={this.state.showPassword ? 'text' : 'password'}
                style={{ width: '100%' }}
                error={repeatErr}
                password={true}
                label="REPEAT PASSWORD"
                labelWidth={140}
                attribute="repeat"
                onChange={this.generateOnInput('repeat')}
                onKeyUp={this.onSubmitPressed} />
          </Grid>
          <Grid item xs={12}>
            <Button className={this.props.classes.button} color="primary" onClick={this.onSubmit} variant="contained">
              {this.state.processing ?
                  (
                      <CircularProgress size={14} color="inherit" />
                  )
               : 'Accept Invitation'
              }
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  goHome() {
    this.redirect(`/`);
  }

  onCancel() {
    this.redirect(`/${Util.toQueryString(this.props.query)}`);
  }

  onCreateAccountClick(e) {
    e.preventDefault();
    this.redirect(`/new-account${Util.toQueryString(this.props.query)}`);
  }

  onError({ error }) {
    this.setState({
      error,
      pop: true
    });
  }

  onPopClose() {
    this.setState({
      pop: false
    });
  }

  onResetToggle()  {
    this.setState({
      reset: !this.state.reset,
      password: '',
      repeat: ''
    });
  }

  onShowPassword() {
    this.setState({showPassword: !this.state.showPassword});
  }

  async onSubmit() {
    return await this.onSubmitInternal();
  }

  async onSubmitInternal() {
    const token = this.state.isCurrentUser ? Local.getCurrentUser().token : null;

    if (this.state.invite.existingUser) {
      if (this.state.reset) {
        try {
          const response = await this.api.post(
            `/user/invite/${this.props.query.code}?reset=true`,
            {
              password: this.state.password
            }
          );

          if (response.code === 201) {
            Local.userLogin(response.content.token, this.state.invite.username);
            window.location = response.content.redirect;
          } else {
            this.onError({error: response.content.message});
          }
        } catch (e) {

        }
      } else if (this.state.isCurrentUser) {
        try {
          const response = await this.api.post(
            `/user/invite/${this.props.query.code}`,
            {
              ssoToken: token
            }
          );

          if (response.code === 201) {
            window.location = response.content.redirect;
          } else if (response.code === 412) {
            this.onError({error: 'The provided username already exists!  Please contact your administrator for a new invitation.'});
          } else {
            this.onError({error: response.content.message});
          }
        } catch (e) {
        }
      } else {
        try {
          const response = await this.api.post(
            `/user/invite/${this.props.query.code}`,
            {
              password: this.state.password
            }
          );

          if (response.code === 201) {
            Local.userLogin(response.content.token, this.state.invite.username);
            window.location = response.content.redirect;
          } else {
            this.onError({error: response.content.message});
          }
        } catch (e) {

        }
      }
    } else {
      if (!this.state.password || this.state.password.length < 8) {
        this.setState({
          validation: {
            password: 'Your password must be at least 8 characters long'
          }
        });
        return;
      }

      try {
        const response = await this.api.post(
          `/user/invite/${this.props.query.code}`,
          {
            password: this.state.password
          }
        );

        if (response.code === 201) {
          Local.userLogin(response.content.token, this.state.invite.username);
          window.location = response.content.redirect;
        } else if (response.code === 412) {
          this.onError({error: 'The provided username already exists!  Please contact your administrator for a new invitation.'});
        } else {
          this.onError({error: response.content.message});
        }
      } catch (e) {

      }
    }
  }

  async onSubmitPressed(e) {
    if (e.key === 'Enter') {
      return await this.onSubmit();
    }
  }

  redirect(redirect) {
    this.setState({ redirect });
  }

  render() {

    let view;

    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    if (this.state.loading) {
      view = (
        <Grid item xs={12} md={6}>
          <Grid container justify="center">
            <Grid item xs={12} md={6}>
              <CircularProgress className={this.props.classes.progress} size={40} color="inherit" />
            </Grid>
          </Grid>
        </Grid>
      );
    } else if (this.state.notFound) {
      view = (
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PageHeader text="Invitation Not Found" />
            </Grid>
            <Grid item xs={12}>
              <Typography color="textPrimary" className={this.props.classes.subHeading}>
                The invitation code does not exist or has expired.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textPrimary" className={this.props.classes.subHeading}>
                Please contact your site administrator to request a new invitation email.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textPrimary" className={this.props.classes.subHeading}>
                <Button className={this.props.classes.button} color="primary" onClick={this.goHome} variant="contained">
                  Return to Login
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    } else {
      if (this.state.invite.existingUser) {
        view = this.getExistingView();
      } else {
        view = this.getNewUserView();
      }
    }

    return (
      <div className={this.props.classes.pageContainer}>
        <Grid container spacing={0} className={this.props.classes.view}>
          <PopAlert open={this.state.pop} message={this.state.error} onClose={this.onPopClose} />
          <Grid item xs={12} md={6}>
            <LeftRail />
          </Grid>
          {view}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(InvitationApp);
