export const setAlert = alert => ({
  type: 'SET_ALERT',
  alert: alert
})

export const setError = error => ({
  type: 'SET_ERROR',
  error: error
})

export const setHasValidated = status => ({
  type: 'SET_HAS_VALIDATED',
  hasValidated: status
})

export const setSignedIn = status => ({
  type: 'SET_IS_SIGNED_IN',
  isSignedIn: status
})

export const setUser = user => ({
  type: 'SET_USER',
  user: user
})
