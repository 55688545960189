import Api from './../api';
import { setAlert, setError, } from './../../actions/auth';

const api = new Api();

const _dispatchErrors = (dispatch, data) => {
  if (data.toast) {
    dispatch(setAlert({
      message: data.message,
      open: true
    }));
  }

  dispatch(setError({
    code: data.code,
    field: data.field,
    message: data.message,
    previous: data.previous,
    rawMessage: data.rawMessage,
    toast: data.toast,
  }));
}

export default {

  getVerificationInformation: async(dispatch, verificationId, type) => {
    let response;
    try {
      response = await api.get(`/user/identity/${type}/verification/${verificationId}`);

      if (response.code === 200) {
        return response.content;
      }

      return null;
    } catch (e) {
      _dispatchErrors(
        dispatch,
        {
          code: response.code,
          field: null,
          message: "We're sorry. Our servers encountered an error.",
          rawMessage: response.content.message,
          toast: true
        }
      );
      return null;
    }
  },

  startProcess: async (dispatch, program, payload) => {
    try {
      const response = await api.post('/user/identity/' + program, payload);
      if (response.code === 200) {
        return response;
      }

      if (response.code > 299) {
        if (response.code === 409) {
          _dispatchErrors(
            dispatch,
            {
              code: response.code,
              field: null,
              message: response.content.message,
              rawMessage: response.content.message,
              toast: true
            }
          );
          return false;
        }

        if (response.code === 400) {
          let message = 'Please double-check your input';

          if (response.content.validation && response.content.validation.length) {
            message = message + ': ' + response.content.validation.join(", ")
          }

          _dispatchErrors(
            dispatch,
            {
              code: response.code,
              field: null,
              message: message,
              rawMessage: response.content.message,
              toast: true
            }
          );

          return false;
        }

        _dispatchErrors(
          dispatch,
          {
            code: response.code,
            field: null,
            message: "We're sorry. Our servers encountered an error.",
            rawMessage: response.content.message,
            toast: true
          }
        );
        return false
      }

    } catch (e) {
      _dispatchErrors(
        dispatch,
        {
          code: null,
          field: null,
          message: "We're sorry. Our servers encountered an error.",
          rawMessage: e,
          toast: true
        }
      );
      return false;
    }
  },
}
