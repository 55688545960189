import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

class PopAlert extends React.Component {

  render() {
    const popColor = !this.props.color ? null :
    {
      backgroundColor: `#${this.props.color}`
    };

    const action = [];
    if (this.props.hasExit) {
      action.push((
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={this.props.onClose}
        >
          <CloseIcon />
        </IconButton>
      ));
    }

    return (
      <Snackbar
        open={this.props.open === true && this.props.message !== null}
        onClose={this.props.onClose}
      >
        <SnackbarContent
          style={popColor}
          message={
            <div className="text-center">
            {this.props.message}
            </div>
          }
          action={action}
        />
      </Snackbar>
    );
  }
}

export default PopAlert;
