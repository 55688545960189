import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { withStyles } from "@material-ui/core";

import Loading from "../../../../base/shared/components/loading";

import { setError } from "../../../../../actions/auth";
import { setReturnTo } from "../../../../../actions/return";
import Api from "../../../../../common/api";
import IdentityStatus from "./views/identity-status";
import LeftRail from "../../../shared/components/left-rail";
import PageHeader from "../../../shared/components/page-header";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import StudentView from "./views/student/index";
import MilitaryView from "./views/military/index";
import FirstResponderView from "./views/firstResponder/index";
import TeacherView from "./views/teacher/index";
import MedicalView from "./views/medical/index";

const styles = (theme) => ({
	pageContainer: theme.pageContainer,
	card: {
		background: theme.palette.background.light
	},
	view: {
		...theme.internalContainer,
		[theme.breakpoints.up("md")]: {
			padding: theme.internalContainer._themeMdPadding
		}
	}
});

class Page extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			redirectTo: null,
			// verificationId: null,
			// programId: window.globals ? window.globals.gidStudentProgramId : '60c9e65288e17849464951fc',
			userStatus: null,
			screen: "start",
			identities: null
		};
		this.api = new Api();
	}

	async componentDidMount() {
		try {
			// // The 2 indicates the page was accessed by navigating into the history
			// if (performance.navigation.type === 2) {
			//   window.location.reload(true);
			//   return;
			// }

			const result = await this.api.get('/user');

			if (result.code === 401) {
		    this.props.dispatch(setReturnTo("/identity"));
				return this.setState({ redirectTo: "/?logged_out=true" });
	    }

			const userStatusResponses = {};
			let userIdentities = result.content.identities;

			userIdentities.forEach(function(userIdentity) {
			    userStatusResponses[userIdentity.type] = userIdentity;
			});

			this.setState({
				loading: false,
				identities: userStatusResponses
			});

			// // bypass reading golf-id status..it may still 'pending' and with the old
			// // verificationId... force a restart so we can show the user our collect info form
			// if(this.state.userStatus === '_forceRestart') {
			//   this.setState({
			//     loading: false
			//   })
			//   return
			// }

			//
			// let userStatus = userStatusResp['content'];
			// console.log("X status", userStatus)

			// if (userStatus) {
			//   if (userStatus.status === 'pending') {
			//     // this.props.dispatch(setFlow({isDocUpload: true}));
			//     // setFlow({isDocUpload: true});
			//     if (userStatus.programId) {
			//       this.setState({
			//         verificationId: userStatus.verificationId,
			//         programId: userStatus.programId,
			//         userStatus: userStatus.status,
			//         loading: false
			//       })
			//     } else {
			//       this.setState({
			//         verificationId: userStatus.verificationId,
			//         userStatus: userStatus.status,
			//         loading: false
			//       })
			//     }
			//   }
			//   this.setState({
			//     loading: false,
			//     userStatus: userStatus.status,  // 'failed' or 'success'
			//     verificationId: userStatus.verificationId,
			//   })
			// }

			if (this.props.query.identity) {
			    this.setScreen(this.props.query.identity);
			}
		} catch (e) {
			this.props.dispatch(
				setError({
					code: null,
					field: null,
					message: e.toString(),
					rawMessage: e,
					toast: false
				})
			);
		}
	}

	setScreen = (value) => {
		this.setState({ screen: value });
	};

	goToHome = (e) => {
		e.preventDefault();
		this.setState({ redirectTo: "/" });
	};

	render() {
		if (this.state.redirectTo) {
			return <Redirect push to={this.state.redirectTo} />;
		}

		let statusView = (
			<div className={this.props.classes.pageContainer}>
				<Grid
					container
					spacing={0}
					className={this.props.classes.view}
					justifyContent="center"
				>
				    {/*<PopAlert open={this.props.error.toast} message={this.props.error.message} onClose={this.onMessageClose}  hasExit={true}/>*/}

					<Grid item xs={12} md={6}>
						<LeftRail />
					</Grid>

					<Grid item xs={12} md={6}>
						<PageHeader text="Identity Verifications" />
						<Card elevation={13} className={this.props.classes.card}>
							<CardContent>
								<Grid container spacing={5}>
									<Grid item xs={12}>
										<IdentityStatus
											label="Student"
											type="student"
											identities={this.state.identities}
											onClickScreen={this.setScreen}
										/>
									</Grid>

									<Grid item xs={12}>
										<IdentityStatus
											label="Teacher"
											type="teacher"
											identities={this.state.identities}
											onClickScreen={this.setScreen}
										/>
									</Grid>

									<Grid item xs={12}>
										<IdentityStatus
											label="Military"
											type="military"
											identities={this.state.identities}
											onClickScreen={this.setScreen}
										/>
									</Grid>

									<Grid item xs={12}>
										<IdentityStatus
											label="First Responder"
											type="firstResponder"
											identities={this.state.identities}
											onClickScreen={this.setScreen}
										/>
									</Grid>
									<Grid item xs={12}>
										<IdentityStatus
											label="Medical Professional"
											type="medical"
											identities={this.state.identities}
											onClickScreen={this.setScreen}
										/>
									</Grid>
								</Grid>


								<Grid container spacing={10}>
									<Grid item xs={12}>
										<Button
											className={this.props.classes.buttonControls}
											color="primary"
											onClick={this.goToHome}
											variant="contained"
										>
											Cancel
										</Button>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</div>
		);

		let view = statusView;
		let state = this.state.screen;

		switch(state) {
		    case "student":
		        view = <StudentView query={this.props.query}></StudentView>;
		        break;
		    case "teacher":
		        view = <TeacherView query={this.props.query}></TeacherView>;
		        break;
		    case "military":
		        view = <MilitaryView query={this.props.query}></MilitaryView>;
		        break;
		    case "firstResponder":
		        view = <FirstResponderView query={this.props.query}></FirstResponderView>;
		        break;
		    case "medical":
		        view = <MedicalView query={this.props.query}></MedicalView>;
		        break;
		}
		return this.state.loading ? <Loading /> : view;
	}
}

const mapStateToProps = (state) => {
	return {
		flow: state.flow
	};
};

const ConnectedPage = connect(mapStateToProps, null)(Page);

export default withStyles(styles)(ConnectedPage);
