import { red } from '@material-ui/core/colors';

export default {
  MuiFormControl: {
    root: {
      width: '100%'
    },
  },
  MuiFormControlLabel: {
    root: {
      marginRight: '7px',
    }
  },
  MuiFormLabel: {
    root: {
      color: '#757575',
      fontSize: '13px',
      "&$error": {
        color: red[300]
      }
    }
  },
  MuiFormHelperText: {
    root: {
      color: red[300],
      "&$error": {
        color: red[300]
      }
    }
  },
  MuiButton: {
    root: {
      fontSize: '14px',
      textAlign: 'center',
      width: '148px',
      height: '44px',
      display: 'block',
      lineHeight: 0,
      padding: '16px 10px',
      textTransform: 'unset',
    },
  },
  MuiInput: {
    root: {
      '&:after': {
        backgroundColor: '#151519'
      },
      "&$error": {
        color: red[300]
      }
    },
    underline: {
      '&:after': {
        backgroundColor: '#151519'
      }
    }
  },
  MuiInputLabel: {
    root: {
      fontSize: '13px',
      '&:after': {
        backgroundColor: '#151519'
      }
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: '0px'
    }
  },
  MuiListItemText: {
    root: {
      marginBottom: '0px',
      marginTop: '0px'
    }
  },
  MuiSnackbarContent: {
    message: {
      maxWidth: '85%',
    },
    action: {
      marginLeft: 'auto',
      paddingLeft: 0,
    },
    root: {
      backgroundColor: '#0076BB'
    }
  },
  MuiTooltip: {
    tooltip: {
      backgroundColor: '#151519 !important',
      width: '353px',
    },
    popper: {
      top: '8px !important',
      left: '20px !important'
    }
  },
  MuiCheckbox: {
  },
  MuiRadio: {
  },
  buttonPrimary: {
    backgroundColor: "#0076BB",
    padding: "15px 20px",
  }
};
