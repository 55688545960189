import React from 'react';
import { Redirect, Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import Api from '../../../../../common/api';
import Local from '../../../../../common/local';
import Util from '../../../../../common/util';

import LeftRail from '../../../shared/components/left-rail';
import PageHeader from '../../../shared/components/page-header';
import StandardInput from '../../../shared/components/standard-input';
import ResetPasswordRules from '../../../shared/components/reset-password-rules';


const styles = theme => ({
  buttonControls: {
    display: "flex",
    justifyContent: "center",
  },
  pageContainer: theme.pageContainer,
  secondheadline: {
    fontSize: "28px",
    color: "#000000",
    letterSpacing: "-1.3px",
    margin: "0 0 20px 0",
    textAlign: "center",
    [theme.breakpoints.up('md')]: {
      textAlign: "left",
      fontSize: "32px",
    },
  },
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  },
  resetText: {
    color: '#666',
    fontSize: '14px'
  }
});

const generateError = (message) => {

  return (
    <div>
      <div className="row">
        <div className="col-12">
          {message}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          Please contact support for more information.
        </div>
      </div>
    </div>
  );
};

class ResetApp extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      password: '',
      repeat: '',
      previousPassword: false,
      validation: {
        password: null,
        repeat: null
      },
      showPassword: false,
      redirect: null,
      processing: false,
      error: null
    };

    this.api = new Api();
    this.generateOnInput = this.generateOnInput.bind(this);
    this.onShowPassword = this.onShowPassword.bind(this);
    this.onSubmitPressed = this.onSubmitPressed.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  generateOnInput(stateKey) {

    return function (e) {

      this.setState({
        [stateKey]: e.target.value,
        validation: { password: null, repeat: null }
      });
    }.bind(this);
  }

  onShowPassword() {
    this.setState({showPassword: !this.state.showPassword});
  }

  onSubmitPressed(e) {

    if (e.key === 'Enter') {
      return this.onSubmit();
    }
  }

  async onSubmit() {

    const {
      password,
      repeat
    } = this.state;

    const validation = {
      password: password.length ? null : 'Error: Please provide a new password.',
      repeat: repeat.length ? null : 'Error: Please repeat the password provided above.'
    };

    if (password !== repeat) {
      validation.repeat = 'Error: These passwords don\'t match. Please re-type your password again.';
    }

    if (Object.values(validation).some((v) => { return v !== null; })) {
      return this.setState({ validation });
    }

    try {
      const forgotPayload = {
        password
      };

      if (this.props.query.clientId || this.props.query.client_id) {
        forgotPayload.client_id = this.props.query.clientId || this.props.query.client_id;
      }

      if (this.props.query.username) {
        forgotPayload.username = this.props.query.username;
      }

      if (this.props.query.sgn) {
        forgotPayload.sgn = this.props.query.sgn;
      }

      this.setState({
        previousPassword: false,
        processing: true
      });

      const response = await this.api.post(`/user/authenticate/forgot/${this.props.query.request}`, forgotPayload);
      switch (response.code) {
        case 201:
          Local.userLogin(response.content.token);
          if (this.props.query.username) {
            return this.setState({
              redirect: `/${Util.toQueryString(this.props.query, ['request', 'username', 'sgn'])}`
            });
          }
          else {
            let queryString = Util.toQueryString(this.props.query, ['request']);

            if (this.props.query.redirect_uri) {
              window.location.href = decodeURIComponent(this.props.query.redirect_uri);
              return;
            }

            return this.setState({
              redirect: `/${queryString}`
            });
          }
        case 400:
          return this.setState({
            error: generateError('We\'re sorry, an unexpected error occurred.')
          });
        case 404:
          return this.setState({
            error: generateError(`We're sorry, this reset password link has either expired or is invalid.`)
          });
        case 409:
        case 412:
          var previous = false;
          if (response.content.message === 'Your new password cannot match any of your last 4 passwords. Please try again.') {
            previous = true;
          }
          return this.setState({
            validation: {
              password: response && response.content ? response.content.message || 'Your password could not be accepted. Please choose a password that meets the requirements above.' : 'Your password could not be accepted. Please choose a password that meets the requirements above.',
              repeat: null
            },
            previousPassword: previous,
            processing: false
          });
        default:
          return this.setState({
            error: generateError('We\'re sorry, an unexpected error occurred.')
          });
      }
    }
    catch (e) {
      return this.props.onError({ error: e.toString() });
    }
  }

  async componentWillMount() {

    const generateError = (message) => {

      return (
        <div>
          <div className="row">
            <div className="col-12">
              {message}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              Please contact support for more information.
            </div>
          </div>
        </div>
      );
    };

    try {
      const response = await this.api.get(`/user/authenticate/forgot/${this.props.query.request}`);
      if (response.code === 404) {
        return this.setState({
          error: generateError(`We're sorry, this reset password link has either expired or is invalid.`)
        });
      }
    }
    catch (e) {
      return this.setState({
        error: generateError(`We're sorry, an unexpected error has occurred.`)
      });
    }
  }

  render() {

    if (this.state.redirect !== null) {
      return (
        <Redirect to={this.state.redirect} />
      );
    }

    let view;

    const endAdornment = this.state.showPassword ?
      <VisibilityOffIcon color="action" style={{'cursor': 'pointer'}} onClick={this.onShowPassword} /> :
      <VisibilityIcon color="action" style={{'cursor': 'pointer'}} onClick={this.onShowPassword} />;

    if (this.state.error) {
      view = (
        <Grid container>
          <Grid item xs={12} className="text-center general-text">
            {this.state.error}
          </Grid>
          <Grid item xs={12}>
            You can <Link to="/">click here</Link> to return to the Golf Account home page.
          </Grid>
        </Grid>
      );
    }
    else {
      view = (
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <PageHeader text={this.props.query.set ? 'Set Your Password' : 'Reset Your Password'} />
          </Grid>
          <ResetPasswordRules
            password={this.state.password}
            passwordRepeat={this.state.repeat}
            previous={this.state.previousPassword}/>
          <Grid item xs={12}>
            <StandardInput
              inputType={this.state.showPassword ? 'text' : 'password'}
              style={{ marginBottom: '2rem' }}
              error={this.state.validation.password}
              password={true}
              label="New Password"
              attribute="password"
              endAdornment={endAdornment}
              onChange={this.generateOnInput('password')}
              onKeyUp={this.onSubmitPressed} />
          </Grid>
          <Grid item xs={12}>
            <StandardInput
              inputType={this.state.showPassword ? 'text' : 'password'}
              style={{ marginBottom: '2rem' }}
              error={this.state.validation.repeat}
              password={true}
              label="Repeat New Password"
              attribute="repeat"
              onChange={this.generateOnInput('repeat')}
              onKeyUp={this.onSubmitPressed} />
          </Grid>
          <Grid className={this.props.classes.buttonControls} container spacing={0}>
            <Button color="primary" onClick={this.onSubmit} variant="contained">
                {
                this.state.processing ?
                (<CircularProgress size={14} color="inherit" />) :
                this.props.query.set ? 'Set Password' : 'Reset Password'
                }
            </Button>

          </Grid>
        </Grid>
      );
    }

    return (
      <div className={this.props.classes.pageContainer}>
        <Grid className={this.props.classes.view} container spacing={0} justify="center">
          <Grid item xs={12} md={6}>
            <LeftRail />
          </Grid>
          <Grid item xs={12} md={6}>
            {view}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ResetApp);
