import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Footer  from './footer';
import LogoMain  from './logo-main';
import PropertyLogosMobile  from './property-logos-mobile';
import Util from '../../../../common/util'

const styles = (theme) => ({
  container: {
    display: 'flex',
    minHeight: '100vh',
    height: '100%',
    flexDirection: 'column',
  }
});

class Branding extends Component {
  render() {
    const params = Util.queryToObject(window.location.search);
    let footer = (
      <Footer
      caNotice="https://www.nbcuniversal.com/privacy/california-consumer-privacy-act?brandA=Golf&intake=Golf"
      termsAndConditionsUrl="https://www.nbcuniversal.com/terms"
      privacyPolicyUrl="https://www.nbcuniversal.com/privacy?brandA=Golf&intake=Golf"
      privacyOptOut="https://www.nbcuniversal.com/privacy/notrtoo?brandA=Golf&intake=Golf"
      adChoicesUrl="https://www.nbcuniversal.com/privacy/cookies#accordionheader2"
      doNotSellUrl="https://www.nbcuniversal.com/privacy/notrtoo?brandA=Golf&intake=Golf"
      />
    );
    let mainLogo = <LogoMain />;
    let mobilePropteryLogos = <PropertyLogosMobile />;

    if (params.hasOwnProperty('backgroundColor')) {
      document.body.style.backgroundColor = `#${params['backgroundColor']}`;
    }

    if (params.hasOwnProperty('noLogos') || this.props.noLogos) {
      mainLogo = null;
    }

    if (params.hasOwnProperty('noProperties') || this.props.noProperties) {
      mobilePropteryLogos = null;
    }

    if (params.hasOwnProperty('noFooter') || this.props.noFooter) {
      footer = null;
    }

    return (
      <div className={this.props.classes.container}>
        {mainLogo}
        {this.props.children}
        {mobilePropteryLogos}
        {footer}
      </div>
    );
  }
}

export default withStyles(styles)(Branding);
