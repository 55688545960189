import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';

import { setAlert } from '../../../../actions/auth';

const styles = theme => ({
  pageContainer: theme.pageContainer
});

class AlertBar extends Component {

  constructor(props) {

    super(props);

    this.onClose = this.onClose.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onClose() {
    const close = Object.assign({}, this.props.alert, {open: false});
    this.props.dispatch(setAlert(close));
  }

  onExited() {
    this.props.dispatch(setAlert({}));
  }

  render() {

    const type = this.props.alert.type ? this.props.alert.type : 'error';

    return (

        <Grid container spacing={0} justify="center">
          <Snackbar
            autoHideDuration={5000}
            onClose={this.onClose}
            onExited={this.onExited}
            open={this.props.alert.open}
          >
            <Alert variant="filled" severity={type}>{this.props.alert.message}</Alert>
          </Snackbar>
        </Grid>

    );
  }
}

const mapStateToProps = state => {
  return {
    alert: state.alert
  };
}

const ConnectedAlert = connect(mapStateToProps)(AlertBar);

export default withStyles(styles)(ConnectedAlert);
