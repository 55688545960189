import React from 'react';
import StandardInput from "../../../shared/components/standard-input";

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

class DateInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            day:'',
            month:'',
            year:'',
            validation: {
                year: null,
                month: null,
                day: null,
            },
        };

        this.handleInput = this.handleInput.bind(this);
        this.onKeyPress  = this.onKeyPress.bind(this);
    }

    onKeyPress(e) {
        if (Object.values(this.state.validation).every( (v) => {return v === null})
          && (this.state.year && this.state.month && this.state.day) ) {
            let month = this.state.month;
            let day = this.state.day;
            if (month < 10){
                month = "0"  + month
            }
            if (day < 10){
                day = "0"  + day
            }
            let formattedDate = `${this.state.year}-${month}-${day}`
            this.props.onChange(formattedDate, new Date(Date.UTC(this.state.year, this.state.month, this.state.day)))
        }
        else {
            this.props.onChange("", undefined)
        }
    }

    _validateInputs() {
        let months30 = [4, 6, 9, 11]
        let validation = this.state.validation

        if (this.state.day > 31) {
            validation.day = ' '
        } else {
            validation.day = null
        }

        if (this.state.month && this.state.day && this.state.day <= 31) {
            if (this.state.day === 31 && months30.includes(this.state.month)) {
                validation.month = ' '
                validation.day = ' '
            }
            else {
                validation.month = null
                validation.day = null
            }

            if (this.state.month === 2) {
                let isLeapYear = this.state.year && !((this.state.year % 4) || (!(this.state.year % 100) && (this.state.year % 400)));
                if (this.state.day === 30 || this.state.day === 31 ||
                  (this.state.day === 29 && !isLeapYear) ){
                    validation.month = ' '
                    validation.day = ' '
                }
                else {
                    validation.month = null
                    validation.day = null
                }
            }
        }
        this.setState({validation:validation})
    }

    handleInput(name) {
        return function (e) {
            let eParsed = parseInt(e.target.value)
            eParsed = Number.isNaN(eParsed) ? "" : eParsed

            if (eParsed === "" || eParsed) {
                this.setState({
                    [name]: eParsed,
                }, () => {
                    this._validateInputs()
                    if (name === 'month') {
                        this.onKeyPress(e)
                    }
                });
            }
        }.bind(this);
    }

    render() {
        const months = {
            undefined:'',
            1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun',
            7: 'Jul', 8: 'Aug', 9: 'Sept', 10: 'Oct', 11: 'Nov', 12: 'Dec'
        }

        return (
            <>
              <Grid item xs={4} sm={2}>
                  <TextField
                      select
                      id="month"
                      required={true}
                      value={this.state.month}
                      style={{ marginBottom: '10px', width: '90%' }}
                      error={this.state.validation.month || null}
                      attribute="month"
                      label="Month"
                      onChange={this.handleInput('month')}
                  >
                      {Object.entries(months).map(([k,v], i) => (
                          <MenuItem key={k} value={k}>{v} </MenuItem>
                      ))}
                  </TextField>
              </Grid>
              <Grid item xs={4} sm={1}>
                  <StandardInput
                      id="day"
                      required
                      inputType={'text'}
                      value={this.state.day}
                      style={{ marginBottom: '10px', width: '90%' }}
                      error={this.state.validation.day|| null}
                      attribute="day"
                      label="Day *"
                      labelWidth={100}
                      onChange={this.handleInput('day')}
                      onKeyUp={this.onKeyPress}
                   />
              </Grid>
              <Grid item xs={4} sm={2}>
                  <StandardInput
                      id="year"
                      required={true}
                      inputType={'text'}
                      value={this.state.year}
                      style={{ marginBottom: '10px', width: '70%' }}
                      error={this.state.validation.year || null}
                      attribute="year"
                      label="Year *"
                      labelWidth={100}
                      onKeyUp={this.onKeyPress}
                      onChange={this.handleInput('year')}
                   />
              </Grid>
            </>
        );
  }
}

export default DateInput;
