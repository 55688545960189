import React from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Typography from '@material-ui/core/Typography';

import Auth from '../../../../../../common/auth';
import Util from '../../../../../../common/util';

import { setError } from '../../../../../../actions/auth';

import Heading from '../../../../shared/components/heading';
import StandardInput from '../../../../shared/components/standard-input';

import ResetPasswordRules from '../../../../../golfid/shared/components/reset-password-rules';

const styles = (theme) => ({
  link: {
    cursor: "pointer"
  },
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  }
});

class View extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      password: '',
      repeat: '',
      previousPassword: false,
      validation: {
        password: null,
        repeat: null
      },
      showPassword: false,
      redirect: null,
      processing: false,
      error: null
    };

    this.generateOnInput = this.generateOnInput.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onForgot = this.onForgot.bind(this);
    this.onShowPassword = this.onShowPassword.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitPressed = this.onSubmitPressed.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  async componentWillMount() {
    Auth.validateResetToken(this.props.dispatch);
  }

  generateOnInput(stateKey) {

    return function (e) {

      this.setState({
        [stateKey]: e.target.value,
        validation: { username: null }
      });

      if (this.props.error.field) {
        this.props.dispatch(setError({}));
      }
    }.bind(this);
  }

  onCancel() {
    const newQuery = Object.assign(this.props.query, {});
    delete newQuery.request;
    this.redirect(`/${Util.toQueryString(newQuery)}`);
  }

  onForgot() {
    const newQuery = Object.assign(this.props.query, {});
    delete newQuery.request;
    this.redirect(`/forgot${Util.toQueryString(newQuery)}`);
  }

  onShowPassword() {
    this.setState({showPassword: !this.state.showPassword});
  }

  async onSubmitPressed(e) {

    if (e.key === 'Enter') {
      return await this.onSubmit();
    }
  }

  async onSubmit() {
    const {
      password,
      repeat
    } = this.state;

    const validation = {
      password: password.length ? null : 'Error: Please provide a new password.',
      repeat: repeat.length ? null : 'Error: Please repeat the password provided above.'
    };

    if (password !== repeat) {
      validation.repeat = 'Error: These passwords don\'t match. Please re-type your password again.';
    }

    if (Object.values(validation).some((v) => { return v !== null; })) {
      return this.setState({ validation });
    }

    Auth.userResetPassword(this.props.dispatch, password);
  }

  redirect(redirect) {
    this.setState({ redirect });
  }

  render() {
    if (this.state.redirect !== null) {
      return (
        <Redirect to={this.state.redirect} />
      );
    }

    let view;

    let passwordErr = this.state.validation.password || null;
    let repeatErr = this.state.validation.repeat || null;

    if (this.props.error) {
      if (this.props.error.field === 'password') {
        passwordErr = this.props.error.message;
      }
    }

    const endAdornment = this.state.showPassword ?
      <VisibilityOffIcon style={{'cursor': 'pointer'}} onClick={this.onShowPassword} /> :
      <VisibilityIcon style={{'cursor': 'pointer'}} onClick={this.onShowPassword} />;

    if (this.props.error.field === 'global') {
      view = (
        <Card elevation={3} className={this.props.classes.view}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} className="text-center general-text">
                <Typography color="textSecondary" className={this.props.classes.text}>{this.props.error.message}</Typography>
              </Grid>
              <Grid item xs={12} className="text-center general-text">
                <Typography color="textSecondary" className={this.props.classes.text}>Please contact support for more information.</Typography>
              </Grid>
              <Grid item xs={12}>
                <Button className={`${this.props.classes.button}`} color="primary" onClick={this.onForgot} variant="contained">
                    Request New Token
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button className={`${this.props.classes.button}`} color="secondary" onClick={this.onCancel} variant="contained">
                    Return to Login
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      );
    }
    else {
      view = (
        <Card elevation={3} className={this.props.classes.view}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item>
                <Heading text={this.props.query.set ? 'Set Your Password' : 'Reset Your Password'} />
              </Grid>
              <Grid item xs={12}>
              <ResetPasswordRules
                password={this.state.password}
                passwordRepeat={this.state.repeat}
                previous={this.state.previousPassword}/>
              </Grid>
              <Grid item xs={12}>
                <StandardInput
                  inputType={this.state.showPassword ? 'text' : 'password'}
                  style={{ width: "100%" }}
                  error={passwordErr}
                  password={true}
                  label="New Password"
                  labelWidth={110}
                  attribute="password"
                  endAdornment={endAdornment}
                  onChange={this.generateOnInput('password')}
                  onKeyUp={this.onSubmitPressed} />
              </Grid>
              <Grid item xs={12}>
                <StandardInput
                  inputType={this.state.showPassword ? 'text' : 'password'}
                  style={{ width: "100%" }}
                  error={repeatErr}
                  password={true}
                  label="Repeat New Password"
                  labelWidth={160}
                  attribute="repeat"
                  onChange={this.generateOnInput('repeat')}
                  onKeyUp={this.onSubmitPressed} />
              </Grid>
              <Grid item xs={12}>
                <Button color="primary" onClick={this.onSubmit} variant="contained">
                    {
                    this.state.processing ?
                    (<CircularProgress size={14} color="inherit" />) :
                    this.props.query.set ? 'Set Password' : 'Reset Password'
                    }
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button className={`${this.props.classes.button}`} color="secondary" onClick={this.onCancel} variant="contained">
                    Cancel
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      );
    }

    return view;
  }
}

const mapStateToProps = state => {
  return {
    error: state.error
  };
}

const ConnectedView = connect(mapStateToProps, null)(View);

export default withStyles(styles)(ConnectedView);
