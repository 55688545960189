import React from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import Auth from '../../../../../../common/auth';
import Util from '../../../../../../common/util';

import Heading from '../../../../shared/components/heading';

const styles = (theme) => ({
  buttonControls: {
    paddingTop: '10px'
  },
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  }
});

class View extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      redirect: null,
      resent: false
    };

    this.generateOnInput = this.generateOnInput.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitPressed = this.onSubmitPressed.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  generateOnInput(stateKey) {

    return function (e) {

      this.setState({
        [stateKey]: e.target.value,
        validation: { username: null }
      });
    }.bind(this);
  }

  onCancel() {
    this.redirect(`/${Util.toQueryString(this.props.query)}`);
  }

  async onSubmitPressed(e) {

    if (e.key === 'Enter') {
      return await this.onSubmit();
    }
  }

  async onSubmit() {
    Auth.userForgot(this.props.dispatch, this.props.flow.username);

    this.setState({resent: true});
  }

  redirect(redirect) {
    this.setState({ redirect });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    return (
      <Card elevation={3} className={this.props.classes.view}>
        <CardContent>
          <Grid container>
            <Grid item>
              <Heading text={this.props.query.set ? 'Set Password Sent' : 'Reset Sent'}/>
            </Grid>
            <Grid item>
              {
                this.props.query.aslogin ?
                (
                  <div>
                    <div><strong>Almost There!</strong></div>
                    <p>
                      You’ll need to reset your password in order to complete Golf Account setup. An email has been {this.state.resent ? 're-' : ''}sent to <strong>{this.props.query.aslogin}</strong> from GolfNow. Please open the link and complete the password reset now.
                    </p>
                    <p>
                      Remember, your reset link will expire in 24 hours.
                    </p>
                  </div>
                ) :
                (
                  <div>
                    <p>If there is an account associated with <strong>{this.props.flow.username}</strong>, a {this.props.query.set ? 'set password' : 'password reset'}  email will be {this.state.resent ? 're-' : ''}sent.</p>
                    <p>Remember, your {this.props.query.set ? 'set password' : 'reset'} link will expire in 24 hours, emails may take up to 10 minutes to arrive, and be sure to check your Spam folder.</p>
                    <p>
                      Didn't receive an email? Don't have an account? <Link className={this.props.classes.noAccountLink} onClick={this.onCreateAccountClick} href={`/new-account${Util.toQueryString(this.props.query)}`}>Click here to create one.</Link>
                    </p>
                  </div>
                )
              }
              <Grid className={this.props.classes.buttonControls} container spacing={2}>
                <Grid item>
                  <Button className={this.props.classes.topButton} color="primary" onClick={this.onSubmit} variant="contained">Resend</Button>
                </Grid>
                <Grid item>
                  <Button className={this.props.classes.button} color="secondary"  onClick={this.onCancel} variant="contained">Login</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.error,
    flow: state.flow
  };
}

const ConnectedView = connect(mapStateToProps, null)(View);

export default withStyles(styles)(ConnectedView);
