import React from 'react';
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom';

import Api from '../../../../../common/api';
import Auth from '../../../../../common/auth';
import Local from '../../../../../common/local';
import Util from '../../../../../common/util';
import LeftRail from '../../../shared/components/left-rail';
import PageHeader from '../../../shared/components/page-header';
import StandardInput from '../../../shared/components/standard-input';
import PopAlert from '../../../shared/components/pop-alert';
import ResetPasswordRules from '../../../shared/components/reset-password-rules';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const styles = theme => ({
    view: {
      ...theme.internalContainer,
      [theme.breakpoints.up('md')]: {
        padding: theme.internalContainer._themeMdPadding
      },
    },
    form: {
      padding: '0px 0 0 0',
    },
    buttonControls: {
      display: "flex",
      justifyContent: "center",
      marginTop: "25px"
    },
    button: {
      padding: "15px 20px",
      margin: "0 10px 10px 10px"
    },
    buttonWidthModification: {
      width: '150px'
    },
    pageContainer: theme.pageContainer,
    textInputContainer: {
      padding: "0 10px 0 0"
    },
    tooltipHeading: {
        fontSize: '15px',
        fontWeight: 'bold'
    },
    tooltipText: {
        fontSize: '13px'
    },
    createButton: {
      width: '200px',
      marginBottom: '10px',
      [theme.breakpoints.down('sm')]: {
          width: '150px',
      },
    }
});

class NewAccountApp extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            firstname: '',
            lastname: '',
            username: this.props.query.email ? this.props.query.email : '',
            password: '',
            repeat: '',
            phonenumber: '',
            open: false,
            processing: false,
            showPassword: false,
            validateEmail: this.props.query.validate ? true : false,
            validateEmailSent: false,
            validation: {
                firstname: null,
                lastname: null,
                username: null,
                password: null,
                repeat: null,
                phonenumber: null
            }
        };

        this.api = new Api();
        this.generateOnInput = this.generateOnInput.bind(this);
        this.onCancelVerify = this.onCancelVerify.bind(this);
        this.onError = this.onError.bind(this);
        this.onLogIntoAccount = this.onLogIntoAccount.bind(this);
        this.onPopClose = this.onPopClose.bind(this);
        this.onShowPassword = this.onShowPassword.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitPressed = this.onSubmitPressed.bind(this);
        this.onVerify = this.onVerify.bind(this);
    }

    onPopClose() {

        this.setState({
            pop: false
        });
    }

    onError({ error }) {

        this.setState({
            error,
            pop: true,
            processing: false
        });
    }

    generateOnInput(stateKey) {

        return function (e) {

            this.setState({
                [stateKey]: e.target.value,
                validation: {
                    firstname: null,
                    lastname: null,
                    username: null,
                    password: null,
                    repeat: null,
                    phonenumber: null
                }
            });
        }.bind(this);
    }

    handleTooltipClose() {

        this.setState({ open: false });
    }

    handleTooltip() {

        const open = !this.state.open;
        this.setState({ open: open });
    }

    onCancelVerify() {
      this.setState({
        validateEmail: false,
        validateEmailSent: false,
        password: '',
        repeat: ''
      });
    }

    onShowPassword() {
      this.setState({showPassword: !this.state.showPassword});
    }

    onSubmitPressed(e) {

        if (e.key === 'Enter') {
            return this.onSubmit();
        }
    }

    async onSubmit() {

        const {
            firstname,
            lastname,
            username,
            password,
            repeat
        } = this.state;

        const validation = {
            firstname: firstname.length ? null : 'Please provide your first name.',
            lastname: lastname.length ? null : 'Please provide your last name/surname.',
            username: username.length ? null : 'Please provide an email address.',
            password: password.length ? null : 'Please enter a password.',
            repeat: repeat.length ? null : 'Please repeat the password you entered above.',
            phonenumber: null
        };

        if (password !== repeat) {
            validation.repeat = 'The passwords do not match. Please re-enter to try again.';
        }

        if (Object.values(validation).some((v) => { return v !== null; })) {
            return this.setState({ validation });
        }

        const payload = {
            firstName: firstname,
            lastName: lastname,
            username,
            password,
            twoFactor: {
              enabled: false
            }
        };

        this.setState({ processing: true });

        const that = this;

        try {
            const result = await this.api.post(`/user${this.props.query.client_id ? `?clientId=${this.props.query.client_id}` : ''}`, payload);

            if (result.code !== 200) {
                if (result.code === 412) {
                    return this.setState({
                        processing: false,
                        validation: {
                            firstname: this.state.validation.firstname ? this.state.validation.firstname.slice() : null,
                            lastname: this.state.validation.lastname ? this.state.validation.lastname.slice() : null,
                            username: (
                                <span>
                                    Sorry, that email address is already registered. <Link onClick={this.onLogIntoAccount} to="/">Login instead?</Link>
                                </span>
                            ),
                            password: this.state.validation.password ? this.state.validation.password.slice() : null,
                            repeat: this.state.validation.repeat ? this.state.validation.repeat.slice() : null,
                            phonenumber: this.state.validation.phonenumber ? this.state.validation.phonenumber.slice() : null
                        }
                    });
                }
                else if (result.code === 400) {
                    this.setState({ processing: false });
                    return this.onError({
                        error: function() {
                            if ((result.content.validation &&
                                 result.content.validation.keys.length &&
                                 result.content.validation.keys[0] === 'username') ||
                                 (result.content && result.content.message === 'The email address is not valid.')
                               ) {
                                validation.username = 'Please enter a valid email address.';
                                that.setState({ validation });
                                return 'This doesn\'t look like a valid email address. Please check it and try again.';
                            }

                            if (result.content.message) {
                                return result.content.message;
                            }
                            return undefined;
                        }() || 'Please double-check your email address for mistakes and try again.'
                    });
                }
                else if (result.code === 424) {
                  this.setState({ processing: false, validateEmail: true });
                  return;
                }
                else {
                    this.setState({ processing: false });
                    return this.onError({
                        error: `Sorry, an unknown error occurred. Please try again shortly or contact customer support for immediate assistance.`
                    });
                }
            }

            Local.userLogin(result.content.token, username);
            return this.setState({ redirect: `/${Util.toQueryString(this.props.query, ['email', 'forceLogout', 'reauthenticate', 'validate'])}`, processing: false });
        }
        catch (e) {
            return this.onError({ error: e.toString() });
        }
    }

    async onVerify() {
      const res = await Auth.userRequestVerify(this.props.dispatch, this.state.username, true);

      if (res.code === 412) {
        return this.onError({
          error: `An account already exists with that email.`
        });
      } else if (res.code === 429) {
        return this.onError({
          error: `A verification email has recently been sent. Please wait a moment before trying to resend.`
        });
      } else if (res.code === 400) {
        return this.onError({
          error: res.content.message
        })
      }

      // Not an error, but use the toast
      this.onError({
        error: `A verification email has sent.`
      });

      this.setState({
        validateEmailSent: true,
        password: '',
        repeat: ''
      });
    }

    onLogIntoAccount(e) {
        e.preventDefault();
        return this.setState({
            redirect: `/${Util.toQueryString(this.props.query, ['email', 'validate'])}`
        });
    }

    componentWillMount() {

        if (this.props.query.aslogin) {
            return this.setState({
                username: this.props.query.aslogin
            });
        }
    }

    render() {

        if (this.state.redirect) {
            return (
                <Redirect to={this.state.redirect} />
            );
        }

        const endAdornment = this.state.showPassword ?
          <VisibilityOffIcon color="action" style={{'cursor': 'pointer'}} onClick={this.onShowPassword} /> :
          <VisibilityIcon color="action" style={{'cursor': 'pointer'}} onClick={this.onShowPassword} />;

        const createView = (
          <Grid item xs={12} md={6}>
              <PageHeader text="Create Account" />
              <Grid container spacing={2} className={this.props.classes.form}>
                  <Grid item xs={6} className={this.props.classes.textInputContainer}>
                      <StandardInput
                          style={{ marginBottom: '2rem' }}
                          error={this.state.validation.firstname}
                          focus={true}
                          label="FIRST NAME"
                          attribute="firstname"
                          onChange={this.generateOnInput('firstname')}
                          onKeyUp={this.onSubmitPressed} />
                  </Grid>
                  <Grid item xs={6} className={this.props.classes.textInputContainer}>
                      <StandardInput
                          style={{ marginBottom: '2rem' }}
                          error={this.state.validation.lastname}
                          label="LAST NAME"
                          attribute="lastname"
                          onChange={this.generateOnInput('lastname')}
                          onKeyUp={this.onSubmitPressed} />
                  </Grid>
              </Grid>
              <Grid item xs={12} className={this.props.classes.textInputContainer}>
                  <StandardInput
                      inputType="email"
                      value={this.state.username}
                      disabled={!!this.props.query.aslogin}
                      style={{ marginBottom: '2rem' }}
                      error={this.state.validation.username}
                      label="EMAIL ADDRESS"
                      attribute="username"
                      onChange={this.generateOnInput('username')}
                      onKeyUp={this.onSubmitPressed} />
              </Grid>
              <ResetPasswordRules
                password={this.state.password}
                passwordRepeat={this.state.repeat}
              />
              <Grid container spacing={2}>
                  <Grid item xs={6} className={this.props.classes.textInputContainer}>
                      <StandardInput
                          inputType={this.state.showPassword ? 'text' : 'password'}
                          style={{ marginBottom: '10px' }}
                          error={this.state.validation.password}
                          password={true}
                          label="PASSWORD"
                          attribute="password"
                          endAdornment={endAdornment}
                          onChange={this.generateOnInput('password')}
                          onKeyUp={this.onSubmitPressed} />
                  </Grid>
                  <Grid item xs={6} className={this.props.classes.textInputContainer}>
                      <StandardInput
                          inputType={this.state.showPassword ? 'text' : 'password'}
                          style={{ marginBottom: '10px' }}
                          error={this.state.validation.repeat}
                          password={true}
                          label="REPEAT PASSWORD"
                          attribute="repeat"
                          onChange={this.generateOnInput('repeat')}
                          onKeyUp={this.onSubmitPressed} />
                  </Grid>
              </Grid>

              <Grid className={this.props.classes.buttonControls} container spacing={0}>
                <Button className={this.props.classes.button} color="primary" onClick={this.onSubmit} variant="contained" id="createAccount">
                    {this.state.processing ?
                        (
                            <CircularProgress size={14} color="inherit" />
                        )
                     : 'Create Account'
                    }
                </Button>
                <Button className={`${this.props.classes.button} ${this.props.classes.buttonWidthModification}`} color="primary" onClick={this.onLogIntoAccount} variant="outlined" id="logIntoAccount">
                    Log into Account
                </Button>
              </Grid>
          </Grid>
        );

        let validateView = !this.state.validateEmailSent ? (
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PageHeader text="Verify Your Email" />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography>
                    We're sorry we couldn't validate your email automatically.  Enter the email address you are trying to create an account for and click the </Typography><Typography color='primary' display='inline'>Verify Email</Typography><Typography display='inline'> button below.
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography>
                    We will send an email to the address with a link to verify the address and allow the email to be used for account creation.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <StandardInput
                  inputType="email"
                  style={{ marginBottom: '10px' }}
                  error={this.state.validation.username}
                  attribute="username"
                  label="EMAIL ADDRESS"
                  focus={true}
                  value={this.state.username}
                  onChange={this.generateOnInput('username')}
                  onKeyUp={this.onSubmitPressed} />
              </Grid>
              <Grid className={this.props.classes.buttonControls} container spacing={0}>
                  <Button className={this.props.classes.button}
                      color="primary"
                      id="logout-click"
                      onClick={this.onVerify}
                      variant="contained">
                      Verify Email
                  </Button>
                  <Button className={this.props.classes.button}
                      color="primary"
                      id="logout-click"
                      onClick={this.onCancelVerify}
                      variant="outlined">
                      Back to Create
                  </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PageHeader text="Verify Your Email" />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography display='inline'>
                    An email has been set to </Typography><Typography color='primary' display='inline'>{this.state.username}</Typography><Typography display='inline'> with a link to validate your email.
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography>
                    This link will expire in 24 hours, emails may take up to 10 minutes to arrive, and be sure to check your Spam folder.
                </Typography>
              </Grid>
              <Grid className={this.props.classes.buttonControls} container spacing={0}>
                  <Button className={this.props.classes.button}
                      color="primary"
                      id="logout-click"
                      onClick={this.onVerify}
                      variant="contained">
                      Resend Email
                  </Button>
                  <Button className={this.props.classes.button}
                      color="primary"
                      id="logout-click"
                      onClick={this.onCancelVerify}
                      variant="outlined">
                      Back to Create
                  </Button>
              </Grid>
            </Grid>
          </Grid>
        );

        const view = this.state.validateEmail ? validateView : createView;

        return (
          <div className={this.props.classes.pageContainer}>
            <Grid container spacing={0} className={this.props.classes.view} >
                <PopAlert
                    open={this.state.pop}
                    message={this.state.error}
                    onClose={this.onPopClose}
                    hasExit={true}
                />
                <Grid item xs={12} md={6}>
                  <LeftRail />
                </Grid>
                {view}
            </Grid>
          </div>
        );
    }
}

const mapStateToProps = state => {
  return {
  };
}

const ConnectedNewAccountApp = connect(mapStateToProps, null)(NewAccountApp);

export default withStyles(styles)(ConnectedNewAccountApp);
